import { textFieldClasses, Theme } from '@mui/material';
import { ShipmentStatus, ShipmentStatuses } from 'globals/constants/shipment';
import { capitalizeWords } from 'services';

export const getShipmentStatusConfig = (
  shipmentStatus: ShipmentStatus,
  shipmentStatusText: string,
  theme: Theme
) => {
  const textColor = theme.palette.colors.white;
  const text = capitalizeWords(shipmentStatusText);
  const hoverText = text;
  const configTable = {
    [ShipmentStatuses.ATTEMPT_FAILED]: {
      text,
      color: theme.palette.colors.burntSiennaR,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.AVAILABLE_FOR_PICKUP]: {
      text,
      textFieldClasses,
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.CREATED]: {
      text,
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.DELIVERED]: {
      text,
      color: theme.palette.colors.goldFusion,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.EXCEPTION]: {
      text,
      color: theme.palette.colors.chiGong,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.EXPIRED]: {
      text,
      color: theme.palette.colors.jaffa,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.INFO_RECEIVED]: {
      text,
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.IN_TRANSIT]: {
      text,
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.OUT_FOR_DELIVERY]: {
      text,
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
    [ShipmentStatuses.PENDING]: {
      text,
      color: theme.palette.colors.pelorous,
      textColor,
      hoverText,
    },
  };

  return configTable[shipmentStatus];
};
