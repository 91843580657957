import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import theme from 'themes/theme';


import {
  ShippingPopoverStackSx,
  TrackingDetailsBoxSx,
  ShipmentStatusPillSx,
  OrderItemDetailsBoxSx,
  MoreOrderItemsLinkSx,
  ShippingPopoverSx,
} from './styles';
import { ShippingDetailsPopoverProps } from './types';
import { getItemProductTitle } from 'pages/Orders/utils';
import { Link, Typography } from '@mui/material';
import { ShipmentStatusPill } from 'components';
import { ArrowOutward } from '@mui/icons-material';
import { getOrderItemCountText } from './utils';
import React from 'react';
import { NavigationContext } from 'context/NavigationContext';
import { FlattenedShipment } from '../../types';


function ShippingDetailsPopover(props: ShippingDetailsPopoverProps) {
  const {
    order,
    open,
    anchorEl,
    handlePopoverClose,
    handleMouseLeave,
    handleMouseEnter,
  } = props;

  
  const { id, shipments, orderType: { name: orderTypeName}, organizationId } = order;
  const navigator = React.useContext(NavigationContext);

  return (

        <Popover
          sx={ShippingPopoverSx}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          PaperProps={{
            onMouseLeave: handleMouseLeave,
            onMouseEnter: handleMouseEnter,
          }}
        >
          {shipments?.map((shipment: FlattenedShipment) => (
            <Stack sx={ShippingPopoverStackSx}>
              <Box key={shipment.id} sx={TrackingDetailsBoxSx}>
                <Link
                  href={shipment.trackings?.[0]?.trackingLink ?? undefined}
                  target="_blank"
                  rel="noopener"
                  color={theme.palette.colors.black}
                >
                  <b>{shipment.trackings?.[0]?.trackingNumber}</b>
                </Link>
                <ShipmentStatusPill overrideSx={ShipmentStatusPillSx} status={shipment.status } statusText={shipment.substatus || shipment.status} />
              </ Box>
              {shipment.orderItems?.length && (
                <Box sx={OrderItemDetailsBoxSx}>
                  <Typography variant="body1" color="text.primary" fontSize="14px">
                    {getItemProductTitle(shipment.orderItems[0], orderTypeName)}
                  </Typography>
                  {shipment.orderItems.length > 1 && (
                    <Link
                      sx={MoreOrderItemsLinkSx}
                      href={navigator.constructSecurePathToOrderDetails(id, organizationId, 'orderitems')}
                      target="_blank"
                      rel="noopener"
                      color={theme.palette.colors.black}
                    >
                     <b>{getOrderItemCountText(shipment)}</b>
                     <ArrowOutward />
                    </Link>
                  )}
                </Box>
              )}
            </Stack>
          ))}
        </Popover>
  );
}

export default ShippingDetailsPopover;
