import { DATABASE_ORDER_STATUSES } from 'globals/constants/orders';
import moment from 'moment';

export const getStatusVariables = (statuses: string[], orgId?: string) => {
  const variables: {
    variables: {
      where: {
        deletedAt: { equals: null };
        status?: {
          in: string[];
        };
        organization: {
          is: {
            id: {
              equals?: string;
            };
          };
        };
      };
    };
  } = {
    variables: {
      where: {
        deletedAt: { equals: null },
        organization: {
          is: {
            id: {
              equals: orgId,
            },
          },
        },
      },
    },
  };
  if (statuses.length) {
    variables.variables.where.status = {
      in: statuses,
    };
  }
  return variables;
};

export const getDateRangeVariables = (
  beforeDate: string,
  afterDate: string,
  orgId?: string
) => {
  const variables: {
    variables: {
      where: {
        OR: {
          to: { equals: string };
          createdAt: { lte: string; gte: string };
          order: {
            is: {
              organizationId: { equals?: string };
              status: { equals: string };
            };
          };
        }[];
      };
    };
  } = {
    variables: {
      where: {
        OR: [
          {
            to: {
              equals: DATABASE_ORDER_STATUSES.PENDING_RECIPIENT_INFORMATION,
            },
            createdAt: { lte: beforeDate, gte: afterDate },
            order: {
              is: {
                organizationId: { equals: orgId },
                status: {
                  equals: DATABASE_ORDER_STATUSES.PENDING_RECIPIENT_INFORMATION,
                },
              },
            },
          },
          {
            to: { equals: DATABASE_ORDER_STATUSES.PENDING_RETURN },
            createdAt: { lte: beforeDate, gte: afterDate },
            order: {
              is: {
                organizationId: { equals: orgId },
                status: { equals: DATABASE_ORDER_STATUSES.PENDING_RETURN },
              },
            },
          },
        ],
      },
    },
  };
  return variables;
};
