import React from 'react';
import moment from 'moment/moment';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import { FAQCardLink, FAQCardProps } from './widgets/faq/FAQCard/types';
import { CTAButton } from './widgets/CTA/types';
import { BoxSVGIcon, BuyAndHoldSVGIcon } from 'components/Icons';
import homepageCTACupSrc from 'assets/homepageCTACup.png';
import { NavigationContext } from '../../context/NavigationContext';
import { selectOrdersLoading } from 'store/slices/home/orders/selectors';
import { notificationSlice } from 'store/slices';
import {
  ORDERS_API_ERROR_MESSAGE,
  ASSETS_API_ERROR_MESSAGE,
} from './constants';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { AppDispatch } from 'store';
import {
  getAllOrdersTotals,
  getOpenOrdersTotals,
  getCompleteOrdersTotals,
  getNeedsAttentionOrdersTotals,
  getInTransitOrdersTotals,
  getExpiringSoonOrdersTotals,
  getAllAssetsTotals,
  getAllocatedAssetsTotals,
  getInInventoryAssetsTotals,
  getReadyToDeployAssetsTotals,
  getNeedsAttentionAssetsTotals,
} from 'store/slices/home/totals/actions';
import { getStatusVariables, getDateRangeVariables } from './utils';

import {
  OPEN_ORDERS_STATUSES,
  IN_TRANSIT_ORDERS_STATUSES,
  COMPLETE_ORDERS_STATUSES,
  NEEDS_ATTENTION_ORDERS_STATUSES,
  ALLOCATED_ASSETS_STATUSES,
  IN_INVENTORY_ASSETS_STATUSES,
  READY_TO_DEPLOY_ASSETS_STATUSES,
  NEEDS_ATTENTION_ASSETS_STATUSES,
} from './constants';
import { selectAssetsLoading } from 'store/slices/home/assets/selectors';
import { PanelProps } from './list/panel/Panel/types';
import { RetrievalStepSubRoutes } from 'global-constants';

export const useGetOrderFAQConfig = () => {
  const links0: FAQCardLink[] = [
    {
      link: 'https://faq.allwhere.co/getting-started-with-allwhere-start-here-rkqG7kvbT',
      copy: 'Getting Started with allwhere',
      icon: ArrowOutwardOutlinedIcon,
    },
    {
      link: 'https://faq.allwhere.co/hris-integration-BkZVxpyIT',
      copy: 'HRIS Integration',
      icon: ArrowOutwardOutlinedIcon,
    },
  ];

  const links1: FAQCardLink[] = [
    {
      link: 'https://faq.allwhere.co/retrieval-service-details-by-region-SJcTdk9u3',
      copy: 'Retrieval Service Details by Region',
      icon: ArrowOutwardOutlinedIcon,
    },
    {
      link: 'https://faq.allwhere.co/available-services-by-region-SyS44fqu2',
      copy: 'Available Services by Region',
      icon: ArrowOutwardOutlinedIcon,
    },
  ];

  const cards: FAQCardProps[] = [
    {
      links: links0,
      icon: SpeakerNotesIcon,
    },
    {
      links: links1,
      icon: LocationOnOutlinedIcon,
    },
  ];

  return {
    title: (
      <div>
        Want to find out more? Explore our{' '}
        <a
          href="https://faq.allwhere.co/en_us/categories/faq-S1PKSbsci"
          target="_blank"
        >
          FAQ section
        </a>
      </div>
    ),
    cards,
  };
};

export const useGetOrderCTAConfig = (props: PanelProps) => {
  const navigator = React.useContext(NavigationContext);
  const { data } = props;

  const orderButtons: CTAButton[] = [
    {
      link: navigator.pathToSendEquipment(),
      copy: 'Send Equipment',
      icon: CardGiftcardIcon,
    },
    {
      link: navigator.pathToRetrieval(RetrievalStepSubRoutes.LOCATION),
      copy: 'Retrieve equipment',
      customIcon: BoxSVGIcon,
    },
    {
      link: navigator.pathToBuyAndHold(),
      copy: 'Buy & Hold',
      customIcon: BuyAndHoldSVGIcon,
    },
  ];

  const homepageCTATitle = "It's a good time to place an order!";
  const homepageCTASubtitle =
    "Let's buy assets and send more equipment to your employees or start a new retrieval with allwhere!";

  return {
    buttons: orderButtons,
    homepageCTACupSrc,
    homepageCTATitle,
    homepageCTASubtitle,
    ContainerSx: data.length === 0 ? { paddingTop: '40px' } : {},
  };
};

export const useGetAssetFAQConfig = () => {
  const links0: FAQCardLink[] = [
    {
      link: 'https://faq.allwhere.co/recycling-and-reselling-devices-with-allwhere-SyahliplR',
      copy: 'Recycling and reselling Devices with allwhere',
      icon: ArrowOutwardOutlinedIcon,
    },
    {
      link: 'https://faq.allwhere.co/functionality-testing-allwhere-intake-process-SJVqv92dn',
      copy: 'Device Functionality Testing',
      icon: ArrowOutwardOutlinedIcon,
    },
  ];

  const links1: FAQCardLink[] = [
    {
      link: 'https://faq.allwhere.co/retrieval-service-details-by-region-SJcTdk9u3',
      copy: 'Retrieval Service Details by Region',
      icon: ArrowOutwardOutlinedIcon,
    },
    {
      link: 'https://faq.allwhere.co/available-services-by-region-SyS44fqu2',
      copy: 'Available Services by Region',
      icon: ArrowOutwardOutlinedIcon,
    },
  ];

  const cards: FAQCardProps[] = [
    {
      links: links0,
      icon: LaptopOutlinedIcon,
    },
    {
      links: links1,
      icon: LocationOnOutlinedIcon,
    },
  ];

  return {
    title: (
      <div>
        Want to find out more? Explore our{' '}
        <a
          href="https://faq.allwhere.co/en_us/categories/faq-S1PKSbsci"
          target="_blank"
        >
          FAQ section
        </a>
      </div>
    ),
    cards,
  };
};

export const useGetAssetCTAConfig = (props: PanelProps) => {
  const navigator = React.useContext(NavigationContext);
  const { data } = props;

  const orderButtons: CTAButton[] = [
    {
      link: navigator.pathToBuyAndHold(),
      copy: 'Buy & Hold',
      customIcon: BuyAndHoldSVGIcon,
    },
    {
      link: navigator.pathToRetrieval(RetrievalStepSubRoutes.LOCATION),
      copy: 'Retrieve equipment',
      customIcon: BoxSVGIcon,
    },
    {
      link: 'mailto:hello@allwhere.co',
      copy: 'Contact us',
      icon: EmailOutlinedIcon,
    },
  ];

  const homepageCTATitle = 'Inventory low?';
  const homepageCTASubtitle =
    'Buy and hold new devices, or start an equipment refresh with a retrieval!';

  return {
    buttons: orderButtons,
    homepageCTACupSrc,
    homepageCTATitle,
    homepageCTASubtitle,
    ContainerSx: data.length === 0 ? { paddingTop: '40px' } : {},
  };
};

export const useShowErrorNotificationOnOrdersOrAssetsAPIError = () => {
  const dispatch = useDispatch();
  const ordersLoading = useSelector(selectOrdersLoading);
  const assetsLoading = useSelector(selectAssetsLoading);
  React.useEffect(() => {
    if (ordersLoading === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: ORDERS_API_ERROR_MESSAGE,
        })
      );
    } else if (assetsLoading === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: ASSETS_API_ERROR_MESSAGE,
        })
      );
    }
  }, [ordersLoading]);
};

export const useGetOrdersTotals = () => {
  const organization = useGetUserOrganization();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  React.useEffect(() => {
    // Orders
    if (organization?.id) {
      dispatch(getAllOrdersTotals(getStatusVariables([], organization?.id)));
      dispatch(
        getOpenOrdersTotals(
          getStatusVariables(OPEN_ORDERS_STATUSES, organization?.id)
        )
      );
      dispatch(
        getCompleteOrdersTotals(
          getStatusVariables(COMPLETE_ORDERS_STATUSES, organization?.id)
        )
      );

      dispatch(
        getNeedsAttentionOrdersTotals(
          getStatusVariables(NEEDS_ATTENTION_ORDERS_STATUSES, organization?.id)
        )
      );

      dispatch(
        getInTransitOrdersTotals(
          getStatusVariables(IN_TRANSIT_ORDERS_STATUSES, organization?.id)
        )
      );

      const beforeDate = moment(Date.now()).utc().format();
      const afterDate = moment().subtract(20, 'days').utc().format();

      dispatch(
        getExpiringSoonOrdersTotals(
          getDateRangeVariables(beforeDate, afterDate, organization?.id)
        )
      );

      // Assets
      dispatch(getAllAssetsTotals(getStatusVariables([], organization?.id)));

      dispatch(
        getAllocatedAssetsTotals(
          getStatusVariables(ALLOCATED_ASSETS_STATUSES, organization?.id)
        )
      );

      dispatch(
        getInInventoryAssetsTotals(
          getStatusVariables(IN_INVENTORY_ASSETS_STATUSES, organization?.id)
        )
      );

      dispatch(
        getReadyToDeployAssetsTotals(
          getStatusVariables(READY_TO_DEPLOY_ASSETS_STATUSES, organization?.id)
        )
      );

      dispatch(
        getNeedsAttentionAssetsTotals(
          getStatusVariables(NEEDS_ATTENTION_ASSETS_STATUSES, organization?.id)
        )
      );
    }
  }, [organization, location]);
};
