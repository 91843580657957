import { SearchOption } from 'components/SearchScopeDropdown/types';
import { OrderManagementFilterFields } from './enum';

export enum OrderManagementSuggestionFilterName {
  ORDER_NUMBER = 'orderNumber',
  RECIPIENT_NAME = 'recipientName',
  RECIPIENT_EMAIL = 'recipientEmail',
}

export const OM_SEARCH_OPTIONS: SearchOption[] = [
  {
    displayName: 'Order number',
    filterName: OrderManagementSuggestionFilterName.ORDER_NUMBER,
  },
  {
    displayName: 'Recipient name',
    filterName: OrderManagementSuggestionFilterName.RECIPIENT_NAME,
  },
  {
    displayName: 'Recipient email',
    filterName: OrderManagementSuggestionFilterName.RECIPIENT_EMAIL,
  },
];

export const OM_FILTER_SLICE_NAME = 'ORDER_MANAGEMENT_FILTERS';

export const OM_DEFAULT_FILTER_STATE = {
  [OrderManagementFilterFields.ORDER_TYPE_NAME]: null, // Order Type
  [OrderManagementFilterFields.STATUS]: null, // Status
  [OrderManagementFilterFields.ORGANIZATION_ID]: null, // Account
  [OrderManagementFilterFields.ORDER_MANAGER_ID]: null, // Assignee
  [OrderManagementFilterFields.DEPOT_ID]: null, // Depot Location
};

export const OM_CLEAR_ALL_FILTERS_BUTTON_ID =
  'order-management-filters-clear-all-button';

export const UNASSIGNED_ORDER_MANAGER_ID_VALUE = 'UNASSIGNED::ORDER_MANAGER';
