import React from 'react';
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const FullTextInputTextSx: SxProps = {
  fontSize: '14px',
  p: '0',
  pl: 3,
  borderRadius: 0,
  textOverflow: 'ellipsis',
};

export const getAutoCompleteSx = (renderClearAllCTA: boolean): SxProps => ({
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: '40px',
  position: 'relative',

  '&:hover .MuiFormLabel-root.MuiInputLabel-root': {
    color: theme.palette.colors.woodSmoke,
  },

  '& .MuiAutocomplete-listbox': {
    mt: renderClearAllCTA ? 0 : 'inherit',
    pt: renderClearAllCTA ? 0 : 'inherit',
    height: renderClearAllCTA ? 'calc(100% - 200px)' : 'inherit',
    maxHeight: renderClearAllCTA ? 'calc(100% - 200px)' : 'inherit',
  },

  // remove the inner-chips w/ delete buttons
  '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium':
    {
      display: 'none',
    },

  // controls outline color when focused, title/label text color, & border color when active
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .Mui-focused.MuiFormLabel-root, & .Mui-focused.MuiInputLabel-root':
    {
      color: theme.palette.colors.woodSmoke,
      borderColor: theme.palette.colors.woodSmoke,
    },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
    pl: 0,

    '& .MuiFormLabel-root.MuiInputLabel-root': {
      top: '-8px',
      backgroundColor: theme.palette.colors.white,
      '&.MuiInputLabel-shrink': {
        top: '0px',
        zIndex: 1299,
      },
    },

    '& .MuiOutlinedInput-input::placeholder': {
      opacity: 1,
    },
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: '40px',
    py: 0,
    '&:not(.Mui-focused)': {
      '& .MuiOutlinedInput-input::placeholder': {
        opacity: 1,
      },
    },
  },
});

export const AutoCompleteSx: SxProps = {
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  height: '40px',
  position: 'relative',

  // remove the inner-chips w/ delete buttons
  '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium':
    {
      display: 'none',
    },

  // controls outline color when focused, title/label text color, & border color when active
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .Mui-focused.MuiFormLabel-root, & .Mui-focused.MuiInputLabel-root':
    {
      color: theme.palette.colors.woodSmoke,
      borderColor: theme.palette.colors.woodSmoke,
    },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
    pl: 0,

    '& .MuiFormLabel-root.MuiInputLabel-root': {
      top: '-8px',
      '&.MuiInputLabel-shrink': {
        top: '0px',
      },
    },

    '& .MuiOutlinedInput-input': {},
  },

  '& .MuiInputBase-formControl': {
    backgroundColor: 'transparent',
    height: '40px',
    py: 0,
  },
};

export const getFullTextCounterSx = (
  shouldRender: boolean,
  avatarRightOffset: number,
): SxProps => ({
  display: shouldRender ? 'flex' : 'none',
  fontSize: '12px',
  backgroundColor: theme.palette.colors.woodSmoke,
  position: 'absolute',
  height: '20px',
  top: '10px',
  width: '20px',
  zIndex: theme.zIndex.drawer,
  ...(avatarRightOffset && { right: `${avatarRightOffset}px` }),
});

export const ListOptionsTextSx: SxProps = {
  fontSize: '14px',
  lineHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

// CSS Properties -- options list
export const OptionListItemCSS: React.CSSProperties = {
  fontSize: '14px',
  maxHeight: '42px',
  paddingLeft: '6px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const getOptionListItemCSS = (
  selected: boolean,
  renderClearAllCTA: boolean,
): React.CSSProperties => ({
  ...(renderClearAllCTA && { marginTop: '32px' }),
  backgroundColor: selected ? '#f6f6f6' : 'transparent',
  fontSize: '14px',
  maxHeight: '42px',
  paddingLeft: '6px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const InnerPopperSx: SxProps = {
  '& .Mui-focused': {
    backgroundColor: 'rgba(0,0,0,.12) !important',
  },
};

export const ClearStackOverrideSx: SxProps = {
  borderRadius: 0,
  border: 'none',
  boxSizing: 'border-box',
  display: 'static',
  height: '50px',
  left: '1px',
  top: 0,
  mt: 0,
  position: 'absolute',
};
