import { v2FindAssetsByStatusTransition } from 'api/apollo/queries/v2/assets';

export const v2FindAssetsByStatusTransitionOutput = `
    count
    assets {
        id
        assetType {
            name
        }
        assignee {
            id
            firstName
            lastName
        }
        assetStatusTransitions {
            id
            assetId
            from
            to
            createdBy
            createdAt
            updatedAt
            deletedAt
        }
        assetStatusTransition {
            id
            assetId
            from
            to
            createdAt
            updatedAt
            deletedAt
        }
        updatedAt
        createdAt
        deletedAt
        organizationId
        status
    }
  `;

export const FIND_ASSETS_BY_STATUS_TRANSITION_QUERY =
  v2FindAssetsByStatusTransition(v2FindAssetsByStatusTransitionOutput);
