import { gql } from '@apollo/client';

export const getV2FindAndCountOrders = (output: string) => gql`
  query getPathOrders(
    $where: V2OrderWhereInput
    $orderBy: [V2OrderOrderByWithRelationInput!]
    $cursor: V2OrderWhereUniqueInput
    $take: Int
    $skip: Int
  ) {
    v2FindAndCountOrders(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      take: $take
      skip: $skip
    ) {
      ${output}
    }
  }
`;
