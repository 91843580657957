import React from 'react';
import Stack from '@mui/material/Stack';
import { FAQCardProps } from './types';
import * as styles from './styles';

const FAQCard = (props: FAQCardProps) => {
  const { links, icon } = props;
  return (
    <Stack sx={styles.FAQCardContainerSx}>
      <Stack sx={styles.FAQCardIconSx}>
        <props.icon />
      </Stack>
      <Stack sx={styles.FAQCardLinksSx}>
        {links.map((link, index) => {
          return (
            <a
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Stack sx={styles.FAQCardLinkRowSx(index)}>
                <Stack sx={styles.FAQCardLinkCopySx}>{link.copy}</Stack>
                <Stack sx={styles.FAQCardLinkSx}>
                  <link.icon />
                </Stack>
              </Stack>
            </a>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FAQCard;
