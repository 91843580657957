import React from 'react';
import { OrdersFAQCardsProps } from './types';
import Stack from '@mui/material/Stack';

import FAQCard from '../FAQCard';

import * as styles from './styles';

const FAQCards = (props: OrdersFAQCardsProps) => {
  const { title, cards } = props;
  return (
    <Stack sx={styles.OrdersFAQCardsContainerSx}>
      <Stack sx={styles.OrdersCardsTitleSx}>{title}</Stack>
      <Stack sx={styles.OrdersCardsListContainerSx}>
        {cards.map((card, index) => {
          return <FAQCard links={card.links} icon={card.icon} key={index} />;
        })}
      </Stack>
    </Stack>
  );
};

export default FAQCards;
