/* eslint-disable sort-exports/sort-exports */
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { GET_ORDER_ITEMS_BY_ORDER_ID } from 'pages/OrderManagement/components/DetailedOrderView/constants';
import { useSafeMutation } from 'hooks/useSafeMutation';
import {
  IAddOrderItemByAssetFieldValues,
  AddOrderItemByAssetFormProps,
} from './types';
import { CREATE_ORDER_ITEMS_FOR_ASSETS } from './mutations';
import {
  ADD_BY_ASSET_ID_INPUT_ID,
  ASSET_ID,
  ASSET_ID_LABEL,
  ADD_BY_SERIAL_NUMBER_INPUT_ID,
  SERIAL_NUMBER,
  SERIAL_NUMBER_LABEL,
  ADD_ORDER_ITEMS_TEXT_FIELD_ROWS,
} from './constants';
import * as AddOrderItemFormStyles from './styles';
import { cleanupOrderItemEntries } from './utils';

const AddOrderItemByAssetForm = (props: AddOrderItemByAssetFormProps) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const styles = AddOrderItemFormStyles;
  const {
    control,
    register,
    watch,
    setError,
    resetField,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IAddOrderItemByAssetFieldValues>({
    defaultValues: {
      insuranceRequested: '0.00',
      assetIds: '',
      serialNumbers: '',
    },
    mode: 'onChange',
  });

  const isUpdateDisabled = () => !(isDirty && isValid);

  const handleChangeCapture = (event: any) => {
    if (!event) return;
    const { name: eventSourceName } = event.target;
    const { assetIds, serialNumbers } = watch();
    if (eventSourceName === ASSET_ID && serialNumbers) {
      resetField(SERIAL_NUMBER);
    }
    if (eventSourceName === SERIAL_NUMBER && assetIds) {
      resetField(ASSET_ID);
    }
  };

  const showChangesSavedSuccessfully = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
  };

  const showChangesSavedUnsuccessfully = (reason: string) => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: reason,
      })
    );
  };

  const refetchThenCloseModal = async () => {
    await client.refetchQueries({
      include: [GET_ORDER_ITEMS_BY_ORDER_ID],
    });
    props.closeModal();
  };

  const [createOrderItemsByAsset, { loading }] = useSafeMutation(
    CREATE_ORDER_ITEMS_FOR_ASSETS,
    {
      onCompleted: () => {
        showChangesSavedSuccessfully();
        refetchThenCloseModal();
      },
    }
  );

  const throwAssetIdError = (errorReason: string) => {
    setError(ASSET_ID, {
      type: 'custom',
      message: errorReason,
    });
  };

  const handleAddOrderItemByAssetSubmit = (
    formData: IAddOrderItemByAssetFieldValues
  ) => {
    const { assetIds, serialNumbers: serialNos } = formData;
    const hasSerialNumbers = !!serialNos && !assetIds;
    const hasAssetIds = !!assetIds && !serialNos;

    if (hasSerialNumbers) {
      const serialNumbers = cleanupOrderItemEntries(serialNos);
      const variables = {
        orderId: props.orderId,
        serialNumbers,
      };

      createOrderItemsByAsset({ variables });
    }

    if (hasAssetIds) {
      const assetNumbers = cleanupOrderItemEntries(assetIds);
      const variables = {
        orderId: props.orderId,
        assetNumbers,
      };

      createOrderItemsByAsset({ variables });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleAddOrderItemByAssetSubmit)}
      style={{ height: '100%' }}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ height: '100%', width: '100%' }}
      >
        <Stack direction="column" justifyContent="flex-start">
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={12} sx={{ mt: '16px', position: 'relative' }}>
              <Controller
                name={ASSET_ID}
                control={control}
                render={({ field }) => (
                  <TextField
                    id={ADD_BY_ASSET_ID_INPUT_ID}
                    label={ASSET_ID_LABEL}
                    aria-multiline
                    multiline
                    rows={ADD_ORDER_ITEMS_TEXT_FIELD_ROWS}
                    variant="outlined"
                    {...field}
                    sx={styles.AddOrderItemAssetInputSx}
                    {...register(ASSET_ID, {
                      required: false,
                    })}
                    onChangeCapture={handleChangeCapture}
                    error={!!errors.assetIds}
                  />
                )}
              />
              {!!errors.assetIds && (
                <Typography
                  variant="body2"
                  color="red"
                  sx={{ position: 'absolute', top: '58px' }}
                >
                  {errors.assetIds.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mt: '16px', position: 'relative' }}>
              <span
                style={{
                  backgroundColor: 'white',
                  padding: '0 8px',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                or
              </span>
              <hr style={{ border: '1px solid rgba(19, 20, 20, 0.12)' }} />
            </Grid>
            <Grid item xs={12} sx={{ mt: '16px', position: 'relative' }}>
              <Controller
                name={SERIAL_NUMBER}
                control={control}
                render={({ field }) => (
                  <TextField
                    id={ADD_BY_SERIAL_NUMBER_INPUT_ID}
                    label={SERIAL_NUMBER_LABEL}
                    aria-multiline
                    multiline
                    rows={ADD_ORDER_ITEMS_TEXT_FIELD_ROWS}
                    variant="outlined"
                    {...field}
                    sx={styles.AddOrderItemAssetInputSx}
                    {...register(SERIAL_NUMBER, {
                      required: false,
                    })}
                    onChangeCapture={handleChangeCapture}
                    // error={!!errors.assetNumber}
                  />
                )}
              />
            </Grid>
            {/* {!!errors.assetId && (
              <Typography
                variant="body2"
                color="red"
                sx={{ position: 'absolute', top: '58px' }}
              >
                {errors.assetId.message}
              </Typography>
            )} */}
          </Grid>
        </Stack>
        <ButtonGroup sx={styles.AddOrderItemCancelButtonGroupSx}>
          <Button onClick={props.closeModal} sx={{ mr: '16px' }}>
            Cancel
          </Button>
          <LoadingButton
            disabled={isUpdateDisabled()}
            variant="contained"
            type="submit"
            value="submit"
            loading={loading}
            color="primary"
            loadingIndicator={<CircularProgress color="primary" size={20} />}
          >
            Save
          </LoadingButton>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

export default AddOrderItemByAssetForm;
