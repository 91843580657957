import { OMSearchComboTextKeys } from './types';

export const OMSearchFilterKeys: Record<string, OMSearchComboTextKeys> = {
  ORDER_TYPE: 'orderTypeFilter',
  STATUS: 'statusFilter',
  ORGANIZATION_ID: 'organizationFilter',
  ORDER_MANAGER_ID: 'orderManagerFilter',
  DEPOT_ID: 'depotFilter',
  LOOKAHEAD_OPTION_TYPE: 'lookAheadOptionType',
  LOOKAHEAD_OPTION: 'lookAheadOption',
};
