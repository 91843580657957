import React from 'react';
import IconButton from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { WHATS_NEW_URL_LINK } from './constants';
import * as styles from './styles';

const WhatsNewButton = () => {
  return (
    <Stack sx={styles.WhatsNewContainerSx}>
      <a
        href={WHATS_NEW_URL_LINK}
        target={'_blank'}
        style={styles.WhatsNewALinkSx}
      >
        <IconButton
          fullWidth={false}
          sx={styles.WhatsNewButtonSx}
          startIcon={<CardGiftcardIcon />}
        >
          <Stack sx={styles.WhatsNewCopySx} id="whats-new-copy">
            What's New
          </Stack>
        </IconButton>
      </a>
    </Stack>
  );
};

export default WhatsNewButton;
