import React from 'react';
import { SVGProps } from '../../types';

const PendingWipeIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F9C54D" />
      <path
        d="M13.5 11.3327V11.8327H14H14.6667C16.2305 11.8327 17.5 13.1022 17.5 14.666V18.8327H6.5V14.666C6.5 13.1022 7.76948 11.8327 9.33333 11.8327H10H10.5V11.3327V5.99935C10.5 5.54216 10.8761 5.16602 11.3333 5.16602H12.6667C13.1239 5.16602 13.5 5.54216 13.5 5.99935V11.3327ZM11.3333 5.49935H10.8333V5.99935V11.3327V11.8327H11.3333H12.6667H13.1667V11.3327V5.99935V5.49935H12.6667H11.3333ZM16.6667 18.4993H17.1667V17.9993V14.666C17.1667 13.2899 16.0428 12.166 14.6667 12.166H9.33333C7.95719 12.166 6.83333 13.2899 6.83333 14.666V17.9993V18.4993H7.33333H8.66667H9.16667V17.9993V15.9993C9.16667 15.9088 9.24281 15.8327 9.33333 15.8327C9.42386 15.8327 9.5 15.9088 9.5 15.9993V17.9993V18.4993H10H11.3333H11.8333V17.9993V15.9993C11.8333 15.9088 11.9095 15.8327 12 15.8327C12.0905 15.8327 12.1667 15.9088 12.1667 15.9993V17.9993V18.4993H12.6667H14H14.5V17.9993V15.9993C14.5 15.9088 14.5761 15.8327 14.6667 15.8327C14.7572 15.8327 14.8333 15.9088 14.8333 15.9993V17.9993V18.4993H15.3333H16.6667Z"
        fill="#131414"
        stroke="#131414"
      />
    </svg>
  );
};

export default PendingWipeIcon;
