import React from 'react';
import { SVGProps } from '../../types';

const ExceptionIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F27058" />
      <path
        d="M12.1668 14.4987V14.832H11.8335V14.4987H12.1668ZM12.1668 9.16536V12.1654H11.8335V9.16536H12.1668ZM5.8335 11.9987C5.8335 8.59419 8.59029 5.83203 11.9935 5.83203C15.4043 5.83203 18.1668 8.59517 18.1668 11.9987C18.1668 15.4022 15.4043 18.1654 11.9935 18.1654C8.59029 18.1654 5.8335 15.4032 5.8335 11.9987ZM6.16683 11.9987C6.16683 15.2215 8.77735 17.832 12.0002 17.832C15.223 17.832 17.8335 15.2215 17.8335 11.9987C17.8335 8.77589 15.223 6.16536 12.0002 6.16536C8.77735 6.16536 6.16683 8.77589 6.16683 11.9987Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default ExceptionIcon;
