import { RootState } from 'store';
import OrderManagementSearchState, { OMSearchComboTextKeys } from './types';

/**
 *
 * @description Selector to get the Order Management Search State
 */
export const selectOMSearchState = (state: RootState) =>
  state.orderManagement.search as OrderManagementSearchState;

/**
 *
 * @description Selector to get a specific field the Order Management Search State
 */
export const selectOMSearchByKey =
  (searchKey: OMSearchComboTextKeys) => (state: RootState) =>
    state.orderManagement.search[searchKey];

export const selectOrderManagerIsLoadingStatus = (state: RootState) =>
  state.orderManagement.search.isLoadingOrderManagers;

export const selectOMSelfAssignSwitchChecked = (state: RootState) =>
  state.orderManagement.search.isOMSelfAssignChecked;
