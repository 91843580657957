import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AllwhereLogo from 'assets/allwhere.png';
import { AllwhereLogoStyles, AppBarSx } from './styles';
import { NavigationContext } from 'context/NavigationContext';
import { useGlobalState } from 'state';
import MobileMenu from './components/MobileMenu';
import UserAvatar from '../misc/UserAvatar';
import { Theme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectGlobalNotificationBanner } from 'store/slices/global/selectors';
import WhatsNewButton from 'components/buttons/WhatsNewButton';

const AppBar = () => {
  /* REMOVE below to render Appbar according to route and NOT Acumatica Maintenance Page */
  // const siteMaintenanceContext = React.useContext(SiteMaintenanceContext);
  // if (siteMaintenanceContext.showMaintenancePage) return null;

  const [cta] = useGlobalState('appBarCTAs');
  const navigator = React.useContext(NavigationContext);
  const globalNotificationBanner = useSelector(selectGlobalNotificationBanner);

  const MuiAppBarSx = {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    ...AppBarSx(globalNotificationBanner.show),
  };
  return (
    <MuiAppBar id="appbar" position="fixed" sx={MuiAppBarSx}>
      <MobileMenu navigator={navigator} />
      <Box display={{ xs: 'none', lg: 'flex' }}>
        <IconButton
          disableRipple
          aria-label="top-nav-home-icon-button"
          href={navigator.pathToHome()}
        >
          <img
            src={AllwhereLogo}
            alt="allwhere logo"
            style={AllwhereLogoStyles}
          />
        </IconButton>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <WhatsNewButton />
        </Stack>
        <>{cta?.components}</>
        <Stack direction="row" spacing={2} marginLeft="1rem">
          <UserAvatar navigator={navigator} />
        </Stack>
      </Stack>
    </MuiAppBar>
  );
};

export default AppBar;
