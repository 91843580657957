import { OrderStatus } from 'global-constants';
import { getOrderItemsByOrderId } from 'api/apollo/queries/v1/order-management';

export const ORDER_MGMT_NEW_MODAL_MAX_PAPER_WIDTH = '1337px';
export const XS_SM_MODAL_HEIGHT_OFFSET = '376px';
export const MD_XL_MODAL_HEIGHT_OFFSET = '272px';
export const XS_SM_MODAL_WIDTH_OFFSET = '64px';
export const MD_XL_MODAL_WIDTH_OFFSET = '48px';
export const MD_XL_MODAL_INNER_HEIGHT_OFFSET = '370px';

const CANCELED = 'CANCELED';
export const READ_ONLY_ORDER_TRANSITION_STATUSES = [
  CANCELED,
  ...Object.keys(OrderStatus).slice(1),
] as const;

export const GET_ORDER_ITEMS_BY_ORDER_ID_OUTPUT = `
  id
  orderId
  orderItemNumber
  assetId
  insuranceRequested
  insuranceCharged
  serialNumber
  quantity
  processDevice
  mdmPin
  knownIssues
  inboundOrderItemId
  protectionPlan
  productVariant {
    product {
      name
    }
    sku
    productVariantAttributes {
      value
    }
  }
  asset {
    assetNumber
    make
    model
    assetType {
      id
      name
    }
  }
`;

export const GET_ORDER_ITEMS_BY_ORDER_ID = getOrderItemsByOrderId(
  GET_ORDER_ITEMS_BY_ORDER_ID_OUTPUT
);
