import React from 'react';
import { useNavigate } from 'react-router-dom';

export const useHandleGoToLink = () => {
  const navigate = useNavigate();
  return (link?: string) =>
    React.useCallback((event:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      navigate(link || '/');
    }, []);
};
