import { DATABASE_ORDER_STATUSES } from 'globals/constants/orders';
import {
  COMPLEX_SEARCH_ASSET_STATUSES,
  DATABASE_ASSET_STATUSES,
} from 'globals/constants/assets';

export const CONTAINER_TEST_DATA_ID = 'home-update-container-test-id';
export const ORDERS_API_ERROR_MESSAGE = 'Failed to fetch orders';
export const ASSETS_API_ERROR_MESSAGE = 'Failed to fetch assets';

export const OPEN_ORDERS_STATUSES = [
  DATABASE_ORDER_STATUSES.CREATED,
  DATABASE_ORDER_STATUSES.IN_FULFILLMENT,
  DATABASE_ORDER_STATUSES.SHIPPED,
  DATABASE_ORDER_STATUSES.IN_RETURN,
  DATABASE_ORDER_STATUSES.PENDING_RETURN,
  DATABASE_ORDER_STATUSES.PENDING_CERTIFICATE,
  DATABASE_ORDER_STATUSES.PENDING_APPROVAL,
  DATABASE_ORDER_STATUSES.PROCESSING_DEVICE,
  DATABASE_ORDER_STATUSES.PENDING_REPAIR,
  DATABASE_ORDER_STATUSES.QUOTE_IN_PROGRESS,
  DATABASE_ORDER_STATUSES.PENDING_RECYCLE,
  DATABASE_ORDER_STATUSES.PENDING_RECIPIENT_INFORMATION,
  DATABASE_ORDER_STATUSES.EXCEPTION,
  DATABASE_ORDER_STATUSES.PENDING_QUOTE_APPROVAL,
];

export const IN_TRANSIT_ORDERS_STATUSES = [
  DATABASE_ORDER_STATUSES.SHIPPED,
  DATABASE_ORDER_STATUSES.IN_RETURN,
  DATABASE_ORDER_STATUSES.EXCEPTION,
];

export const COMPLETE_ORDERS_STATUSES = [DATABASE_ORDER_STATUSES.COMPLETE];

export const NEEDS_ATTENTION_ORDERS_STATUSES = [
  DATABASE_ORDER_STATUSES.EXCEPTION,
  DATABASE_ORDER_STATUSES.PENDING_QUOTE_APPROVAL,
  DATABASE_ORDER_STATUSES.PENDING_RECIPIENT_INFORMATION,
  DATABASE_ORDER_STATUSES.PENDING_RETURN,
];

export const EXPIRING_SOON_ORDERS_STATUSES = [
  DATABASE_ORDER_STATUSES.PENDING_RECIPIENT_INFORMATION,
  DATABASE_ORDER_STATUSES.PENDING_RETURN,
];

export const ALL_ASSETS_STATUSES = [];

export const ALLOCATED_ASSETS_STATUSES = [DATABASE_ASSET_STATUSES.ALLOCATED];

export const IN_INVENTORY_ASSETS_STATUSES = [
  DATABASE_ASSET_STATUSES.IN_INVENTORY,
  DATABASE_ASSET_STATUSES.IN_INVENTORY_DEVICE_ISSUE,
  DATABASE_ASSET_STATUSES.HOLD,
  DATABASE_ASSET_STATUSES.MDM_LOCKED,
  DATABASE_ASSET_STATUSES.ACTIVATION_LOCKED,
  DATABASE_ASSET_STATUSES.RECOVERY_LOCKED,
  DATABASE_ASSET_STATUSES.PROCESSING_DEVICE,
];

export const READY_TO_DEPLOY_ASSETS_STATUSES = [
  DATABASE_ASSET_STATUSES.IN_INVENTORY,
];

export const NEEDS_ATTENTION_ASSETS_STATUSES = [
  DATABASE_ASSET_STATUSES.ACTIVATION_LOCKED,
  DATABASE_ASSET_STATUSES.RECOVERY_LOCKED,
  DATABASE_ASSET_STATUSES.HOLD,
  DATABASE_ASSET_STATUSES.MDM_LOCKED,
  DATABASE_ASSET_STATUSES.IN_INVENTORY_DEVICE_ISSUE,
  DATABASE_ASSET_STATUSES.PENDING_QUOTE_APPROVAL,
];

export const COMPLEX_SEARCH_ALLOCATED_ASSETS_STATUSES = [
  COMPLEX_SEARCH_ASSET_STATUSES.ALLOCATED,
];

export const COMPLEX_SEARCH_IN_INVENTORY_ASSETS_STATUSES = [
  COMPLEX_SEARCH_ASSET_STATUSES.IN_INVENTORY,
  COMPLEX_SEARCH_ASSET_STATUSES.IN_INVENTORY_DEVICE_ISSUE,
  COMPLEX_SEARCH_ASSET_STATUSES.HOLD,
  COMPLEX_SEARCH_ASSET_STATUSES.MDM_LOCKED,
  COMPLEX_SEARCH_ASSET_STATUSES.ACTIVATION_LOCKED,
  COMPLEX_SEARCH_ASSET_STATUSES.RECOVERY_LOCKED,
  COMPLEX_SEARCH_ASSET_STATUSES.PROCESSING_DEVICE,
];

export const COMPLEX_SEARCH_READY_TO_DEPLOY_ASSETS_STATUSES = [
  COMPLEX_SEARCH_ASSET_STATUSES.IN_INVENTORY,
];

export const COMPLEX_SEARCH_NEEDS_ATTENTION_ASSETS_STATUSES = [
  COMPLEX_SEARCH_ASSET_STATUSES.ACTIVATION_LOCKED,
  COMPLEX_SEARCH_ASSET_STATUSES.RECOVERY_LOCKED,
  COMPLEX_SEARCH_ASSET_STATUSES.HOLD,
  COMPLEX_SEARCH_ASSET_STATUSES.MDM_LOCKED,
  COMPLEX_SEARCH_ASSET_STATUSES.IN_INVENTORY_DEVICE_ISSUE,
  COMPLEX_SEARCH_ASSET_STATUSES.PENDING_QUOTE_APPROVAL,
];
