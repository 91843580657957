/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { SxProps } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { useSafeQuery } from 'hooks/useSafeQuery';
import Avatar from '@mui/material/Avatar';
import GenericAutocomplete from 'components/GenericAutocomplete';
import TextField from '@mui/material/TextField';
import { renderOptionListItemHOC } from 'components/complex_filter/fields/utils';
import { FilterEntry } from 'store/shared/types';
import FullTextProps from './types';
import * as FullTextStyles from './styles';

const FullText = (props: FullTextProps) => {
  const styles = FullTextStyles;
  const { user } = useAuth0();
  const orgId = user?.organizationId ?? '';
  const { baseSelectorPrefix } = props;

  const getSelectEntries = props.selectChosenEntries(
    props.filterSubTree,
    props.filterKey
  );
  const selectedEntries = useSelector(getSelectEntries);

  const getIsFilterDisabled = props.selectIsFilterDisabled(
    props.filterSubTree,
    props.filterKey,
  );

  const isFilterDisabled = useSelector(getIsFilterDisabled);

  const getAvailableEntries = props.selectAvailableEntries(
    props.filterSubTree,
    props.filterKey
  );
  const availableEntries = useSelector(getAvailableEntries);

  const getExpandedInput = props.selectExpandedFilter(props.filterSubTree);
  const expandedInput = useSelector(getExpandedInput);

  const getSearchInput = props.selectSearchInputByKey(
    props.filterSubTree,
    props.filterKey
  );
  const searchInput = useSelector(getSearchInput) || '';

  const isOpen = expandedInput === props.filterKey;

  const minimumSearchLength = props?.minimumSearchLength ?? 2;

  const getQueryVariables = (searchInput: string) => {
    if (!props?.getQueryVariables) return { variables: {} };
    return props.getQueryVariables(searchInput);
  };

  const { refetch } = useSafeQuery(props.lookupQuery, {
    ...getQueryVariables(searchInput),
    onCompleted: props.onCompletedHOF(
      props.filterKey,
      props.onBatchUpsertFilterEntries,
      searchInput,
    ),
    skip: !searchInput || searchInput.length < minimumSearchLength,
  });

  const refetchFullText = () => {
    const { variables } = getQueryVariables(searchInput);

    refetch({
      ...variables,
    });
  };

  React.useEffect(() => {
    if (searchInput.length >= minimumSearchLength) {
      debounce(refetchFullText, 300);
    }
  }, [searchInput, orgId]);

  const handleInputChange = props.onSearchChangeByKey(props.filterKey);
  const handleBlurClear = props.onBlurClearByKey(props.filterKey);

  const hasSelections = selectedEntries.length > 0;

  const getOptions = (): FilterEntry[] => {
    if (hasSelections) {
      const unavailableEntries = availableEntries.filter(
        (entry: FilterEntry) => !entry.available
      );
      return [...unavailableEntries, ...availableEntries];
    }
    return availableEntries;
  };

  const clearAllSelections = (evt: any) => {
    props.onClearSelectionsByKey(props.filterKey);
  };

  const placeholder =
    selectedEntries.length === 0 || isOpen ? (props?.inputCTA ?? 'Start typing') : props.placeholder;

  const getOptionListItem = renderOptionListItemHOC(clearAllSelections, {
    hasSelections,
    entriesLength: availableEntries.length,
    baseSelectorPrefix,
  });

  const handleClose = () => {
    props.onCloseInput();
  };

  const handleOpen = () => {
    props.onOpenInput(props.filterKey);
  };

  const shouldRenderCounter = !isOpen && selectedEntries.length > 0;

  const getOptionLabel = (option: string | FilterEntry) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.displayName;
  };

  const noOptionsText = searchInput && searchInput.length >= minimumSearchLength 
    ? props.notFoundFallback : 'No options';

  const popperId = `${props.baseSelectorPrefix}-popper-id`;
  const paperId = `${props.baseSelectorPrefix}-paper-id`;
  const counterAvatarTestId = `${props.baseSelectorPrefix}-counter-avatar-id`;
  const avatarRightOffset = props.counterAvatarRightOffset || 0;

  const dropDownSx: SxProps = props.customDropDownSx || { width: '300px', position: 'relative', right: '87px' };

  return (
    <>
      <GenericAutocomplete
        multiple
        disabled={isFilterDisabled}
        disableCloseOnSelect
        disablePortal
        clearOnBlur={false}
        loading={false}
        noOptionsText={noOptionsText}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={props.onFullTextOptionsChange(props.filterKey)}
        onInputChange={handleInputChange}
        inputValue={searchInput}
        value={selectedEntries}
        options={getOptions()}
        getOptionLabel={getOptionLabel}
        sx={styles.getAutoCompleteSx(hasSelections)}
        componentsProps={{
          paper: {
            id: paperId,
            sx: dropDownSx,
          },
          clearIndicator: {
            style: {
              display: 'none',
            },
          },
          popper: {
            keepMounted: true,
            id: popperId,
            sx: styles.InnerPopperSx,
            placement: 'bottom'
          },
        }}
        renderOption={(props, option, { selected }) =>
          getOptionListItem(props, option, selected)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={handleBlurClear}
            name={props.filterKey}
            placeholder={placeholder}
            label={props.placeholder}
            aria-label={props.placeholder}
            sx={styles.FullTextInputTextSx}
          />
        )}
      />
      <Avatar
        data-testid={counterAvatarTestId}
        sx={styles.getFullTextCounterSx(
          shouldRenderCounter,
          avatarRightOffset
        )}
      >
        {selectedEntries.length}
      </Avatar>
    </>
  );
};

export default FullText;
