export const ShipmentStatuses: {
    [index: string]: string;
}= {
    /**
   * Carrier attempted to deliver but failed,,
   * and usually leaves a notice and will try to deliver again.
   */
  ATTEMPT_FAILED: 'ATTEMPT_FAILED',

  /**
   * The package arrived at a pickup point near you and is available for pickup.
   */
  AVAILABLE_FOR_PICKUP: 'AVAILABLE_FOR_PICKUP',

  /**
   * The default value if no value is supply during creation.
   */
  CREATED: 'CREATED',

  /**
   * The shipment was delivered successfully.
   */
  DELIVERED: 'DELIVERED',

  /**
   * Custom hold,, undelivered,, returned shipment to sender or any shipping exceptions.
   */
  EXCEPTION: 'EXCEPTION',

  /**
   * Shipment has no tracking information for 30 days since added.
   */
  EXPIRED: 'EXPIRED',

  /**
   * Carrier has accepted or picked up shipment from shipper. The shipment is on the way.
   */
  INFO_RECEIVED: 'INFO_RECEIVED',

  /**
   * Carrier has received request from shipper and is about to pick up the shipment.
   */
  IN_TRANSIT: 'IN_TRANSIT',

  /**
   * Carrier is about to deliver the shipment ,, or it is ready to pickup.
   */
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',

  /**
   * New shipments added that are pending to track,,
   * or new shipments without tracking information available yet.
   */
  PENDING: 'PENDING',
};

export type ShipmentStatus = typeof ShipmentStatuses[keyof typeof ShipmentStatuses];

export const ShipmentSubstatuses: {
  [index: string]: string;
}= {
    ACCEPTANCE_SCAN: 'Acceptance scan',
    ADDRESSEE_NOT_AVAILABLE: 'Addressee not available',
    ARRIVAL_SCAN: 'Arrival scan',
    ARRIVED_AT_THE_DESTINATION_COUNTRY_REGION: 'Arrived at the destination country/region',
    AVAILABLE_FOR_PICKUP: 'Available for pickup',
    BUSINESS_CLOSED: 'Business closed',
    CARRIER_ACCOUNT_NOT_CONNECTED: 'Carrier account not connected',
    CUSTOMER_CONTACTED: 'Customer contacted',
    CUSTOMER_MOVED: 'Customer moved',
    CUSTOMER_REFUSED_DELIVERY: 'Customer refused delivery',
    CUSTOMS_CLEARANCE_COMPLETED: 'Customs clearance completed',
    CUSTOMS_CLEARANCE_STARTED: 'Customs clearance started',
    DELAYED_CUSTOMS_CLEARANCE: 'Delayed (Customs clearance)',
    DELAYED_EXTERNAL_FACTORS: 'Delayed (External factors)',
    DELIVERED: 'Delivered',
    DELIVERED_AND_RECEIVED_CASH_ON_DELIVERY: 'Delivered and received cash on delivery',
    DELIVERY_APPOINTMENT_SCHEDULED: 'Delivery appointment scheduled',
    DEPARTURE_SCAN: 'Departure scan',
    EXCEPTION: 'Exception',
    EXPIRED: 'Expired',
    FAILED_ATTEMPT: 'Failed attempt',
    FORWARDED_TO_A_DIFFERENT_DELIVERY_ADDRESS: 'Forwarded to a different delivery address',
    HELD_FOR_PAYMENT: 'Held for payment',
    INCORRECT_ADDRESS: 'Incorrect address',
    INFO_RECEIVED: 'Info received',
    IN_TRANSIT: 'In transit',
    LABEL_CREATED_NO_UPDATES_YET: 'Label created, no updates yet',
    NO_RECENT_UPDATES: 'No recent updates',
    OUT_FOR_DELIVERY: 'Out for delivery',
    PENDING: 'Pending',
    PICKED_UP_BY_THE_CUSTOMER: 'Picked up by the customer',
    PICK_UP_MISSED: 'Pick up missed',
    PROBLEM_RESOLVED: 'Problem resolved',
    REJECTED_BY_CARRIER: 'Rejected by carrier',
    RETURNED_TO_SENDER: 'Returned to sender',
    RETURNING_TO_SENDER: 'Returning to sender',
    SHIPMENT_DAMAGE: 'Shipment damage',
    SHIPMENT_LOST: 'Shipment lost',
    SIGN_BY_CUSTOMER: 'Sign by customer',
    UNRECOGNIZED_CARRIER: 'Unrecognized carrier',
    WRONG_CARRIER: 'Wrong carrier',
};

export type ShipmentSubstatus = typeof ShipmentSubstatuses[keyof typeof ShipmentSubstatuses];
