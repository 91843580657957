import { FilterStateKey } from 'store/shared/types';
import UseGetFilterDrawerConfig from 'components/complex_filter/ComplexFilterDrawer/types';
import { useGetFilterDrawerConfig as useGetFilterDrawerConfigBase } from 'components/complex_filter/hooks';

export const useGetFilterDrawerConfig = (
  filterSubTree: FilterStateKey
): UseGetFilterDrawerConfig | undefined => {
  const baseFilterDrawerConfig = useGetFilterDrawerConfigBase(filterSubTree);

  if (!baseFilterDrawerConfig) return undefined;

  return {
    ...baseFilterDrawerConfig,
  } as UseGetFilterDrawerConfig;
};
