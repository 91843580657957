import { RootState } from 'store/index';

export const selectAllOrdersCount = (state: RootState) => {
  return state.home.totals.allOrdersCount;
};

export const selectAllOrdersCountLoading = (state: RootState) => {
  return state.home.totals.allOrdersCountLoading;
};

export const selectOpenOrdersCount = (state: RootState) => {
  return state.home.totals.openOrdersCount;
};

export const selectOpenOrdersCountLoading = (state: RootState) => {
  return state.home.totals.openOrdersCountLoading;
};

export const selectInTransitOrdersCount = (state: RootState) => {
  return state.home.totals.inTransitOrdersCount;
};

export const selectInTransitOrdersCountLoading = (state: RootState) => {
  return state.home.totals.inTransitOrdersCountLoading;
};

export const selectCompleteOrdersCount = (state: RootState) => {
  return state.home.totals.completeOrdersCount;
};

export const selectCompleteOrdersCountLoading = (state: RootState) => {
  return state.home.totals.completeOrdersCountLoading;
};

export const selectNeedsAttentionOrdersCount = (state: RootState) => {
  return state.home.totals.needsAttentionOrdersCount;
};

export const selectNeedsAttentionOrdersCountLoading = (state: RootState) => {
  return state.home.totals.needsAttentionOrdersCountLoading;
};

export const selectExpiringSoonOrdersCount = (state: RootState) => {
  return state.home.totals.expiringSoonOrdersCount;
};

export const selectExpiringSoonOrdersCountLoading = (state: RootState) => {
  return state.home.totals.expiringSoonOrdersCountLoading;
};

// Assets

export const selectAllAssetsCount = (state: RootState) => {
  return state.home.totals.allAssetsCount;
};

export const selectAllAssetsCountLoading = (state: RootState) => {
  return state.home.totals.allAssetsCountLoading;
};

export const selectAllocatedAssetsCount = (state: RootState) => {
  return state.home.totals.allocatedAssetsCount;
};

export const selectAllocatedAssetsCountLoading = (state: RootState) => {
  return state.home.totals.allocatedAssetsCountLoading;
};

export const selectInInventoryAssetsCount = (state: RootState) => {
  return state.home.totals.inInventoryAssetsCount;
};

export const selectInInventoryAssetsCountLoading = (state: RootState) => {
  return state.home.totals.inInventoryAssetsCountLoading;
};

export const selectReadyToDeployAssetsCount = (state: RootState) => {
  return state.home.totals.readyToDeployAssetsCount;
};

export const selectReadyToDeployAssetsCountLoading = (state: RootState) => {
  return state.home.totals.readyToDeployAssetsCountLoading;
};

export const selectNeedsAttentionAssetsCount = (state: RootState) => {
  return state.home.totals.needsAttentionAssetsCount;
};

export const selectNeedsAttentionAssetsCountLoading = (state: RootState) => {
  return state.home.totals.needsAttentionAssetsCountLoading;
};
