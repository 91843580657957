import { RootState } from 'store/index';

export const selectAssets = (state: RootState) => {
  return state.home.assets.assets;
};

export const selectAssetsCount = (state: RootState) => {
  return state.home.assets.count;
};

export const selectAssetsSkip = (state: RootState) => {
  return state.home.assets.skip;
};

export const selectAssetsTake = (state: RootState) => {
  return state.home.assets.take;
};

export const selectAssetsLoading = (state: RootState) => {
  return state.home.assets.loading;
};
