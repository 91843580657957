import { createSlice } from '@reduxjs/toolkit';

import {
  setInitialOrdersReducer,
  setOrdersReducer,
  setOrdersCountReducer,
  setOrdersTakeReducer,
  setOrdersSkipReducer,
  setOrdersLoadingReducer,
  getOrdersPendingReducer,
  getOrdersFulfilledReducer,
  getOrdersRejectedReducer,
  getHoursSavedsPendingReducer,
  getHoursSavedFulfilledReducer,
  getHoursSavedRejectedReducer,
} from './reducers';

import { INITIAL_STATE } from './constants';

import { getHoursSaved, getOrders } from './actions';

export const ordersSlice = createSlice({
  name: 'ordersSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    setOrdersReducer,
    setInitialOrdersReducer,
    setOrdersCountReducer,
    setOrdersTakeReducer,
    setOrdersSkipReducer,
    setOrdersLoadingReducer,
    resetStateReducer: () => INITIAL_STATE(),
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, getOrdersPendingReducer);
    builder.addCase(getOrders.fulfilled, getOrdersFulfilledReducer);
    builder.addCase(getOrders.rejected, getOrdersRejectedReducer);
    builder.addCase(getHoursSaved.pending, getHoursSavedsPendingReducer);
    builder.addCase(getHoursSaved.fulfilled, getHoursSavedFulfilledReducer);
    builder.addCase(getHoursSaved.rejected, getHoursSavedRejectedReducer);
  },
});

const {
  setOrdersReducer: setOrders,
  setInitialOrdersReducer: setInitialOrders,
  setOrdersCountReducer: setOrdersCount,
  setOrdersTakeReducer: setOrdersTake,
  setOrdersSkipReducer: setOrdersSkip,
  setOrdersLoadingReducer: setOrdersLoading,
  resetStateReducer: resetState,
} = ordersSlice.actions;

export {
  setOrders,
  setInitialOrders,
  setOrdersCount,
  setOrdersTake,
  setOrdersSkip,
  setOrdersLoading,
  resetState,
};
