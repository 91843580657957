/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getIndexedEntryLookupByUniqueKey,
  getComplexSearchEntriesDTO,
  emptyEntriesWithExceptions,
} from './utils';
import {
  BaseFilterState,
  DateTimeBaseFilterState,
  FilterEntryUnion,
  FilterEntry,
  BaseFilterEntry,
  ComplexSearchEntry,
  DateTimeMode,
  PartialFilterEntry,
} from './types';

export const deselectFilterByIdAndKeyReducer = <
  T extends { entries: BaseFilterState<{}> | DateTimeBaseFilterState }
>(
  state: T,
  action: PayloadAction<{ id: string; key: string }>
) => {
  const { key, id } = action.payload;

  if (!state.entries[key as keyof typeof state.entries]) return;

  const changeIndex = state.entries[key].options.findIndex(
    (entry) => entry.id === id
  );

  if (changeIndex === -1) return;

  const entry = state.entries[key];
  entry.options[changeIndex].selected = false;
  entry.options[changeIndex].selectDate = Number.MAX_SAFE_INTEGER;
  if ('dateMode' in entry) {
    entry.range = [null, null];
    entry.dateMode = 'range';
    entry.beforeDate = null;
    entry.afterDate = null;
  }
};

export const resetFilterStateReducer = <
  T extends {
    entries: BaseFilterState<{}>;
    popover: { open: boolean };
  }
>(
  state: T,
  action: PayloadAction<{ closeFilterDrawer: boolean }>
) => {
  Object.keys(state.entries).forEach((entryKey) => {
    state.entries[entryKey as keyof typeof state.entries].options = [];
    state.entries[entryKey as keyof typeof state.entries].searchInput = null;
  });

  state.popover.open = !action.payload.closeFilterDrawer;
};

export const softResetFilterStateReducerHOF =
  (deselectOnlyKeys: string[] = []) =>
  <
    T extends {
      entries: BaseFilterState<{}> | DateTimeBaseFilterState;
      popover: { open: boolean };
    }
  >(
    state: T,
    action: PayloadAction<{
      closeFilterDrawer: boolean;
    }>
  ) => {
    const deselectKeyTable: Record<string, boolean> =
      deselectOnlyKeys.length === 0
        ? {}
        : Object.fromEntries(deselectOnlyKeys.map((key) => [key, true]));

    const deselectKeysValid =
      deselectOnlyKeys.length === 0
        ? true
        : deselectOnlyKeys.every((dKey) => dKey in state.entries);

    if (deselectKeysValid) {
      Object.keys(state.entries).forEach((entryKey) => {
        const entry = state.entries[entryKey as keyof typeof state.entries];
        entry.options =
          entryKey in deselectKeyTable
            ? state.entries[entryKey as keyof typeof state.entries].options
            : emptyEntriesWithExceptions(
                state.entries[entryKey as keyof typeof state.entries].options
              );

        entry.searchInput = null;
        if ('dateMode' in entry) {
          entry.range = [null, null];
          entry.dateMode = 'range';
          entry.beforeDate = null;
          entry.afterDate = null;
        }
      });

      if (deselectOnlyKeys.length) {
        deselectOnlyKeys.forEach((dKey) => {
          state.entries[dKey as keyof typeof state.entries].options =
            state.entries[dKey as keyof typeof state.entries].options.map(
              (option) => ({
                ...option,
                selected: false,
                selectDate: Number.MAX_SAFE_INTEGER,
              })
            );
        });
      }

      state.popover.open = !action.payload.closeFilterDrawer;
    } else {
      console.error(
        `1 or more Invalid key(s) provided: [${deselectOnlyKeys.join(
          ', '
        )}]. See #softResetFilterStateReducerHOF for details.`
      );
    }
  };

export const closeFilterDrawerReducer = <
  T extends { popover: { open: boolean } }
>(
  state: T
) => {
  state.popover.open = false;
};

export const openFilterDrawerReducer = <
  T extends { popover: { open: boolean } }
>(
  state: T
) => {
  state.popover.open = true;
};

export const toggleFilterDrawerReducer = <
  T extends { popover: { open: boolean } }
>(
  state: T
) => {
  state.popover.open = !state.popover.open;
};

export const setFilterBatch = <
  T extends {
    entries: BaseFilterState<{}>;
  },
>(
  state: T,
  action: PayloadAction<{ entry: string; values: string[] }>,
) => {
  state.entries[action.payload.entry].options.forEach((option) => {
    if (action.payload.values.includes(option.value.toLowerCase())) {
      option.selected = true;
    }
  });
};

export const upsertFilterEntryReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<{
    id: string;
    key: string;
    upsert: Partial<Omit<FilterEntry, 'key'>>;
  }>
) => {
  const { id, key } = action.payload;
  if (!(key in state.entries)) {
    console.error(
      `Key ${key} not found in filter sub-slice. See #upsertFilterEntryReducer for implementation details.`
    );
    return;
  }

  const updateEntryIndex = state.entries[key].options.findIndex(
    (entry: FilterEntry) => entry.id === id
  );

  if (updateEntryIndex === -1) {
    state.entries[key].options.push({
      displayName: '',
      value: '',
      available: true,
      selected: false,
      selectDate: new Date().getTime(),
      ...action.payload.upsert,
      id,
      key,
    });
  } else {
    state.entries[key].options[updateEntryIndex] = {
      ...state.entries[key].options[updateEntryIndex],
      ...action.payload.upsert,
    };
  }
};

export const batchUpsertFilterEntriesReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<{
    key: string;
    options: BaseFilterEntry[];
  }>
) => {
  const { key, options = [] } = action.payload;
  if (!options.length) {
    console.error('No options provided for batch upsert.');
    return;
  }

  if (!(key in state.entries)) {
    console.error(
      `Key ${key} not found in filter sub-slice. See #batchUpsertFilterEntriesReducer for implementation details.`
    );
    return;
  }

  const optionEntries = options.map(({ id, key, value, displayName }) => ({
    id,
    key,
    value,
    displayName,
    available: true,
    selected: false,
    selectDate: Number.MAX_SAFE_INTEGER,
  })) as FilterEntry[];

  if (!state.entries[key].options.length) {
    state.entries[key].options = optionEntries;
    return;
  }

  const filterEntryLookup = getIndexedEntryLookupByUniqueKey(
    state.entries[key].options,
    'displayName'
  );

  for (let i = 0; i < state.entries[key].options.length; i++) {
    state.entries[key].options[i].available = false;
  }

  optionEntries.forEach((option) => {
    const { displayName: currDisplayName } = option;

    if (currDisplayName in filterEntryLookup) {
      const currIndex = filterEntryLookup[currDisplayName].index;
      state.entries[key].options[currIndex].available = true;
    } else {
      state.entries[key].options.push(option);
    }
  });
};

export const openFilterInputReducer = <
  T extends {
    expandedFilter: string | null;
    entries: BaseFilterState<{}>;
  }
>(
  state: T,
  action: PayloadAction<string>
) => {
  const availableFiltersLookup = Object.fromEntries(
    Object.keys(state.entries).map((entryKey: string) => [entryKey, true])
  );

  if (!(action.payload in availableFiltersLookup)) {
    console.error(`Filter key (${action.payload}) not found.`);
    return;
  }

  state.expandedFilter = action.payload;
};

export const closeFilterInputReducer = <
  T extends {
    expandedFilter: string | null;
  }
>(
  state: T
) => {
  state.expandedFilter = null;
};

export const clearFilterEntriesByKeyReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<{
    key: string;
  }>
) => {
  state.entries[action.payload.key].options = emptyEntriesWithExceptions(
    state.entries[action.payload.key].options
  );
  state.entries[action.payload.key].searchInput = null;
};


export const clearAndUpsertFilterEntriesByKeyReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<{
    key: string;
    entry: PartialFilterEntry;
  }>
) => {
  if (action.payload.key in state.entries) {

    const emptiedEntries = emptyEntriesWithExceptions(
      state.entries[action.payload.key].options
    );
    const rightNow = new Date().getTime();
  
    const {
      available = true,
      selected = false,
      selectDate = rightNow,
      ...filterEntryRest
    } = action.payload.entry;
  
    const upsertEntry: FilterEntry = {
      ...filterEntryRest,
      available,
      selected,
      selectDate,
    };
    state.entries[action.payload.key].options = [...emptiedEntries, upsertEntry];
    state.entries[action.payload.key].searchInput = null;
  } else {
    console.error(`Filter key (${action.payload.key}) not found.`);
  }
};

export const setSearchInputReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<{ key: string; input: string | null }>
) => {
  const { key, input } = action.payload;
  if (input === null) return;

  state.entries[key].searchInput = input;
};

export const clearSearchInputReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<string>
) => {
  state.entries[action.payload].searchInput = '';
};

export const selectOptionReducer = <T extends { entries: BaseFilterState<{}> }>(
  state: T,
  action: PayloadAction<{
    id: string;
    key: string;
  }>
) => {
  const { id, key } = action.payload;
  if (!(key in state.entries)) {
    console.error(
      `Key ${key} not found in filter sub-slice. See #upsertFilterEntryReducer for implementation details.`
    );
    return;
  }

  const updateEntryIndex = state.entries[key].options.findIndex(
    (entry: FilterEntry) => entry.id === id
  );
  if (updateEntryIndex === -1) {
    console.error(`Entry with id ${id} not found in ${key} options.`);
  } else {
    state.entries[key].options[updateEntryIndex].selected = true;
    state.entries[key].options[updateEntryIndex].selectDate =
      new Date().getTime();
  }
};

export const clearAvailableOptionsByKeyReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<string>
) => {
  const key = action.payload;
  if (!(key in state.entries)) {
    console.error(`Key ${key} not found in filter sub-slice.`);
    return;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < state.entries[key].options.length; i++) {
    state.entries[key].options[i].selectDate = Number.MAX_SAFE_INTEGER;
    state.entries[key].options[i].selected = false;
  }
};

export const toggleFilterByKeyIdReducer = <
  T extends { entries: BaseFilterState<{}> }
>(
  state: T,
  action: PayloadAction<{ id: string; key: string }>
) => {
  const { key, id } = action.payload;

  if (!(key in state.entries)) {
    console.error('Passed key not found in state.entries');
    return;
  }

  const changeIndex = state.entries[key].options.findIndex(
    (entry) => entry.id === id
  );

  if (changeIndex === -1) {
    console.error('Passed id not found in state.entries');
    return;
  }

  const isSelected = state.entries[key].options[changeIndex].selected;

  state.entries[key].options[changeIndex].selected = !isSelected;
  state.entries[key].options[changeIndex].selectDate = isSelected
    ? Number.MAX_SAFE_INTEGER
    : new Date().getTime();
};

export const setComplexFilterEntriesReducerHOF =
  (lookup: Record<string, string>) =>
  <
    T extends {
      complexSearchEntriesDTO: ComplexSearchEntry[];
    }
  >(
    state: T,
    action: PayloadAction<FilterEntry[]>
  ) => {
    const complexSearchEntriesDTO = getComplexSearchEntriesDTO(
      action.payload,
      lookup
    );

    state.complexSearchEntriesDTO = complexSearchEntriesDTO;
  };

export const replaceDateFilterEntryReducer = <
  T extends { entries: FilterEntryUnion }
>(
  state: T,
  action: PayloadAction<{
    key: string;
    option: BaseFilterEntry;
    selected?: boolean;
  }>
) => {
  const { key, option, selected = false } = action.payload;
  if (!(key in state.entries)) {
    console.error(
      `Key ${key} not found in filter sub-slice. See #replaceDateFilterEntryReducer for implementation details.`
    );
    return;
  }

  const { id, value, displayName } = option;

  const selectDate = selected ? new Date().getTime() : Number.MAX_SAFE_INTEGER;

  const optionEntry = {
    id,
    key,
    value,
    displayName,
    available: true,
    selected,
    selectDate,
  } as FilterEntry;

  state.entries[key].options = [optionEntry];
};

export const setDateModeReducer = <T extends { entries: FilterEntryUnion }>(
  state: T,
  action: PayloadAction<{
    key: string;
    mode: DateTimeMode;
  }>
) => {
  const { key, mode } = action.payload;
  if (!(key in state.entries)) {
    console.error(
      `Key ${key} not found in filter sub-slice. See #setDateModeReducer for implementation details.`
    );
    return;
  }

  const entry = state.entries[key];
  const isRangeMode = mode === 'range';
  const notRangeMode = !isRangeMode;
  const isBeforeMode = mode === 'before';

  if ('range' in entry && isRangeMode) {
    const [startDate, endDate] = entry.range;
    const rangeValuesTruthy = entry.range.every(Boolean);
    const newRangeString = rangeValuesTruthy ? `${startDate}-${endDate}` : '';

    const rangeDiffersFromOption =
      newRangeString !== entry.options[0]?.value ?? '';

    const isRealRange = rangeValuesTruthy ? startDate !== endDate : false;

    const canRangeReplaceOption =
      rangeValuesTruthy && rangeDiffersFromOption && isRealRange;

    entry.options = canRangeReplaceOption
      ? [
          {
            id: `date::range::${newRangeString}`,
            displayName: newRangeString,
            value: newRangeString,
            key,
            available: true,
            selected: true,
            selectDate: new Date().getTime(),
          },
        ]
      : entry.options;

    entry.dateMode = mode;
  }

  if ('range' in entry && notRangeMode) {
    const isValidRange = entry.range.every(Boolean);
    const targetDateField = isBeforeMode ? 'beforeDate' : 'afterDate';
    const rangeDatePart = isBeforeMode ? entry.range[1] : entry.range[0];

    entry[targetDateField] = isValidRange
      ? rangeDatePart
      : entry[targetDateField];

    const dateType = isBeforeMode ? 'before' : 'after';
    const displayTitle = dateType[0].toUpperCase() + dateType.slice(1);

    entry.dateMode = mode;
    entry.options = isValidRange
      ? [
          {
            id: `date::${dateType}::${rangeDatePart}`,
            displayName: `${displayTitle}: ${rangeDatePart}`,
            value: `${rangeDatePart}`,
            key,
            available: true,
            selected: true,
            selectDate: new Date().getTime(),
          },
        ]
      : entry.options;
  }
};

export const setDateRangeReducer = <T extends { entries: FilterEntryUnion }>(
  state: T,
  action: PayloadAction<{
    key: string;
    startDate: null | string;
    endDate: null | string;
  }>
) => {
  const { key, startDate, endDate } = action.payload;
  if (!(key in state.entries)) {
    console.error(
      `Key ${key} not found in filter sub-slice. See #setDateRangeReducer for implementation details.`
    );
    return;
  }

  const entry = state.entries[key];

  if ('range' in entry) {
    entry.range = [startDate, endDate];

    const legitRange = [startDate, endDate].every(Boolean);

    entry.options = legitRange
      ? [
          {
            id: `date::range::${startDate}-${endDate}`,
            displayName: `${startDate}-${endDate}`,
            value: `${startDate} - ${endDate}`,
            key,
            available: true,
            selected: true,
            selectDate: new Date().getTime(),
          },
        ]
      : entry.options;
  } else {
    console.error(`Range property not found for key ${key}.`);
  }
};

export const setSingleDateReducer = <T extends { entries: FilterEntryUnion }>(
  state: T,
  action: PayloadAction<{
    key: string;
    entryDate: null | string;
    dateDirection: 'before' | 'after';
  }>
) => {
  const { key, entryDate, dateDirection } = action.payload;
  if (!(key in state.entries)) {
    console.error(
      `Key ${key} not found in filter sub-slice. See #setSingleDateReducer for implementation details.`
    );
    return;
  }

  const entry = state.entries[key];
  const titlePrefix = dateDirection[0].toUpperCase() + dateDirection.slice(1);

  if ('beforeDate' in entry && dateDirection === 'before') {
    entry.beforeDate = entryDate;
  } else if ('afterDate' in entry && dateDirection === 'after') {
    entry.afterDate = entryDate;
  } else {
    console.error(`Date property not found for key ${key}.`);
  }

  if ('beforeDate' in entry || 'afterDate' in entry) {
    entry.options = entryDate
      ? [
          {
            id: `date::${dateDirection}::${entryDate}`,
            displayName: `${titlePrefix}: ${entryDate}`,
            value: `${entryDate}`,
            key,
            available: true,
            selected: true,
            selectDate: new Date().getTime(),
          },
        ]
      : entry.options;
  }
};

export const clearDateByModeKeyReducer = <
  T extends { entries: FilterEntryUnion }
>(
  state: T,
  action: PayloadAction<{
    key: string;
    mode: DateTimeMode;
  }>
) => {
  const { key, mode } = action.payload;
  if (!(key in state.entries)) {
    console.error(
      `Key "${key}" not found in filter sub-slice. See #clearDateByModeKey for implementation details.`
    );
    return;
  }

  const entry = state.entries[key];

  if ('dateMode' in entry && mode === 'range') {
    entry.range = [null, null];
  }

  if ('dateMode' in entry && mode === 'before') {
    entry.beforeDate = null;
  }

  if ('dateMode' in entry && mode === 'after') {
    entry.afterDate = null;
  }

  entry.options = [];
};

export const resetFilterByEntryKeyReducerHOF =
  (initialEntries: FilterEntryUnion) =>
  <T extends { entries: FilterEntryUnion }>(
    state: T,
    action: PayloadAction<{
      key: string;
    }>
  ) => {
    const { key } = action.payload;

    if (!(key in state.entries)) {
      console.error(
        `Key "${key}" not found in filter sub-slice. See #resetFilterByEntryKey for implementation details.`
      );
      return;
    }

    if (!(key in initialEntries)) {
      console.error(
        `Key "${key}" not found in initialEntries. See #resetFilterByEntryKey for implementation details.`
      );
      return;
    }

    state.entries[key] = initialEntries[key];
  };

export const disableFilterReducer = <
  T extends {
    entries: BaseFilterState<{}>;
  }
>(
  state: T,
  action: PayloadAction<string>
) => {
  const availableFiltersLookup = Object.fromEntries(
    Object.keys(state.entries).map((entryKey: string) => [entryKey, true])
  );

  if (!(action.payload in availableFiltersLookup)) {
    console.error(`Filter key (${action.payload}) not found.`);
    return;
  }

  state.entries[action.payload].disabled = true;
};

export const enableFilterReducer = <
  T extends {
    entries: BaseFilterState<{}>;
  }
>(
  state: T,
  action: PayloadAction<string>
) => {
  const availableFiltersLookup = Object.fromEntries(
    Object.keys(state.entries).map((entryKey: string) => [entryKey, true])
  );

  if (!(action.payload in availableFiltersLookup)) {
    console.error(`Filter key (${action.payload}) not found.`);
    return;
  }

  state.entries[action.payload].disabled = false;
};
