import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';

import { TotalsCardProps } from '../totals/TotalsCard/types';
import {
  selectAllOrdersCount,
  selectAllOrdersCountLoading,
  selectCompleteOrdersCount,
  selectCompleteOrdersCountLoading,
  selectExpiringSoonOrdersCount,
  selectExpiringSoonOrdersCountLoading,
  selectInTransitOrdersCount,
  selectInTransitOrdersCountLoading,
  selectNeedsAttentionOrdersCount,
  selectNeedsAttentionOrdersCountLoading,
  selectOpenOrdersCount,
  selectOpenOrdersCountLoading,
} from 'store/slices/home/totals/selectors';
import {
  OPEN_ORDERS_STATUSES,
  IN_TRANSIT_ORDERS_STATUSES,
  COMPLETE_ORDERS_STATUSES,
  NEEDS_ATTENTION_ORDERS_STATUSES,
} from '../../constants';

export const useGetOrdersTotalsConfig = () => {
  const viewAllLink = '/orders?VIEW=ALL&reset-filters=1';

  const allOrdersCount = useSelector(selectAllOrdersCount);
  const openOrdersCount = useSelector(selectOpenOrdersCount);
  const inTransitOrdersCount = useSelector(selectInTransitOrdersCount);
  const completeOrdersCount = useSelector(selectCompleteOrdersCount);
  const needsAttentionOrdersCount = useSelector(
    selectNeedsAttentionOrdersCount
  );
  const expiringSoonOrdersCount = useSelector(selectExpiringSoonOrdersCount);

  const allOrdersCountLoading = useSelector(selectAllOrdersCountLoading);
  const openOrdersCountLoading = useSelector(selectOpenOrdersCountLoading);

  const inTransitOrdersCountLoading = useSelector(
    selectInTransitOrdersCountLoading
  );
  const completeOrdersCountLoading = useSelector(
    selectCompleteOrdersCountLoading
  );
  const needsAttentionOrdersCountLoading = useSelector(
    selectNeedsAttentionOrdersCountLoading
  );
  const expiringSoonOrdersCountLoading = useSelector(
    selectExpiringSoonOrdersCountLoading
  );

  const loading =
    allOrdersCountLoading === 'pending' ||
    openOrdersCountLoading === 'pending' ||
    inTransitOrdersCountLoading === 'pending' ||
    completeOrdersCountLoading === 'pending' ||
    needsAttentionOrdersCountLoading === 'pending' ||
    expiringSoonOrdersCountLoading === 'pending';

  const items = [
    {
      name: 'Open',
      total: openOrdersCount,
      link: `${viewAllLink}&order-status=${encodeURIComponent(
        OPEN_ORDERS_STATUSES.join(',')
      )}`,
    },
    {
      name: 'Currently in transit',
      total: inTransitOrdersCount,
      link: `${viewAllLink}&order-status=${encodeURIComponent(
        IN_TRANSIT_ORDERS_STATUSES.join(',')
      )}`,
    },
    {
      name: 'Completed',
      total: completeOrdersCount,
      link: `${viewAllLink}&order-status=${encodeURIComponent(
        COMPLETE_ORDERS_STATUSES.join(',')
      )}`,
    },
  ];

  const red = {
    total: needsAttentionOrdersCount,
    copy: 'Need attention',
    copyTooltip:
      'Orders that require follow-up or have encountered an exception',
    copyTooltipIcon: InfoOutlinedIcon,
    icon: CircleNotificationsOutlinedIcon,
    link: `${viewAllLink}&order-status=${encodeURIComponent(
      NEEDS_ATTENTION_ORDERS_STATUSES.join(',')
    )}`,
  };

  const yellow = {
    total: expiringSoonOrdersCount,
    copy: 'Expiring within 20 days',
    icon: TimelapseOutlinedIcon,
    link: '/orders?view=FOLLOW_UP',
  };

  const config: TotalsCardProps = {
    items,
    title: 'Your orders',
    viewAllLink,
    total: allOrdersCount,
    red: needsAttentionOrdersCount > 0 ? red : undefined,
    yellow: expiringSoonOrdersCount > 0 ? yellow : undefined,
    loading,
  };
  return config;
};
