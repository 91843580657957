import { PANEL_CONTAINER_HEIGHT, PANEL_CONTAINER_WIDTH } from '../../TabsContainer/styles';

export const PANEL_HEIGHT = PANEL_CONTAINER_HEIGHT - 105;
export const PANEL_WIDTH = PANEL_CONTAINER_WIDTH - 25;
export const PANEL_ITEM_HEIGHT = 66;
export const PanelContainerSx = {
  display: 'flex',
  height: `${PANEL_HEIGHT}px`,
  maxHeight: `${PANEL_HEIGHT}px`,
  minHeight: `${PANEL_ITEM_HEIGHT}px`,
  width: PANEL_WIDTH,
  overflowY: 'auto',
  overflowX: 'hidden',
};

export const PanelLoadingIndicatorSx = {
  pt: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
