import { gql } from '@apollo/client';

export const SEARCH_NOTIFICATIONS = gql`
  query searchNotifications(
    $recordId: ID!
    $offset: Int
    $limit: Int
    $orderAsc: String
    $orderDesc: String
    $recordIds: [ID!]
    $includeDeleted: Boolean = false
  ) {
    searchNotifications(
      notificationSearchInput: {
        recordId: $recordId
        recordIds: $recordIds
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        includeDeleted: $includeDeleted
      }
    ) {
      notifications {
        id
        recordId
        status
        notificationTypeId
        createdAt
        updatedAt
      }
      count
    }
  }
`;
