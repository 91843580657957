import React from 'react';
import { SVGProps } from '../../types';

const CompleteIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#7A8450" />
      <path
        d="M15.0602 9.05203L10.6668 13.4454L8.2735 11.0587L7.3335 11.9987L10.6668 15.332L16.0002 9.9987L15.0602 9.05203ZM12.0002 5.33203C8.32016 5.33203 5.3335 8.3187 5.3335 11.9987C5.3335 15.6787 8.32016 18.6654 12.0002 18.6654C15.6802 18.6654 18.6668 15.6787 18.6668 11.9987C18.6668 8.3187 15.6802 5.33203 12.0002 5.33203ZM12.0002 17.332C9.0535 17.332 6.66683 14.9454 6.66683 11.9987C6.66683 9.05203 9.0535 6.66536 12.0002 6.66536C14.9468 6.66536 17.3335 9.05203 17.3335 11.9987C17.3335 14.9454 14.9468 17.332 12.0002 17.332Z"
        fill="white"
      />
    </svg>
  );
};

export default CompleteIcon;
