import { SxProps } from '@mui/material';
import theme from 'themes/theme';
import { CLASS_NAMES_SUFFIX } from '../styles';

const AVATAR_TOP = '10px';

export const getDropdownFilterCountSx = (
  visible: boolean,
  counterAvatarRightOffset: number
): SxProps => ({
  display: visible ? 'flex' : 'none',
  fontSize: '12px',
  backgroundColor: theme.palette.colors.woodSmoke,
  position: 'absolute',
  height: '20px',
  top: AVATAR_TOP,
  right: '55px',
  width: '20px',
  zIndex: theme.zIndex.drawer,
  ...(counterAvatarRightOffset && { right: `${counterAvatarRightOffset}px` }),
});

export const getDropDownClearStackSx = (shouldRender: boolean): SxProps => ({
  display: shouldRender ? 'flex' : 'none',
  fontSize: '12px',
  fontWeight: 600,
  height: '34px',
  px: '16px',
  '& .clear-all-btn': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  '& .clear-box-copy': {
    fontWeight: 600,
  },
});

export const ClearStackButtonSx: SxProps = {
  backgroundColor: 'transparent',
  borderRadius: '5px',
  color: theme.palette.colors.woodSmoke,
  fontSize: '12px',
  fontWeight: 600,
  height: '40px',
  maxHeight: '100%',
  minWidth: '80px',
  px: '12px',
  '&:hover': {
    backgroundColor: theme.palette.colors.transparentGrey,
  },
};

export const DropDownMenuItemSx: SxProps = {
  maxHeight: '42px',
  pl: '8px',
  '& > .MuiCheckbox-root': {
    color: '#131314 !important',
  },
};

export const SelectFormControlSx: SxProps = {
  minHeight: '40px',
  height: '40px',
  width: '100%',

  '&.MuiInputLabel-shrink': {
    top: '-10px',
  },

  '& .MuiInputLabel-shrink.Mui-focused, & .MuiFormLabel-root': {
    background: 'white',
    px: '2px',
  },

  '&:hover .MuiFormLabel-root.MuiInputLabel-root': {
    color: theme.palette.colors.woodSmoke,
  },

  '& .MuiFormLabel-root.MuiInputLabel-root': {
    top: '-8px',
    '&.MuiInputLabel-shrink': {
      top: '1px',
    },
  },

  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .Mui-focused.MuiFormLabel-root, & .Mui-focused.MuiInputLabel-root':
    {
      color: theme.palette.colors.woodSmoke,
      borderColor: theme.palette.colors.woodSmoke,
    },

  '& .MuiInputBase-formControl.MuiOutlinedInput-root': {
    height: '100%',
  },
};

export const DropDownSelectSx: SxProps = {
  width: '100%',
  '& ul': {
    position: 'relative',
    height: '336px',
  },
  '& .MuiListSubheader-root': {
    fontWeight: 600,
  },

  '&.Mui-disabled': {
    color: theme.palette.colors.disableInputGrey,
    cursor: 'not-allowed',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const getDropDownOptionsMenuItemSx = (selected: boolean): SxProps => ({
  '&.MuiButtonBase-root.MuiMenuItem-root': {
    backgroundColor: selected ? '#f6f6f6' : 'transparent',
    '&:hover': {
      backgroundColor: selected ? '#e0e0e0' : '#f6f6f6',
    },
  },
  boxSizing: 'border-box',
  minHeight: '42px !important',
  maxHeight: '42px',
  pl: '8px',
  '& > .MuiCheckbox-root': {
    color: '#131314 !important',
  },
});

export const DropDownOptionsMenuItemSx: SxProps = {
  boxSizing: 'border-box',
  minHeight: '42px !important',
  maxHeight: '42px',
  pl: '8px',
  '& > .MuiCheckbox-root': {
    color: '#131314 !important',
  },
};

export const getClearStackSx = (
  selectorPrefix: string,
  overrideSx: SxProps = {}
): SxProps => ({
  backgroundColor: theme.palette.colors.white,

  borderLeft: 'none',
  borderRight: 'none',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  mt: '-8px',
  position: 'static',
  px: 0,

  boxSizing: 'border-box',
  display: 'flex',
  fontSize: '12px',
  fontWeight: 600,
  height: '40px',
  width: '100%',
  zIndex: 1550,
  '& .clear-all-btn': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  [`& .MuiBox-root.${selectorPrefix}${CLASS_NAMES_SUFFIX.appliedBox}`]: {
    color: theme.palette.colors.woodSmoke,
    fontWeight: 600,
    pl: 0,
  },
  [`& .MuiTypography-root.${selectorPrefix}${CLASS_NAMES_SUFFIX.clearAllType}`]:
    {
      fontSize: '12px',
      fontWeight: 600,
    },
  ...overrideSx,
});
