import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const OuterBoxSx: SxProps = {
  position: 'relative',
  width: '105px',
};

export const FilterPopoverSx: SxProps = {
  mt: '10px',
};

export const getFilterButtonSxHOF = (
  filterDrawerOpen: boolean,
  optionalSx: SxProps
): SxProps => ({
  backgroundColor: filterDrawerOpen ? '#1314141F' : 'transparent',
  borderRadius: '30px',
  fontWeight: 600,
  maxHeight: '40px',
  position: 'relative',
  px: '12px',
  py: '6px',
  width: '94px',
  '&: hover': {
    backgroundColor: '#1314141F',
    cursor: 'pointer',
  },
  ...optionalSx,
});

export const getFilterDrawerOuterSx: (overrideSx: SxProps) => SxProps = (
  overrideSx: SxProps = {}
) => ({
  ...overrideSx,
});

export const FilterDrawerBookendSx: SxProps = {
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  px: '32px',
  py: '8px',
  width: '500px',
};

export const FilterDrawerTopBoxSx: SxProps = {
  borderBottom: `1px solid #c9c9c9`,
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  height: '64px',
  justifyContent: 'space-between',
  ...FilterDrawerBookendSx,
};

export const FilterTitleSx: SxProps = {
  fontFamily: 'PPPangramSans',
  fontSize: '16px',
  fontWeight: 700,
};

export const getFilterDrawerContentSx: (
  hasFilters: boolean,
  overrideSx?: SxProps
) => SxProps = (hasFilters: boolean, overrideSx: SxProps = {}) => ({
  width: '500px',
  borderTopLeftRadius: hasFilters ? 0 : '10px',
  borderTopRightRadius: hasFilters ? 0 : '10px',
  px: '32px',
  pb: '16px',
  pt: 0,
  ...overrideSx,
});

export const FilterDrawerBottomBoxSx: SxProps = {
  borderTop: `1px solid #c9c9c9`,
  height: '52px',
  justifyContent: 'flex-start',
  ...FilterDrawerBookendSx,
  '& .clear-all-filters-button': {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    px: '12px',
    '&:hover': {
      backgroundColor: theme.palette.colors.transparentGrey,
    },
  },
};
