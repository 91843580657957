import { GAContext } from './types';
import { GA_BUTTON_EVENT_NAME, GTM_TAG_ID } from './constants';

// eslint-disable-next-line func-names
const gtag = function (...args: any[]) {
  // for some reason google doesn't respect args, so we have to use arguments instead
  // eslint-disable-next-line prefer-rest-params
  (window as any).dataLayer.push(arguments);
};

export const sendButtonClickEvent = (props: GAContext) => {
  console.warn('Google Analytics Button Click: Disabled', props);
  // TODO: add back if we need to
  // gtag('js', new Date());
  // gtag('config', GTM_TAG_ID);
  //
  // gtag('event', GA_BUTTON_EVENT_NAME, {
  //   send_to: GTM_TAG_ID,
  //   store_ui_button_text: props.textCopy,
  //   store_ui_button_purpose: props.purpose,
  //   store_ui_button_navigates_to: props.navigates_to,
  // });
};
