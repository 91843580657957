import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';

import timeSavedSrc from 'assets/hoursSaved.png';
import runningSrc from 'assets/allwhereBoxRunningAltColors.png';
import { TOOLTIP_TITLE } from './constants';

import * as styles from './styles';
import { useGetHoursSaved, useSetNotificationOnError } from './hooks';
import { useSelector } from 'react-redux';
import {
  selectHoursSaved,
  selectHoursSavedLoading,
} from 'store/slices/home/orders/selectors';

import CircularProgress from '@mui/material/CircularProgress';

const TimeSaved = () => {
  useGetHoursSaved();
  useSetNotificationOnError();
  const hoursSaved = useSelector(selectHoursSaved);
  const hoursSavedLoading = useSelector(selectHoursSavedLoading);
  return (
    <Stack sx={styles.TimeSavedContainerSx}>
      {hoursSavedLoading === 'pending' ? (
        <Stack sx={styles.LoadingOverlaySx}>
          <CircularProgress sx={styles.LoadingOverlaySpinnerSx} />
        </Stack>
      ) : null}
      <Stack sx={styles.TimeSavedHeaderSx}>Your time saved</Stack>
      <Stack sx={styles.TimeSavedContentSx}>
        <Stack sx={styles.TimeSavedContentLeftSx}>
          <Stack>
            <img src={timeSavedSrc} alt="Time saved" />
          </Stack>
          <Stack sx={styles.TimeSavedContentInfoSx}>
            <Stack sx={styles.TimeSavedTotalLabelSx}>
              Total
              <Tooltip title={TOOLTIP_TITLE}>
                <InfoOutlinedIcon sx={styles.TimeSavedTotalTooltipIconSx} />
              </Tooltip>
            </Stack>
            <Stack sx={styles.TimeSavedTotalSx}>{hoursSaved}h</Stack>
          </Stack>
        </Stack>
        <Stack sx={styles.TimeSavedContentRightSx}>
          <img src={runningSrc} alt="Running" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TimeSaved;
