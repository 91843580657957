import { PillProps } from "./types";

export const getPillSx = (props: PillProps) => {
  return {
    backgroundColor: props.color,
    color: props.textColor,
    fontWeight: 'bold',
    ...props.chipOverrideSx,
  };
};
