import React from 'react';
import Stack from '@mui/material/Stack';
import Panel from '../../Panel';

import { useGetAssetsPanelConfig } from './hooks';

const AssetsPanel = () => {
  const config = useGetAssetsPanelConfig();

  return (
    <Stack>
      <Panel {...config} />
    </Stack>
  );
};

export default AssetsPanel;
