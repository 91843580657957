import { gql } from '@apollo/client';

export const GET_ORDER_DETAILS = gql`
  query order($id: String!) {
    order(id: $id) {
      id
      orderType {
        id
        name
      }
    }
  }
`;

export const GET_ORDER_ITEMS_BY_ORDER_ID = gql`
  query searchOrderItems($orderId: ID!, $offset: Int, $limit: Int) {
    getOrderItemsByOrderIdForDetails: searchOrderItems(
      searchOrderItemsInput: {
        orderId: $orderId
        orderDesc: "createdAt"
        offset: $offset
        limit: $limit
      }
    ) {
      count
      orderItems {
        id
        createdAt
        asset {
          id
          make
          model
          assetNumber
          assetImages {
            image {
              url
              name
            }
          }
        }
        orderItemNumber
        inboundOrderItemId
        shipment {
          status
          substatus
          substatusDescription
        }
        productVariant {
          sku
          productId
          productImages {
            image {
              id
              url
            }
          }
          product {
            name
          }
        }
        tracking {
          id
          shipmentId
          trackingLink
          trackingNumber
        }
      }
    }
  }
`;
