export const OrdersFAQCardsContainerSx = {
  display: 'flex',
  justifyContent: 'center',
  pb: '15px',
};

export const OrdersCardsTitleSx = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  borderRadius: '5px',
  pt: '45px',
  fontSize: '14px',
  '& a': {
    textDecoration: 'underline',
    color: 'black',
    fontWeight: '700',
  },
};

export const OrdersCardsListContainerSx = {
  display: 'flex',
  pt: '15px',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  '& > div:nth-child(odd)': {
    mr: '4px',
  },
  '& > div:nth-child(even)': {
    ml: '4px',
  },
};
