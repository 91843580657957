import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { OrderOptionProps } from './types';
import * as OrderOptionsStyles from './styles';
import TextIconButton from 'components/TextIconButton';
import { AddOrderItemByEnum } from 'pages/OrderManagement/components/DetailedOrderView/enum';
import {
  DISABLED_ASSET_TOOLTIP_TEXT,
  DISABLED_PRODUCT_TOOLTIP_TEXT,
} from './constants';
import { getShippingName } from './utils';

const OrderOptions = (props: OrderOptionProps) => {
  const styles = OrderOptionsStyles;
  const theme = useTheme();

  const setClickOrigin = (clickOrigin: string) => () => {
    if (clickOrigin === AddOrderItemByEnum.PRODUCT) {
      props.setAddOrderItemToProduct();
    }
    if (clickOrigin === AddOrderItemByEnum.ASSET) {
      props.setAddOrderItemToAsset();
    }
  };

  const shippingTypeName = getShippingName(props.order, props?.shippingTypeLookupById ?? {});

  return (
    <Grid
      container
      sx={styles.OrderOptionsGridSx}
      justifyContent="space-between"
    >
      <Grid item justifyItems="flex-start">
        <Stack direction="row">
          {props.orderStatusComponent ? props.orderStatusComponent : null}
            <Stack direction="row" sx={{ ml: '16px' }}>
              <FormControlLabel
                label="SHIPPING"
                labelPlacement="start"
                disabled
                sx={{
                  '& .MuiFormControlLabel-label.Mui-disabled': {
                    color: theme.palette.colors.gray60,
                    opacity: 1,
                    fontSize: '12px',
                  },
                }}
                control={
                  <Select
                    sx={styles.DetailOrderShippingSelectSx}
                    defaultValue={shippingTypeName}
                    disabled
                    onChange={(e: any) => {}}
                  >
                    {[shippingTypeName].map((typeName: string) => (
                      <MenuItem value={typeName}>{typeName}</MenuItem>
                    ))}
                  </Select>
                }
              />
            </Stack>
        </Stack>
      </Grid>
      <div>
        <Tooltip
          title={DISABLED_ASSET_TOOLTIP_TEXT}
          disableHoverListener={!props.disableAsset}
        >
          <span>
            <TextIconButton
              text="Asset"
              ariaLabel="Add Asset"
              onClick={setClickOrigin(AddOrderItemByEnum.ASSET)}
              icon={<AddIcon />}
              variant="outlined"
              disabled={props.disableAsset}
              buttonStyles={styles.OrderOptionButtonSx}
            />
          </span>
        </Tooltip>
        <Tooltip
          title={DISABLED_PRODUCT_TOOLTIP_TEXT}
          disableHoverListener={!props.disableProduct}
        >
          <span>
            <TextIconButton
              text="Product"
              ariaLabel="Add Product"
              onClick={setClickOrigin(AddOrderItemByEnum.PRODUCT)}
              icon={<AddIcon />}
              variant="outlined"
              disabled={props.disableProduct}
              buttonStyles={styles.OrderOptionButtonSx}
            />
          </span>
        </Tooltip>
      </div>
    </Grid>
  );
};

export default OrderOptions;
