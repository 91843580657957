import { TotalsCardProps } from '../totals/TotalsCard/types';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  selectAllAssetsCount,
  selectAllAssetsCountLoading,
  selectInInventoryAssetsCount,
  selectInInventoryAssetsCountLoading,
  selectReadyToDeployAssetsCount,
  selectReadyToDeployAssetsCountLoading,
  selectNeedsAttentionAssetsCount,
  selectNeedsAttentionAssetsCountLoading,
  selectAllocatedAssetsCount,
  selectAllocatedAssetsCountLoading,
} from 'store/slices/home/totals/selectors';
import {
  COMPLEX_SEARCH_IN_INVENTORY_ASSETS_STATUSES,
  COMPLEX_SEARCH_READY_TO_DEPLOY_ASSETS_STATUSES,
  COMPLEX_SEARCH_ALLOCATED_ASSETS_STATUSES,
  COMPLEX_SEARCH_NEEDS_ATTENTION_ASSETS_STATUSES,
} from '../../constants';
import { useSelector } from 'react-redux';

export const useGetAssetsTotalsConfig = () => {
  const viewAllLink = '/assets?view=DEVICES&reset-filters=1';
  const allAssetsCount = useSelector(selectAllAssetsCount);
  const allAssetsCountLoading = useSelector(selectAllAssetsCountLoading);
  const allocatedAssetsCount = useSelector(selectAllocatedAssetsCount);
  const allocatedAssetsCountLoading = useSelector(
    selectAllocatedAssetsCountLoading
  );
  const inInventoryAssetsCount = useSelector(selectInInventoryAssetsCount);
  const inInventoryAssetsCountLoading = useSelector(
    selectInInventoryAssetsCountLoading
  );
  const readyToDeployAssetsCount = useSelector(selectReadyToDeployAssetsCount);
  const readyToDeployAssetsCountLoading = useSelector(
    selectReadyToDeployAssetsCountLoading
  );
  const needsAttentionAssetsCount = useSelector(
    selectNeedsAttentionAssetsCount
  );
  const needsAttentionAssetsCountLoading = useSelector(
    selectNeedsAttentionAssetsCountLoading
  );

  const loading =
    allAssetsCountLoading === 'pending' ||
    allocatedAssetsCountLoading === 'pending' ||
    inInventoryAssetsCountLoading === 'pending' ||
    readyToDeployAssetsCountLoading === 'pending' ||
    needsAttentionAssetsCountLoading === 'pending';
  const items = [
    {
      name: 'Allocated to employees',
      total: allocatedAssetsCount,
      link: `${viewAllLink}&status=${encodeURIComponent(
        COMPLEX_SEARCH_ALLOCATED_ASSETS_STATUSES.join(',')
      )}`,
    },
    {
      name: 'In allwhere warehouse',
      total: inInventoryAssetsCount,
      link: `${viewAllLink}&status=${encodeURIComponent(
        COMPLEX_SEARCH_IN_INVENTORY_ASSETS_STATUSES.join(',')
      )}`,
    },
    {
      name: 'Ready to deploy',
      total: readyToDeployAssetsCount,
      link: `${viewAllLink}&status=${encodeURIComponent(
        COMPLEX_SEARCH_READY_TO_DEPLOY_ASSETS_STATUSES.join(',')
      )}`,
    },
  ];
  const red = {
    total: needsAttentionAssetsCount,
    copy: 'Need attention',
    copyTooltip: 'Assets that may require action or extra attention',
    copyTooltipIcon: InfoOutlinedIcon,
    icon: CircleNotificationsOutlinedIcon,
    link: `${viewAllLink}&status=${encodeURIComponent(
      COMPLEX_SEARCH_NEEDS_ATTENTION_ASSETS_STATUSES.join(',')
    )}`,
  };
  const config: TotalsCardProps = {
    items,
    title: 'Your assets',
    viewAllLink,
    total: allAssetsCount,
    red: needsAttentionAssetsCount > 0 ? red : undefined,
    loading,
  };
  return config;
};
