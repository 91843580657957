import { createSlice } from '@reduxjs/toolkit';

import {
  setInitialAssetsReducer,
  setAssetsReducer,
  setAssetsCountReducer,
  setAssetsTakeReducer,
  setAssetsSkipReducer,
  setAssetsLoadingReducer,
  getAssetsPendingReducer,
  getAssetsFulfilledReducer,
  getAssetsRejectedReducer,
} from './reducers';

import { INITIAL_STATE } from './constants';

import { getAssets } from './actions';

export const assetsSlice = createSlice({
  name: 'assetsSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    setAssetsReducer,
    setInitialAssetsReducer,
    setAssetsCountReducer,
    setAssetsTakeReducer,
    setAssetsSkipReducer,
    setAssetsLoadingReducer,
    resetStateReducer: () => INITIAL_STATE(),
  },
  extraReducers: (builder) => {
    builder.addCase(getAssets.pending, getAssetsPendingReducer);
    builder.addCase(getAssets.fulfilled, getAssetsFulfilledReducer);
    builder.addCase(getAssets.rejected, getAssetsRejectedReducer);
  },
});

const {
  setAssetsReducer: setAssets,
  setInitialAssetsReducer: setInitialAssets,
  setAssetsCountReducer: setAssetsCount,
  setAssetsTakeReducer: setAssetsTake,
  setAssetsSkipReducer: setAssetsSkip,
  setAssetsLoadingReducer: setAssetsLoading,
  resetStateReducer: resetState,
} = assetsSlice.actions;

export {
  setAssets,
  setInitialAssets,
  setAssetsCount,
  setAssetsTake,
  setAssetsSkip,
  setAssetsLoading,
  resetState,
};
