import React from 'react';
import { useGetOrdersTotalsConfig } from './hooks';
import TotalsCard from '../totals/TotalsCard';

const OrdersTotals = () => {
  const config = useGetOrdersTotalsConfig();
  return (
    <div>
      <TotalsCard {...config} />
    </div>
  );
};

export default OrdersTotals;
