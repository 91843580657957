import React from 'react';
import Stack from '@mui/material/Stack';

import useGetUserOrganization from 'hooks/useGetUserOrganization';
import TimeSaved from './widgets/TimeSaved';
import AssetsTotals from './widgets/AssetsTotals';
import OrdersTotals from './widgets/OrdersTotals';
import TabsContainer from './list/TabsContainer';

import {
  useShowErrorNotificationOnOrdersOrAssetsAPIError,
  useGetOrdersTotals,
} from './hooks';

import { CONTAINER_TEST_DATA_ID } from './constants';

import * as styles from './styles';

const Home = () => {
  useGetOrdersTotals();
  const userOrganization = useGetUserOrganization();
  useShowErrorNotificationOnOrdersOrAssetsAPIError();

  return (
    <Stack sx={styles.MainContainerSx} data-testid={CONTAINER_TEST_DATA_ID}>
      <Stack sx={styles.HomePageHeaderSx}>
        <Stack sx={styles.HomePageHeaderLeftSx}>
          <Stack sx={styles.HomePageHeaderTextSx}>
            Welcome back, {userOrganization?.name ?? ''}
          </Stack>
        </Stack>
        <Stack sx={styles.HomePageHeaderRightSx}></Stack>
      </Stack>
      <Stack sx={styles.HomePageContentSx}>
        <Stack sx={styles.HomePageContentLeftSx}>
          <TabsContainer />
        </Stack>
        <Stack sx={styles.HomePageContentRightSx} spacing={2}>
          <TimeSaved />
          <AssetsTotals />
          <OrdersTotals />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home;
