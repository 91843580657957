import React from 'react';
import { SVGProps } from '../../types';

const ShippedIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4DB7C6" />
      <path
        d="M17.3332 9.33464H15.3332V6.66797H5.99984C5.2665 6.66797 4.6665 7.26797 4.6665 8.0013V15.3346H5.99984C5.99984 16.4413 6.89317 17.3346 7.99984 17.3346C9.1065 17.3346 9.99984 16.4413 9.99984 15.3346H13.9998C13.9998 16.4413 14.8932 17.3346 15.9998 17.3346C17.1065 17.3346 17.9998 16.4413 17.9998 15.3346H19.3332V12.0013L17.3332 9.33464ZM16.9998 10.3346L18.3065 12.0013H15.3332V10.3346H16.9998ZM7.99984 16.0013C7.63317 16.0013 7.33317 15.7013 7.33317 15.3346C7.33317 14.968 7.63317 14.668 7.99984 14.668C8.3665 14.668 8.6665 14.968 8.6665 15.3346C8.6665 15.7013 8.3665 16.0013 7.99984 16.0013ZM15.9998 16.0013C15.6332 16.0013 15.3332 15.7013 15.3332 15.3346C15.3332 14.968 15.6332 14.668 15.9998 14.668C16.3665 14.668 16.6665 14.968 16.6665 15.3346C16.6665 15.7013 16.3665 16.0013 15.9998 16.0013Z"
        fill="white"
      />
    </svg>
  );
};

export default ShippedIcon;
