import { Dispatch, AnyAction } from 'redux';
import { assetFilterActions } from 'store/slices/assets/filter';
import {
  DropDownOption,
  UpsertOptionsHOF,
} from 'components/complex_filter/types';
import { AssetType } from 'types';
import { BaseFilterEntry, FilterStateKey } from 'store/shared/types';

export const getAssetQueryVariablesHOF =
  (
    searchField: string,
    options: { [x: string]: string } = { searchBy: 'iLike' }
  ) =>
  (rawInput: string, moreVariables: { [x: string]: string } = {}) => ({
    variables: {
      searchField,
      searchBy: options.searchBy,
      searchTerm: `%${rawInput}%`,
      ...options,
      ...moreVariables,
    },
  });

export const getAssetQueryVariables = (
  searchField: string,
  rawInput: string,
  searchBy?: string
) => ({
  variables: {
    searchField,
    searchBy: searchBy ?? 'iLike',
    searchTerm: `%${rawInput}%`,
  },
});

export const getAssetAssigneeQueryVariables = (
  rawInput: string,
  moreVariables: { [x: string]: string } = {}
) => ({
  variables: {
    searchTerm: `%${rawInput}%`,
    ...moreVariables,
  },
});

export const getAssetAssigneeQueryVariablesHOF =
  (moreVariables: { [x: string]: string } = {}) =>
  (rawInput: string) => ({
    variables: {
      searchTerm: `%${rawInput}%`,
      ...moreVariables,
    },
  });

export const getAssetTypeaheadOnCompletedHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void
  ) =>
  (data: any): void => {
    const matchIds = data?.getAssetAutocompleteSuggestions?.matchIds ?? [];
    const matches = data?.getAssetAutocompleteSuggestions?.matches ?? [];

    const noOptions = matches.length === 0 || matchIds.length === 0;

    if (noOptions) {
      upsertEntriesCallback(key, []);
      return;
    }

    const upsertOptions: BaseFilterEntry[] = matches.map(
      (match: string, index: number) => ({
        id: `devices-${key}-${match}-${matchIds[index]}`,
        key,
        displayName: match,
        value: match,
      })
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const getAssigneeTypeaheadOnCompletedHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void
  ) =>
  (data: any): void => {
    const matchIds =
      data?.getCollaboratorAutocompleteSuggestions?.matchIds ?? [];
    const matches = data?.getCollaboratorAutocompleteSuggestions?.matches ?? [];

    const noOptions = matches.length === 0 || matchIds.length === 0;

    if (noOptions) {
      upsertEntriesCallback(key, []);
      return;
    }

    const upsertOptions: BaseFilterEntry[] = matches.map(
      (match: string, index: number) => ({
        id: `devices-${key}-${match}-${matchIds[index]}`,
        key,
        displayName: match,
        value: matchIds[index],
      })
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const fetchAssetAssigneeBaseEntriesHOF =
  (key: string, filterSubTree: FilterStateKey) =>
  (data: any): BaseFilterEntry[] => {
    const matchIds =
      data?.getCollaboratorAutocompleteSuggestions?.matchIds ?? [];
    const matches = data?.getCollaboratorAutocompleteSuggestions?.matches ?? [];

    const noOptions = matches.length === 0 || matchIds.length === 0;

    if (noOptions) {
      return [];
    }

    const upsertOptions: BaseFilterEntry[] = matches.map(
      (match: string, index: number) => ({
        id: `${filterSubTree}-${key}-${match}-${matchIds[index]}`,
        key,
        displayName: match,
        value: matchIds[index],
      })
    );

    return upsertOptions;
  };

export const stringsToDropDownOptions = (strings: string[]): DropDownOption[] =>
  strings.map((string) => ({ displayName: string, value: string }));

export const getDepotsOnCompletedHOF: UpsertOptionsHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
    _options: DropDownOption[]
  ) =>
  (data: any): void => {
    const partialDepotsResponse: { name: string; id: string }[] =
      data?.searchDepots?.depots ?? [];
    if (!partialDepotsResponse.length) {
      console.error('No depots found');
      return;
    }

    const upsertOptions: BaseFilterEntry[] = partialDepotsResponse.map(
      (depot) => ({
        id: `devices-${key}-${depot.id}`,
        key,
        displayName: depot.name,
        value: depot.id,
      })
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const getAssetTypesOnCompletedHOF: UpsertOptionsHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
    _options: DropDownOption[]
  ) =>
  (data: any): void => {
    const assetTypesResponse: AssetType[] = data?.getAllAssetTypes ?? [];
    if (!assetTypesResponse) {
      console.error('No asset types found');
      return;
    }

    const upsertOptions: BaseFilterEntry[] = assetTypesResponse.map(
      (assetType) => ({
        id: `devices-${key}-${assetType.id}`,
        key,
        displayName: assetType.name,
        value: assetType.id,
      })
    );

    upsertEntriesCallback(key, upsertOptions);
  };
