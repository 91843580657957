import React from 'react';
import { SVGProps } from '../../types';

const PendingRecipientInformationIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F9C54D" />
      <path
        d="M11.9998 7.93464C12.7732 7.93464 13.3998 8.5613 13.3998 9.33464C13.3998 10.108 12.7732 10.7346 11.9998 10.7346C11.2265 10.7346 10.5998 10.108 10.5998 9.33464C10.5998 8.5613 11.2265 7.93464 11.9998 7.93464ZM11.9998 13.9346C13.9798 13.9346 16.0665 14.908 16.0665 15.3346V16.068H7.93317V15.3346C7.93317 14.908 10.0198 13.9346 11.9998 13.9346ZM11.9998 6.66797C10.5265 6.66797 9.33317 7.8613 9.33317 9.33464C9.33317 10.808 10.5265 12.0013 11.9998 12.0013C13.4732 12.0013 14.6665 10.808 14.6665 9.33464C14.6665 7.8613 13.4732 6.66797 11.9998 6.66797ZM11.9998 12.668C10.2198 12.668 6.6665 13.5613 6.6665 15.3346V17.3346H17.3332V15.3346C17.3332 13.5613 13.7798 12.668 11.9998 12.668Z"
        fill="#131414"
      />
    </svg>
  );
};

export default PendingRecipientInformationIcon;
