import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';

import { V2CalculateHoursOfSavedWork } from 'api/apollo/queries/v2/orders';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { notificationSlice } from 'store/slices';
import { selectHoursSavedLoading } from 'store/slices/home/orders/selectors';
import { getHoursSaved } from 'store/slices/home/orders/actions';

export const useGetHoursSaved = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();
  React.useEffect(() => {
    if (organization?.id) {
      dispatch(
        getHoursSaved({
          query: V2CalculateHoursOfSavedWork(),
          variables: {
            where: {
              organizationId: { equals: organization.id },
              deletedAt: { equals: null },
            },
          },
          append: false,
        })
      );
    }
  }, []);
};

export const useSetNotificationOnError = () => {
  const dispatch = useDispatch();
  const hoursSavedLoading = useSelector(selectHoursSavedLoading);

  React.useEffect(() => {
    if (hoursSavedLoading === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Could not retrieve hours saved.  Please try again',
        })
      );
    }
  }, [hoursSavedLoading]);
};
