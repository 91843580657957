import { createSlice } from '@reduxjs/toolkit';
import { FilterEntry, FilterEntryUnion } from 'store/shared/types';
import {
  deselectFilterByIdAndKeyReducer,
  resetFilterStateReducer,
  closeFilterDrawerReducer,
  openFilterDrawerReducer,
  toggleFilterDrawerReducer,
  upsertFilterEntryReducer,
  batchUpsertFilterEntriesReducer,
  openFilterInputReducer,
  closeFilterInputReducer,
  setSearchInputReducer,
  clearSearchInputReducer,
  selectOptionReducer,
  clearAvailableOptionsByKeyReducer,
  toggleFilterByKeyIdReducer,
  clearFilterEntriesByKeyReducer,
  setComplexFilterEntriesReducerHOF,
  replaceDateFilterEntryReducer,
  setDateModeReducer,
  setDateRangeReducer,
  setSingleDateReducer,
  clearDateByModeKeyReducer,
  resetFilterByEntryKeyReducerHOF,
  softResetFilterStateReducerHOF,
  disableFilterReducer,
  enableFilterReducer,
  clearAndUpsertFilterEntriesByKeyReducer,
} from 'store/shared/reducers';
import OMFilterState from './types';

export const OM_FILTERS_MAPPING_TABLE = {
  'order-number': 'orderNumber',
  'order-type': 'orderType',
  'order-date': 'orderDate',
  'due-date': 'dueDate',
  'order-status': 'status',
  depot: 'depot',
  'shipping-selection': 'shippingType',
  'tracking-number': 'trackingNumber',
  organization: 'organization',
  'order-manager': 'orderManager',
  'customer-notes': 'customerNotes',
  purchaser: 'purchaser',
  'purchaser-email': 'purchaserEmail',
  recipient: 'recipient',
  'recipient-email': 'recipientEmail',
};

const DESELECT_ONLY_RESET_KEYS = [
  'order-type',
  'order-status',
  'shipping-selection',
  'depot',
];

export const INITIAL_ORDER_MGMT_FILTER_ENTRIES: FilterEntryUnion = {
  'order-number': { options: [] as FilterEntry[], searchInput: '' },
  'order-type': { options: [] as FilterEntry[], searchInput: '' },
  'order-date': {
    options: [] as FilterEntry[],
    searchInput: null,
    dateMode: 'range',
    range: [null, null],
    beforeDate: null,
    afterDate: null,
  },
  'due-date': {
    options: [] as FilterEntry[],
    searchInput: null,
    dateMode: 'range',
    range: [null, null],
    beforeDate: null,
    afterDate: null,
  },
  'order-status': { options: [] as FilterEntry[], searchInput: '' },
  depot: { options: [] as FilterEntry[], searchInput: '' },
  'shipping-selection': { options: [] as FilterEntry[], searchInput: '' },
  'tracking-number': { options: [] as FilterEntry[], searchInput: '' },
  organization: { options: [] as FilterEntry[], searchInput: '' },
  'order-manager': {
    options: [
      {
        id: 'UNASSIGNED::ORDER_MANAGER',
        key: 'order-manager',
        displayName: 'Unassigned',
        value: 'UNASSIGNED::ORDER_MANAGER',
        available: true,
        selected: false,
        selectDate: new Date().getTime(),
        softReset: true,
      },
    ] as FilterEntry[],
    searchInput: '',
  },
  'customer-notes': {
    options: [{
      id: 'HAS::CUSTOMER_NOTES',
      key: 'customer-notes',
      displayName: 'Customer notes',
      value: 'HAS::CUSTOMER_NOTES',
      available: true,
      selected: false,
      selectDate: Number.MAX_SAFE_INTEGER,
      softReset: true,
    }] as FilterEntry[],
    searchInput: '',
    checked: false,
  },
  purchaser: { options: [] as FilterEntry[], searchInput: '' },
  'purchaser-email': { options: [] as FilterEntry[], searchInput: '' },
  recipient: { options: [] as FilterEntry[], searchInput: '' },
  'recipient-email': { options: [] as FilterEntry[], searchInput: '' },
};

export const orderManagementInitialState: OMFilterState = {
  entries: INITIAL_ORDER_MGMT_FILTER_ENTRIES,
  popover: { open: false },
  expandedFilter: null,
  complexSearchEntriesDTO: [],
};

export const orderManagementFilterSlice = createSlice({
  name: 'orderManagementFilterSlice',
  initialState: () => orderManagementInitialState,
  reducers: {
    deselectFilterByIdAndKeyReducer,
    resetFilterStateReducer,
    closeFilterDrawerReducer,
    openFilterDrawerReducer,
    toggleFilterDrawerReducer,
    upsertFilterEntryReducer,
    batchUpsertFilterEntriesReducer,
    openFilterInputReducer,
    closeFilterInputReducer,
    setSearchInputReducer,
    clearSearchInputReducer,
    selectOptionReducer,
    clearAvailableOptionsByKeyReducer,
    toggleFilterByKeyIdReducer,
    clearFilterEntriesByKeyReducer,
    setComplexFilterEntriesReducer: setComplexFilterEntriesReducerHOF(
      OM_FILTERS_MAPPING_TABLE
    ),
    resetFilterByEntryKeyReducer: resetFilterByEntryKeyReducerHOF(
      INITIAL_ORDER_MGMT_FILTER_ENTRIES
    ),
    softResetFilterStateReducer: softResetFilterStateReducerHOF(
      DESELECT_ONLY_RESET_KEYS
    ),
    // optional reducers for date filters
    replaceDateFilterEntryReducer,
    setDateModeReducer,
    setDateRangeReducer,
    setSingleDateReducer,
    clearDateByModeKeyReducer,
    // optional reducers for complex filters
    disableFilterReducer,
    enableFilterReducer,
    clearAndUpsertFilterEntriesByKeyReducer,
  },
  extraReducers: (builder) => {},
});

export const orderManagementFilterReducer = orderManagementFilterSlice.reducer;
export const orderManagementFilterActions = orderManagementFilterSlice.actions;
