import { gql } from '@apollo/client';

export const SEARCH_ORDERS = gql`
  query searchOrders(
    $offset: Int!
    $limit: Int!
    $view: OrderView!
    $orderManagerId: String
    $orderNumber: String
    $recipientCollaboratorId: String
    $orderAsc: String
    $orderDesc: String
    $orderTypeName: OrderTypeName
    $status: [OrderStatusEnum!]
    $depotId: String
    $organizationId: String
    # $isPremiumShipping: Boolean!
  ) {
    searchOrders(
      searchOrdersInput: {
        offset: $offset
        limit: $limit
        view: $view
        status: $status
        orderManagerId: $orderManagerId
        orderNumber: $orderNumber
        recipientCollaboratorId: $recipientCollaboratorId
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        orderTypeName: $orderTypeName
        depotId: $depotId
        organizationId: $organizationId
      }
    ) {
      orders {
        id
        rush
        orderNumber
        createdAt
        depotId
        status
        internalNotes
        checkoutNotes
        sendOrderNotifications
        dueDate
        shippingTypeId
        orderType {
          id
          name
        }
        orderManager {
          id
          email
          firstName
          lastName
        }
        recipientId
        recipient {
          id
          firstName
          lastName
          email
          collaboratorId
        }
        dropRecipient {
          id
          firstName
          lastName
          email
          collaboratorId
        }
        dropRecipientId
        organizationObject {
          id
          name
        }
        purchaser {
          id
          firstName
          lastName
          email
        }
        depot {
          id
          name
        }
      }
      count
    }
  }
`;

export const SEARCH_SHIPPING_TYPES = gql`
  query searchShippingTypes(
    $orderAsc: String
    $orderDesc: String
    $limit: Int
    $name: String
    $description: String
    $offset: Int
  ) {
    searchShippingTypes(
      searchShippingTypesInput: {
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        limit: $limit
        name: $name
        description: $description
        offset: $offset
      }
    ) {
      count
      shippingTypes {
        id
        name
      }
    }
  }
`;

export const GET_ALL_ORDER_TYPES = gql`
  query {
    getAllOrderTypes {
      id
      name
      hasAssets
      hasProducts
      toDepot
      toRecipient
      toDropRecipient
    }
  }
`;
