import { PanelItemData } from '../../PanelItem/types';
import { Asset } from 'store/slices/home/assets/types';

export const transformAsset = (asset: Asset) => {
  let createdAt = '';
  let recipientName = '';

  if (asset?.assignee?.firstName && asset?.assignee?.lastName) {
    recipientName = `${asset.assignee.firstName} ${asset.assignee.lastName}'s`;
  }

  if (asset?.assetStatusTransition?.createdAt) {
    createdAt = asset.assetStatusTransition.createdAt;
  }

  const assetItem: PanelItemData = {
    recipientName,
    assetStatus: asset?.status,
    createdAt,
    assetType: asset?.assetType?.name,
    assetId: asset?.id,
    orgId: asset?.organizationId,
  };
  return assetItem;
};
