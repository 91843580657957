import React from 'react';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { Button } from 'components';
import { notificationSlice } from 'store/slices';
import { OrderNotificationProps } from './types';
import {
  NotificationTypeStackSx,
  OrderNotificationsContainerSx,
  SendButtonSx,
  SendButtonTextSx,
} from './styles';
import NotificationsTable from './components/NotificationsTable';
import { useSafeMutation } from 'hooks/useSafeMutation';
import {
  SEND_NOTIFICATION, UPDATE_ORDER_NOTIFICATIONS_FLAG
} from './mutations';
import { AllowNotificationMessage } from './constants';
import { Order, NotificationType } from 'types';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { useSafeQuery } from 'hooks/useSafeQuery';
import {
  SEARCH_NOTIFICATION_TYPES,
} from './queries';
import { fromCamelToSentenceCase } from 'services';

const OrderNotifications = (props: OrderNotificationProps) => {
  if (!props.order) return null;

  const [selectedOrderNotificationTypeId, setSelectedOrderNotificationTypeId] =
    React.useState<string>('');

  const dispatch = useDispatch();

  const [sendOrderNotifications, setSendOrderNotifications] =
    React.useState<boolean>(props.order?.sendOrderNotifications);

  const [updateOrder, { loading: updateOrderIsLoading }] = useSafeMutation(
    UPDATE_ORDER_NOTIFICATIONS_FLAG,
    {
      onCompleted(data) {
        const value = data.updateOrder.sendOrderNotifications as boolean;

        setSendOrderNotifications(value);

        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: value
              ? AllowNotificationMessage.ALLOWED
              : AllowNotificationMessage.ON_HOLD,
          })
        );
      },
      update(cache, { data: { updateOrder } }) {
        cache.modify({
          optimistic: true,
          fields: {
            searchOrders(data) {
              const orders = data.orders || [];
              return orders.map((order: Order) =>
                order.id === props.order?.id
                  ? {
                      ...order,
                      sendOrderNotifications:
                        updateOrder.sendOrderNotifications,
                    }
                  : order
              );
            },
          },
        });
      },
    }
  );

  const [sendNotification, { loading: sendNotificationIsLoading }] =
    useSafeMutation(SEND_NOTIFICATION, {
      onCompleted() {
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.NOTIFICATION_SENT_SUCCESSFULLY,
          })
        );
      },
    });

  const { data, loading: searchNotificationTypesIsLoading } = useSafeQuery(
    SEARCH_NOTIFICATION_TYPES,
    {
      variables: {
        limit: 100,
        recordTypes: ['ORDER', 'SHIPMENT'],
        includeDeleted: true,
        orderAsc: 'name',
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const queriedNotificationTypes =
    data?.searchNotificationsTypes?.notificationTypes || [];

  // filter out deleted notification types for send dropdown
  const excludeDeletedNotificationTypes = (nType: NotificationType): boolean =>
    !nType.deletedAt;

  // filter out shipment notification types for send dropdown
  const excludeShipmentNotificationTypes = (nType: NotificationType): boolean =>
    nType.recordType !== 'SHIPMENT';

  const memoNotificationTypeLookup: Record<string, NotificationType> = React.useMemo(() => {
    if (!queriedNotificationTypes.length) return {};
    return Object.fromEntries(
      queriedNotificationTypes.map((nType: NotificationType) => [nType.id, nType])
    );
  }, [queriedNotificationTypes.length]);

  const handleOnAllowNotificationsChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateOrder({
      variables: {
        id: props.order?.id as string,
        sendOrderNotifications: event.target.checked,
      },
    });
  };

  const handleOnChange = (event: SelectChangeEvent<string>) => {
    setSelectedOrderNotificationTypeId(event.target.value);
  };

  const handleOnClickSend = () => {
    sendNotification({
      variables: {
        recordId: props.order?.id as string,
        notificationTypeId: selectedOrderNotificationTypeId,
      },
    });
  };

  const showLoadingIndicator =
    updateOrderIsLoading ||
    sendNotificationIsLoading ||
    searchNotificationTypesIsLoading;

  return (
    <Container sx={OrderNotificationsContainerSx}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={NotificationTypeStackSx}>
          <FormControl fullWidth>
            <InputLabel>Notification type *</InputLabel>
            <Select
              id="notification-type-select"
              variant="outlined"
              label="Notification type *"
              value={selectedOrderNotificationTypeId}
              onChange={handleOnChange}
            >
              {queriedNotificationTypes
                .slice()
                .filter(excludeDeletedNotificationTypes)
                .filter(excludeShipmentNotificationTypes)
                .map((orderNotificationType: any) => {
                return (
                  <MenuItem key={orderNotificationType.id} value={orderNotificationType.id}>
                    {fromCamelToSentenceCase(orderNotificationType.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            gaContext={{
              textCopy: 'Send',
              navigates_to: 'N/A',
              purpose: 'Sends Order Notification',
            }}
            size="small"
            variant="contained"
            sx={SendButtonSx}
            onClick={handleOnClickSend}
            disabled={!selectedOrderNotificationTypeId}
          >
            <Typography variant="button" sx={SendButtonTextSx}>
              Send
            </Typography>
          </Button>
        </Stack>
        <FormControlLabel
          sx={{ mr: 0 }}
          control={
            <Switch
              color="secondary"
              checked={sendOrderNotifications}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={handleOnAllowNotificationsChange}
            />
          }
          label="Allow notifications"
        />
      </Stack>
      <NotificationsTable
        order={props.order}
        showLoadingIndicator={showLoadingIndicator}
        sendNotification={sendNotification}
        notificationTypeLookup={memoNotificationTypeLookup}
      />
    </Container>
  );
};

export default OrderNotifications;
