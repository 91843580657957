import React from 'react';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { GET_ALL_PURCHASERS_BY_ORG_ID } from './queries';
import CreateOrderState from './types';

const useCreateOrderState = (orderTypeLookup: Record<string, any>) => {
  const [organizationId, setOrganizationId] = React.useState<string>('');
  const [purchasersSelectable, setPurchasersSelectable] = React.useState<
    [collaboratorId: string, collaboratorName: string][]
  >([]);
  const [purchaserId, setPurchaserId] = React.useState<string>('');

  const [
    fetchOrgPurchasers,
    { loading: loadingPurchasers, data: purchasersData },
  ] = useSafeLazyQuery(GET_ALL_PURCHASERS_BY_ORG_ID, {
    onCompleted: (data) => {
      const { getAllCollaboratorsByOrgId = [] } = data;
      if (getAllCollaboratorsByOrgId.length === 0) return;
      const selectablePurchasers = getAllCollaboratorsByOrgId
        .slice()
        .sort((a: any, b: any) =>
          a?.lastName
            .toLowerCase()
            .replace(' ', '')
            .localeCompare(b?.lastName.toLowerCase().replace(' ', ''))
        )
        .map((collaborator: any) => {
          const { id, firstName, lastName } = collaborator;
          return [id, `${firstName} ${lastName}`];
        });
      setPurchasersSelectable(selectablePurchasers);
    },
  });

  const getPurchaser = (collaboratorId: string = '') => {
    const searchId = collaboratorId || purchaserId;
    const matchingPurchaser = purchasersData.getAllCollaboratorsByOrgId.find(
      (purchaser: any) => purchaser.id === searchId
    );
    if (!matchingPurchaser)
      return { id: '', firstName: '', lastName: '', email: '' };
    return matchingPurchaser;
  };

  const isPurchaserDisabled = (): boolean =>
    purchasersSelectable.length === 0 || !organizationId;

  const resetPurchaser = () => {
    setPurchaserId('');
    setPurchasersSelectable([]);
  };

  const resetAllPendingOrderSelections = () => {
    setOrganizationId('');
    resetPurchaser();
  };

  const getOrgPurchasers = () => {
    fetchOrgPurchasers({
      variables: {
        organizationId,
        isAdmin: true,
      },
    });
  };

  React.useEffect(() => {
    resetAllPendingOrderSelections();
  }, []);

  React.useEffect(() => {
    if (organizationId) return;
    resetPurchaser();
  }, [organizationId]);

  React.useEffect(() => {
    if (!organizationId) return;
    getOrgPurchasers();
  }, [organizationId]);

  return {
    organizationId,
    purchaserId,
    getPurchaser,
    setPurchaserId,
    resetAllPendingOrderSelections,
    purchasersSelectable,
    setOrganizationId,
    resetPurchaser,
    isPurchaserDisabled,
  } as CreateOrderState;
};

export default useCreateOrderState;
