import { gql } from '@apollo/client';

export const SEARCH_NOTIFICATION_TYPES = gql`
  query searchNotificationsTypes(
    $limit: Int!
    $recordType: String
    $recordTypes: [String!]
    $includeDeleted: Boolean
    $orderAsc: String
    $orderDesc: String
  ) {
    searchNotificationsTypes(
      notificationTypeSearchInput: {
        limit: $limit
        recordType: $recordType
        recordTypes: $recordTypes
        includeDeleted: $includeDeleted
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      notificationTypes {
        id
        name
        deletedAt
        recordType
      }
    }
  }
`;
