import React from 'react';
import { SVGProps } from '../../types';

const PendingAllocationIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F9C54D" />
      <path
        d="M5.8335 11.9987C5.8335 8.59419 8.59029 5.83203 11.9935 5.83203C15.4043 5.83203 18.1668 8.59517 18.1668 11.9987C18.1668 15.4022 15.4043 18.1654 11.9935 18.1654C8.59029 18.1654 5.8335 15.4032 5.8335 11.9987ZM6.16683 11.9987C6.16683 15.2215 8.77735 17.832 12.0002 17.832C15.223 17.832 17.8335 15.2215 17.8335 11.9987C17.8335 8.77589 15.223 6.16536 12.0002 6.16536C8.77735 6.16536 6.16683 8.77589 6.16683 11.9987Z"
        fill="#131414"
        stroke="#131414"
      />
      <path
        d="M12.3335 8.66797H11.3335V12.668L14.8335 14.768L15.3335 13.948L12.3335 12.168V8.66797Z"
        fill="#131414"
      />
    </svg>
  );
};

export default PendingAllocationIcon;
