export const getUserIdFullNameTuple = (users: Record<string, any>[]) =>
  users.map((user) => {
    const { id, firstName = '', lastName = '' } = user;
    const spacer = firstName && lastName ? ' ' : '';
    return [id, `${firstName}${spacer}${lastName}`.trimEnd()];
  });

export const sortUsersByLastName = (users: Record<string, any>[]) =>
  users
    .slice()
    .sort((a: Record<string, any>, b: Record<string, any>) =>
      (a?.firstName ?? '')
        .toLowerCase()
        .replace(' ', '')
        .localeCompare((b?.firstName ?? '').toLowerCase().replace(' ', ''))
    )
    .sort((c: Record<string, any>, d: Record<string, any>) =>
      (c?.lastName ?? '')
        .toLowerCase()
        .replace(' ', '')
        .localeCompare((d?.lastName ?? '').toLowerCase().replace(' ', ''))
    );
