import React from 'react';
import { SVGProps } from '../../types';

const LostIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#E0E0E0" />
      <path
        d="M12.0001 17.9008C11.9017 17.784 11.7868 17.6455 11.6592 17.4883C11.2282 16.9572 10.6542 16.214 10.0813 15.3649C9.50742 14.5143 8.94069 13.5664 8.51896 12.625C8.0943 11.6771 7.8335 10.7725 7.8335 9.9987C7.8335 9.68904 7.87444 9.37908 7.94182 9.0728L8.00064 8.80541L7.80705 8.61181L6.0406 6.84536L6.2735 6.61247L16.7197 17.0587L16.4868 17.2916L14.607 15.4118L14.181 14.9857L13.8408 15.4831C13.2807 16.302 12.7263 17.0155 12.3117 17.5246C12.1958 17.6669 12.0909 17.793 12.0001 17.9008ZM11.6057 16.8927L11.9994 17.398L12.394 16.8934C12.8055 16.3672 13.2673 15.7495 13.7102 15.0817L13.9362 14.741L13.647 14.4518L9.03372 9.83848L8.09772 8.90248L8.18116 10.2235C8.24868 11.2926 8.79294 12.5441 9.44736 13.7022C10.1109 14.8764 10.9265 16.0208 11.6057 16.8927ZM15.8335 9.9987C15.8335 7.88256 14.1163 6.16536 12.0002 6.16536C11.1867 6.16536 10.439 6.42763 9.82567 6.8571L9.5783 6.60972C10.2614 6.1209 11.0941 5.83203 12.0002 5.83203C14.304 5.83203 16.1668 7.69484 16.1668 9.9987C16.1668 10.7556 15.9159 11.632 15.5116 12.5497L15.2645 12.3026C15.6048 11.5132 15.8335 10.7156 15.8335 9.9987ZM11.8099 8.84804C11.8719 8.83749 11.9354 8.83203 12.0002 8.83203C12.644 8.83203 13.1668 9.35484 13.1668 9.9987C13.1668 10.0634 13.1614 10.127 13.1508 10.1889L11.8099 8.84804Z"
        fill="#131414"
        stroke="#131414"
      />
    </svg>
  );
};

export default LostIcon;
