import React from 'react';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';

import AssetsPanel from '../panel/assets/AssetsPanel';
import OrdersPanel from '../panel/orders/OrdersPanel';
import ListTab from '../ListTab';

import { BoxSVGIcon } from 'components/Icons';
import {
  useHandleOnClick,
  useResetTotalsOnUnmount,
  useResetOrdersOnUnmount,
  useResetAssetsOnUnmount,
  useResetHomeUIStateOnUmount,
  useGetInitialAssetsOnOrganizationChange,
  useGetInitialOrdersOnOrganizationChange,
} from './hooks';
import * as styles from './styles';
import { useSelector } from 'react-redux';
import { selectActiveTab } from 'store/slices/ui/home/selectors';
import {
  selectOrdersCount,
  selectOrdersLoading,
} from 'store/slices/home/orders/selectors';
import {
  selectAssetsCount,
  selectAssetsLoading,
} from 'store/slices/home/assets/selectors';
import CircularProgress from '@mui/material/CircularProgress';

const TabsContainer = () => {
  useResetHomeUIStateOnUmount();
  useResetAssetsOnUnmount();
  useResetOrdersOnUnmount();
  useResetTotalsOnUnmount();
  useGetInitialAssetsOnOrganizationChange();
  useGetInitialOrdersOnOrganizationChange();
  const assetsCount = useSelector(selectAssetsCount);
  const ordersCount = useSelector(selectOrdersCount);
  const assetsLoading = useSelector(selectAssetsLoading);
  const ordersLoading = useSelector(selectOrdersLoading);
  const handleOnClick = useHandleOnClick();
  const activeTab = useSelector(selectActiveTab);
  const mapping = {
    orders: 0,
    assets: 1,
  };
  return (
    <Stack sx={styles.ListContainerSx}>
      {(activeTab === 'assets' && assetsLoading === 'pending') ||
      (activeTab === 'orders' && ordersLoading === 'pending') ? (
        <Stack sx={styles.LoadingOverlaySx}>
          <CircularProgress sx={styles.LoadingOverlaySpinnerSx} />
        </Stack>
      ) : null}
      <Stack sx={styles.ListContainerHeaderSx}>
        <Stack sx={styles.ListContainerRecentUpdatesSx}>Recent updates</Stack>
        <Stack sx={styles.ListContainerTimeSx}>Last 14 days</Stack>
      </Stack>
      <Stack sx={styles.ListContainerContentSx}>
        <Tabs sx={styles.ListContainerTabsSx} value={mapping[activeTab]}>
          <ListTab
            id={0}
            onClick={handleOnClick('orders')}
            customIcon={BoxSVGIcon}
            label="Orders"
            count={ordersCount}
            active={mapping[activeTab] === 0}
            area-current={mapping[activeTab] === 0}
          />
          <ListTab
            id={1}
            onClick={handleOnClick('assets')}
            muiIcon={LaptopOutlinedIcon}
            label="Assets"
            count={assetsCount}
            active={mapping[activeTab] === 1}
            area-current={mapping[activeTab] === 1}
          />
        </Tabs>
      </Stack>
      <Stack sx={styles.ListPanelContainerSx}>
        {activeTab === 'orders' ? <OrdersPanel /> : null}
        {activeTab === 'assets' ? <AssetsPanel /> : null}
      </Stack>
    </Stack>
  );
};

export default TabsContainer;
