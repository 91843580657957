import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const CheckBoxFromControlSx: SxProps = {
  marginTop: '-8px',
};

export const MainCheckBoxSx: SxProps = {
  '&.Mui-checked': {
    color: theme.palette.colors.woodSmoke,
  },

  '&.Mui-disabled': {
    color: theme.palette.colors.disableInputGrey,
    cursor: 'not-allowed',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const CheckBoxLabelSx: SxProps = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
  },
};
