import React from 'react';
import { SVGProps } from '../../types';

const DonatedIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#E0E0E0" />
      <path
        d="M15.0002 6C13.8402 6 12.7268 6.54 12.0002 7.39333C11.2735 6.54 10.1602 6 9.00016 6C6.94683 6 5.3335 7.61333 5.3335 9.66667C5.3335 12.1867 7.60016 14.24 11.0335 17.36L12.0002 18.2333L12.9668 17.3533C16.4002 14.24 18.6668 12.1867 18.6668 9.66667C18.6668 7.61333 17.0535 6 15.0002 6ZM12.0668 16.3667L12.0002 16.4333L11.9335 16.3667C8.76016 13.4933 6.66683 11.5933 6.66683 9.66667C6.66683 8.33333 7.66683 7.33333 9.00016 7.33333C10.0268 7.33333 11.0268 7.99333 11.3802 8.90667H12.6268C12.9735 7.99333 13.9735 7.33333 15.0002 7.33333C16.3335 7.33333 17.3335 8.33333 17.3335 9.66667C17.3335 11.5933 15.2402 13.4933 12.0668 16.3667Z"
        fill="#131414"
      />
    </svg>
  );
};

export default DonatedIcon;
