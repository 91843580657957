import { TotalsState } from './types';
import { LoadingState } from '../../global/types';

export const INITIAL_STATE: () => TotalsState = () => {
  return {
    allOrdersCount: 0,
    allOrdersCountLoading: 'idle',
    openOrdersCount: 0,
    openOrdersCountLoading: 'idle',
    inTransitOrdersCount: 0,
    inTransitOrdersCountLoading: 'idle',
    completeOrdersCount: 0,
    completeOrdersCountLoading: 'idle',
    needsAttentionOrdersCount: 0,
    needsAttentionOrdersCountLoading: 'idle',
    expiringSoonOrdersCount: 0,
    expiringSoonOrdersCountLoading: 'idle',

    allAssetsCount: 0,
    allAssetsCountLoading: 'idle',
    allocatedAssetsCount: 0,
    allocatedAssetsCountLoading: 'idle',
    inInventoryAssetsCount: 0,
    inInventoryAssetsCountLoading: 'idle',
    readyToDeployAssetsCount: 0,
    readyToDeployAssetsCountLoading: 'idle',
    needsAttentionAssetsCount: 0,
    needsAttentionAssetsCountLoading: 'idle',
  };
};
