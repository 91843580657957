import { BORDER_COLOR } from '../../styles';

export const TimeSavedContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${BORDER_COLOR}`,
  borderRadius: '5px',
  minHeight: '150px',
  width: '380px',
  paddingTop: '15px',
  paddingBottom: '20px',
  paddingLeft: '15px',
  paddingRight: '15px',
  fontSize: '14px',
  position: 'relative',
};

export const LoadingOverlaySx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  zIndex: '100',
  top: '0px',
  left: '0px',
  height: '100%',
  borderRadius: '5px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  width: '100%',
};

export const LoadingOverlaySpinnerSx = {
  display: 'flex',
};

export const TimeSavedHeaderSx = {
  display: 'flex',
  fontSize: '18px',
  fontWeight: '700',
  paddingBottom: '15px',
};

export const TimeSavedContentSx = {
  display: 'flex',
  flexDirection: 'row',
};

export const TimeSavedContentLeftSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '50%',
  '& img': {
    width: '80px',
    height: '80px',
  },
};

export const TimeSavedContentRightSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: '50%',
  '& img': {
    height: '80px',
  },
};

export const TimeSavedTotalSx = {
  fontWeight: '500',
  fontSize: '25.6px',
};

export const TimeSavedTotalLabelSx = {
  display: 'flex',
  fontWeight: '500',
  flexDirection: 'row',
  fontSize: '12px',
  color: 'rgba(96, 96, 93, 1)',
};

export const TimeSavedContentInfoSx = {
  paddingLeft: '10px',
};

export const TimeSavedTotalTooltipIconSx = {
  position: 'relative',
  top: '2px',
  fontSize: '15px',
  paddingLeft: '2px',
  cursor: 'pointer',
};
