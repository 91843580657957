import { gql } from '@apollo/client';

export const V2FindAndCountAssets = gql`
  query V2FindAndCountAssets($where: V2AssetWhereInput!) {
    v2FindAndCountAssets(where: $where) {
      count
    }
  }
`;

export const v2FindAssetsByStatusTransition = (output: string) => gql`
  query v2FindAssetsByStatusTransition(
    $where: V2AssetStatusTransitionWhereInput!
    $orderBy: [V2AssetStatusTransitionOrderByWithRelationInput!]
    $skip: Int
    $take: Int
  ) {
      v2FindAssetsByStatusTransition(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      ${output}
    }
  }
`;
