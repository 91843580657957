import MuiButton from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Button } from 'components';
import { fromCamelToSentenceCase, formatDate } from 'services';
import { Order, NotificationType } from 'types';
import {
  FailedTimeStampTextSx,
  PendingTimeStampTextSx,
  SentTimeStampTextSx,
  TimeStampBaseTextSx,
  DecommissionButtonSx,
} from './styles';

export const useGetOrderNotificationsColumns = (
  order: Order,
  sendNotification: any,
  notificationTypeLookup: Record<string, NotificationType>,
): GridColDef[] => {

  return [
    {
      field: 'timeStamp',
      headerName: 'Time stamp',
      align: 'left',
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter(params) {
        if (!params.row.createdAt) return '';
        return params.row.createdAt
          ? formatDate(params.row.createdAt, 'MMM D, yyyy h:mm A')
          : '';
      },
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'notification',
      headerName: 'Notification',
      align: 'left',
      sortable: false,
      filterable: false,
      flex: 2,
      renderCell: (params) => {
        const orderNotificationTypeName = params.row.notificationTypeId in notificationTypeLookup 
        ? fromCamelToSentenceCase(
          `${notificationTypeLookup[params.row.notificationTypeId].name}`
        )
        : '';

        const decommissioned = params.row.notificationTypeId in notificationTypeLookup 
          ? !!notificationTypeLookup[params.row.notificationTypeId]?.deletedAt
          : false;

        const timeStampComponent = () => {
          if (params.row.status === 'PENDING') {
            return (
              <Stack direction="row" alignItems="flex-end" columnGap="4px">
                <QueryBuilderIcon fontSize="small" htmlColor="#1314148A" />
                <Typography variant="body2" sx={PendingTimeStampTextSx}>
                  PENDING
                </Typography>
              </Stack>
            );
          }

          if (params.row.status === 'FAILED') {
            return (
              <Stack direction="row" alignItems="flex-end" columnGap="4px">
                <ErrorOutlineOutlinedIcon
                  fontSize="small"
                  sx={{ color: 'colors.burntSiennaR' }}
                />
                <Typography variant="body2" sx={FailedTimeStampTextSx}>
                  FAILED ON{' '}
                  {formatDate(params.row.updatedAt, 'MM/DD/YY @ hh:mmA')}
                </Typography>
              </Stack>
            );
          }

          return (
            <Stack direction="row" alignItems="flex-end" columnGap="4px">
              <CheckIcon fontSize="small" sx={{ color: 'colors.goldFusion' }} />
              <Typography variant="body2" sx={SentTimeStampTextSx}>
                SENT ON {formatDate(params.row.updatedAt, 'MM/DD/YY @ hh:mmA')}
              </Typography>
            </Stack>
          );
        };

        return (
          <div style={{}}>
            <>
              <Typography variant="body2" sx={TimeStampBaseTextSx}>
                {`${orderNotificationTypeName}${decommissioned ? ' (Decommissioned)' : ''}`}
              </Typography>
              {timeStampComponent()}
            </>
          </div>
        );
      },
    },
    {
      field: 'sentTo',
      headerName: 'Sent to',
      align: 'left',
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter(params) {
        const notificationTypeName = params.row.notificationTypeId in notificationTypeLookup 
          ? notificationTypeLookup[params.row.notificationTypeId]?.typeName ?? '' 
          : '';

        const isRecipientEmail = notificationTypeName === 'RECIPIENT';
        if (isRecipientEmail) {
          if (order.dropRecipient) {
            return `${order.dropRecipient.firstName} ${order.dropRecipient.lastName}`;
          }
          if (order.recipient) {
            return `${order.recipient.firstName} ${order.recipient.lastName}`;
          }
          return '';
        }

        return order?.purchaser
          ? `${order?.purchaser?.firstName} ${order?.purchaser?.lastName}`
          : '';
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      type: 'actions',
      renderCell(params) {
        const decommissioned = params.row.notificationTypeId in notificationTypeLookup 
          ? !!notificationTypeLookup[params.row.notificationTypeId]?.deletedAt
          : false;

        if (decommissioned) {
          return (
            <Tooltip title="Decommissioned notifications are inactive and no longer available to resend. Please select an alternative notification." arrow>
              <span>
                <MuiButton color="secondary" disabled variant="outlined" sx={DecommissionButtonSx}>
                  N/A
                </MuiButton>
              </span>
            </Tooltip>
          )
        }

        return (
          <Button
            gaContext={{
              textCopy: 'Resend',
              navigates_to: 'N/A',
              purpose: 'Resends User Notification',
            }}
            variant="outlined"
            color="secondary"
            sx={{ padding: '8px 22px', minWidth: '85px' }}
            onClick={() => {
              sendNotification({
                variables: {
                  recordId: params.row.recordId,
                  notificationTypeId: params.row.notificationTypeId,
                },
              });
            }}
          >
            <Typography variant="button" color="secondary">
              Resend
            </Typography>
          </Button>
        );
      },
    },
  ];
};
