export const ListTabContainerSx = {
  display: 'flex',
  flexDirection: 'row',
  width: '110px',
  fontSize: '14px',
};

export const ListTabIconSx = (active: boolean) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  color: active ? 'black' : 'rgba(0, 0, 0, 0.57)',
  width: '20%',
});

export const ListTabLabelSx = (active: boolean) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '600',
  color: active ? 'black' : 'rgba(0, 0, 0, 0.57)',
  width: '60%',
  position: 'relative',
  top: '2px',
});

export const ListTabCountSx = (active: boolean) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  color: active ? 'black' : 'rgba(0, 0, 0, 0.57)',
  width: '20%',
  position: 'relative',
  top: '2px',
});
