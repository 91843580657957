import { createSlice } from '@reduxjs/toolkit';
import OrderManagementSearchState from './types';
import {
  resetComboSearchReducer,
  setLookAheadOptionReducer,
  setSearchFilterByKeyReducer,
  clearSearchFilterByKeyReducer,
  resetNonComboSearchReducer,
  setOrderManagerLoadStatusReducer,
  setOMLookAheadKeyReducer,
  assignOMSelfAssignSwitchReducer,
  clearOMSelfAssignSwitchReducer,
} from './reducers';

export const INITIAL_OM_SEARCH_STATE: OrderManagementSearchState = {
  orderTypeFilter: '',
  statusFilter: '',
  organizationFilter: '',
  isLoadingOrderManagers: false,
  orderManagerFilter: '',
  depotFilter: '',
  lookAheadOptionType: 'orderNumber',
  lookAheadOption: '',
  isOMSelfAssignChecked: false,
};

export const orderManagementSearchSlice = createSlice({
  name: 'OMSearchSlice',
  initialState: INITIAL_OM_SEARCH_STATE,
  reducers: {
    resetComboSearchReducer,
    setLookAheadOptionReducer,
    setSearchFilterByKeyReducer,
    clearSearchFilterByKeyReducer,
    resetNonComboSearchReducer,
    setOrderManagerLoadStatusReducer,
    setOMLookAheadKeyReducer,
    clearOMSelfAssignSwitchReducer,
    assignOMSelfAssignSwitchReducer,
    resetState: () => INITIAL_OM_SEARCH_STATE,
  },
});

export const {
  resetState,
  resetComboSearchReducer: resetOMComboSearch,
  setLookAheadOptionReducer: setOMLookAheadOptionAndKey,
  setSearchFilterByKeyReducer: setOMSearchFilterByKey,
  clearSearchFilterByKeyReducer: clearOMSearchFilterByKey,
  resetNonComboSearchReducer: resetNonComboOMSearch,
  setOrderManagerLoadStatusReducer: setOrderManagerLoadStatus,
  setOMLookAheadKeyReducer: setOMLookAheadKey,
  assignOMSelfAssignSwitchReducer: assignOMSelfAssignSwitch,
  clearOMSelfAssignSwitchReducer: clearOMSelfAssignSwitch,
} = orderManagementSearchSlice.actions;
