/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GAIconButton from 'components/buttons/google_analytics/GAIconButton';
import Typography from '@mui/material/Typography';
import { modalSlice } from 'store/slices';
import { GlobalModals } from 'store/constants';
import * as NewEmployeeButtonStyles from './styles';
import NewEmployeeButtonProps from './types';

const NewEmployeeButton = (props: NewEmployeeButtonProps) => {
  const dispatch = useDispatch();
  const styles = NewEmployeeButtonStyles;
  const {
    buttonCopy = 'New employee', purpose = 'Opens new employee modal', buttonOverwriteSx = {},
    disableRipple = true, ariaLabel = "add new employee", navigatesTo = "N/A", iconOverwriteSx = {},
  } = props;

  const buttonSx = { ...styles.NewEmployeeButtonSx, ...buttonOverwriteSx };

  const handleOpenNewEmployeeModal = () => {
    dispatch(
      modalSlice.actions.setCurrentModal(GlobalModals.ADD_EMPLOYEE_INFORMATION)
    );
  };

  return (
    <GAIconButton
      gaContext={{
        purpose,
        textCopy: buttonCopy,
        navigates_to: navigatesTo,
      }}
      aria-label={ariaLabel}
      color="secondary"
      size="small"
      disableRipple={disableRipple}
      onClick={handleOpenNewEmployeeModal}
    >
      <AddCircleOutlineIcon fontSize="small" sx={iconOverwriteSx} /> &nbsp;
      <Typography variant="button" sx={buttonSx}>
        {buttonCopy}
      </Typography>
    </GAIconButton>
  );
};

export default NewEmployeeButton;
