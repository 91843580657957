import moment from 'moment/moment';

import CompleteIcon from 'components/Icons/homepage/orders/CompleteIcon';
import CreatedIcon from 'components/Icons/homepage/orders/CreatedIcon';
import InFulfillmentIcon from 'components/Icons/homepage/orders/InFulfillmentIcon';
import ShippedIcon from 'components/Icons/homepage/orders/ShippedIcon';
import InReturnIcon from 'components/Icons/homepage/orders/InReturnIcon';
import ExpiredPendingReturnIcon from 'components/Icons/homepage/orders/ExpiredPendingReturnIcon';
import PendingReturnIcon from 'components/Icons/homepage/orders/PendingReturnIcon';
import PendingRepairIcon from 'components/Icons/homepage/orders/PendingRepairIcon';
import QuoteInProgressIcon from 'components/Icons/homepage/orders/QuoteInProgressIcon';
import PendingRecycleIcon from 'components/Icons/homepage/orders/PendingRecycleIcon';
import PendingQuoteApprovalIcon from 'components/Icons/homepage/orders/PendingQuoteApprovalIcon';
import CancelledIcon from 'components/Icons/homepage/orders/CancelledIcon';
import ExceptionIcon from 'components/Icons/homepage/orders/ExceptionIcon';
import PendingAllocationIcon from 'components/Icons/homepage/assets/PendingAllocationIcon';
import ProcessingDeviceIcon from 'components/Icons/homepage/orders/ProcessingDeviceIcon';
import PendingRecipientInformationIcon from 'components/Icons/homepage/orders/PendingRecipientInformationIcon';

import { DATABASE_ORDER_STATUSES } from 'globals/constants/orders';
import { SVGProps } from 'components/Icons/types';

export const ICONS: { [index: string]: (props: SVGProps) => JSX.Element } = {
  [DATABASE_ORDER_STATUSES.CANCELED]: CancelledIcon,
  [DATABASE_ORDER_STATUSES.COMPLETE]: CompleteIcon,
  [DATABASE_ORDER_STATUSES.CREATED]: CreatedIcon,
  [DATABASE_ORDER_STATUSES.EXCEPTION]: ExceptionIcon,
  [DATABASE_ORDER_STATUSES.EXPIRED_PENDING_RECIPIENT_INFORMATION]:
    ExpiredPendingReturnIcon,
  [DATABASE_ORDER_STATUSES.IN_FULFILLMENT]: InFulfillmentIcon,
  [DATABASE_ORDER_STATUSES.IN_RETURN]: InReturnIcon,
  [DATABASE_ORDER_STATUSES.PENDING_CERTIFICATE]: PendingAllocationIcon,
  [DATABASE_ORDER_STATUSES.PENDING_QUOTE_APPROVAL]: PendingQuoteApprovalIcon,
  [DATABASE_ORDER_STATUSES.PENDING_RECYCLE]: PendingRecycleIcon,
  [DATABASE_ORDER_STATUSES.PENDING_REPAIR]: PendingRepairIcon,
  [DATABASE_ORDER_STATUSES.PENDING_APPROVAL]: PendingAllocationIcon,
  [DATABASE_ORDER_STATUSES.PENDING_RECIPIENT_INFORMATION]:
    PendingRecipientInformationIcon,
  [DATABASE_ORDER_STATUSES.PENDING_RETURN]: PendingReturnIcon,
  [DATABASE_ORDER_STATUSES.PROCESSING_DEVICE]: ProcessingDeviceIcon,
  [DATABASE_ORDER_STATUSES.QUOTE_IN_PROGRESS]: QuoteInProgressIcon,
  [DATABASE_ORDER_STATUSES.SHIPPED]: ShippedIcon,
  [DATABASE_ORDER_STATUSES.EXPIRED_PENDING_RETURN]: ExpiredPendingReturnIcon,
};

export const ORDERS_LOOK_BACK_DATE = moment(Date.now())
  .subtract(14, 'd')
  .utc()
  .format();
