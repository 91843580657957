export const DATABASE_ORDER_STATUSES = {
  CANCELED: 'CANCELED',
  COMPLETE: 'COMPLETE',
  CREATED: 'CREATED',
  EXCEPTION: 'EXCEPTION',
  EXPIRED_PENDING_RECIPIENT_INFORMATION:
    'EXPIRED_PENDING_RECIPIENT_INFORMATION',
  IN_FULFILLMENT: 'IN_FULFILLMENT',
  IN_RETURN: 'IN_RETURN',
  PENDING_CERTIFICATE: 'PENDING_CERTIFICATE',
  PENDING_QUOTE_APPROVAL: 'PENDING_QUOTE_APPROVAL',
  PENDING_RECYCLE: 'PENDING_RECYCLE',
  PENDING_REPAIR: 'PENDING_REPAIR',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_RECIPIENT_INFORMATION: 'PENDING_RECIPIENT_INFORMATION',
  PENDING_RETURN: 'PENDING_RETURN',
  PROCESSING_DEVICE: 'PROCESSING_DEVICE',
  QUOTE_IN_PROGRESS: 'QUOTE_IN_PROGRESS',
  SHIPPED: 'SHIPPED',
  EXPIRED_PENDING_RETURN: 'EXPIRED_PENDING_RETURN',
};

export const ORDER_STATUSES_LABELS: {
  [index: string]: string;
} = {
  CANCELED: 'Cancelled',
  COMPLETE: 'Complete',
  CREATED: 'Created',
  EXCEPTION: 'Exception',
  EXPIRED_PENDING_RECIPIENT_INFORMATION:
    'Expired pending recipient information',
  IN_FULFILLMENT: 'In fulfillment',
  IN_RETURN: 'In return',
  PENDING_CERTIFICATE: 'Pending certificate',
  PENDING_QUOTE_APPROVAL: 'Pending quote approval',
  PENDING_RECYCLE: 'Pending recycle',
  PENDING_REPAIR: 'Pending repair',
  PENDING_APPROVAL: 'Preparing order',
  PENDING_RECIPIENT_INFORMATION: 'Pending recipient information',
  PENDING_RETURN: 'Pending return',
  PROCESSING_DEVICE: 'Processing device',
  QUOTE_IN_PROGRESS: 'Quote in progress',
  SHIPPED: 'Shipped',
  EXPIRED_PENDING_RETURN: 'Expired pending return',
};
