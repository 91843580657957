import { createSlice } from '@reduxjs/toolkit';
import { GlobalState } from './types';
import {
  setAuthTokenReducer,
  setGlobalNotificationBannerReducer,
  setGlobalAvatarPopoverReducer,
} from './reducers';
import { setLocalStorageValue } from 'api/storage';

const initialState: GlobalState = {
  authToken: 'initial',
  globalNotificationBanner: {
    show: false,
    content: null,
  },
  globalAvatarPopover: {
    show: false,
  },
};

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState: () => {
    setLocalStorageValue('authToken', 'initial');
    return initialState;
  },
  reducers: {
    setAuthToken: setAuthTokenReducer,
    setGlobalNotificationBanner: setGlobalNotificationBannerReducer,
    setGlobalAvatarPopover: setGlobalAvatarPopoverReducer,
    reset: () => initialState,
  },
});

export const {
  setAuthToken,
  setGlobalNotificationBanner,
  setGlobalAvatarPopover,
} = globalSlice.actions;
