import { gql } from '@apollo/client';

export const GET_ALL_PURCHASERS_BY_ORG_ID = gql`
  query getAllCollaboratorsByOrgId(
    $organizationId: String!
    $isAdmin: Boolean
  ) {
    getAllCollaboratorsByOrgId(
      organizationId: $organizationId
      isAdmin: $isAdmin
    ) {
      id
      firstName
      lastName
      email
      isAdmin
    }
  }
`;
