import { BORDER_COLOR } from '../../styles';

export const PANEL_CONTAINER_WIDTH = 620;
export const PANEL_CONTAINER_HEIGHT = 621;
export const ListContainerSx = {
  display: 'flex',
  width: `${PANEL_CONTAINER_WIDTH}px`,
  border: `1px solid ${BORDER_COLOR}`,
  borderRadius: '5px',
  height: `${PANEL_CONTAINER_HEIGHT}px`,
  pt: '15px',
  pl: '15px',
  pr: '15px',
  position: 'relative',
};

export const LoadingOverlaySx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  zIndex: '100',
  top: '0px',
  left: '0px',
  height: '100%',
  borderRadius: '5px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  width: '100%',
};

export const LoadingOverlaySpinnerSx = {
  display: 'flex',
};

export const ListContainerHeaderSx = {
  display: 'flex',
  flexDirection: 'row',
};

export const ListContainerRecentUpdatesSx = {
  fontSize: '18px',
  fontWeight: '700',
  width: '80%',
};

export const ListContainerTimeSx = {
  display: 'flex',
  flexDirection: 'row',
  fontSize: '14px',
  color: 'rgba(96, 96, 93, 1)',
  width: '20%',
  justifyContent: 'flex-end',
};

export const ListContainerContentSx = {
  pt: '10px',
};

export const ListContainerTabsSx = {
  borderBottom: `1px solid ${BORDER_COLOR}`,
  width: `${PANEL_CONTAINER_WIDTH - 42}px`,
  '& .MuiTabs-indicator': {
    backgroundColor: 'black',
  },
  '& .MuiButtonBase-root': {
    opacity: '1',
  },
};

export const ListPanelContainerSx = {};
