import { WritableDraft } from 'immer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  GlobalState,
  GlobalNotificationBanner,
  GlobalAvatarPopover,
} from './types';
import { setLocalStorageValue } from 'api/storage';

export const setAuthTokenReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<string>
) => {
  const authToken = action.payload;
  state.authToken = authToken;
  setLocalStorageValue('authToken', authToken);
};

export const setGlobalNotificationBannerReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<GlobalNotificationBanner>
) => {
  state.globalNotificationBanner = action.payload;
};

export const setGlobalAvatarPopoverReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<GlobalAvatarPopover>
) => {
  state.globalAvatarPopover = action.payload;
};
