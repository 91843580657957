import { createAsyncThunk } from '@reduxjs/toolkit';
import { bffClient } from 'api/apollo';
import {
  V2FindAndCountOrders,
  v2FindOrdersByStatusTransition,
} from 'api/apollo/queries/v2/orders';
import { V2FindAndCountAssets } from 'api/apollo/queries/v2/assets';

export const getAllOrdersTotals = createAsyncThunk(
  'home/totals/getAllOrdersTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountOrders,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getOpenOrdersTotals = createAsyncThunk(
  'home/totals/getOpenOrdersTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountOrders,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getInTransitOrdersTotals = createAsyncThunk(
  'home/totals/getInTransitOrdersTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountOrders,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getCompleteOrdersTotals = createAsyncThunk(
  'home/totals/getCompleteOrdersTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountOrders,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getNeedsAttentionOrdersTotals = createAsyncThunk(
  'home/totals/getNeedsAttentionOrdersTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountOrders,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getExpiringSoonOrdersTotals = createAsyncThunk(
  'home/totals/getExpiringSoonOrdersTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: v2FindOrdersByStatusTransition('count'),
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getAllAssetsTotals = createAsyncThunk(
  'home/totals/getAllAssetsTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountAssets,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getAllocatedAssetsTotals = createAsyncThunk(
  'home/totals/getAllocatedAssetsTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountAssets,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getInInventoryAssetsTotals = createAsyncThunk(
  'home/totals/getInInventoryAssetsTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountAssets,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getReadyToDeployAssetsTotals = createAsyncThunk(
  'home/totals/getReadyToDeployAssetsTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountAssets,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getNeedsAttentionAssetsTotals = createAsyncThunk(
  'home/totals/getNeedsAttentionAssetsTotals',
  async (params: { variables: {} }) => {
    const { variables } = params;
    return bffClient.query({
      query: V2FindAndCountAssets,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);
