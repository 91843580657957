/* eslint-disable sort-exports/sort-exports */
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { notificationSlice } from 'store/slices';
import { executeNowThenWait } from 'services/misc';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as myComponents from 'components';
import * as styles from './styles';
import { CANCEL_ORDER } from './mutations';
import { useSafeMutation } from 'hooks/useSafeMutation';
import {
  selectOMDetailsSelectedOrder,
  selectOMCancelModalStatus,
} from 'store/slices/order_management/details/selectors';
import { resetOMDetails } from 'store/slices/order_management/details';
import { SEARCH_ORDERS } from 'pages/OrderManagement/queries';

const OrderCancelModal = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { Button: MyButton, NewModal } = myComponents;
  const selectedOrder = useSelector(selectOMDetailsSelectedOrder);
  const modalIsOpen = useSelector(selectOMCancelModalStatus);
  const orderId = selectedOrder?.id ?? '';

  const [cancelOrderMutation, { loading: cancelOrderLoading }] =
    useSafeMutation(CANCEL_ORDER);

  if (!modalIsOpen) return null;

  const headerCopy = 'Are you sure you want to cancel this order?';

  const showChangesSavedSuccessfully = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: 'Order has been cancelled successfully.',
      })
    );
  };

  const refetchQueries = async () => {
    await client.refetchQueries({
      include: [SEARCH_ORDERS],
    });
  };

  const closeCancelOrderModal = (refetchSearchQuery: boolean = false) => {
    if (refetchSearchQuery) {
      refetchQueries();
    }

    dispatch(resetOMDetails());
  };

  const closeModalAndRefetch = () => {
    closeCancelOrderModal(true);
  };

  const handleCancelOrderClick = () => {
    cancelOrderMutation({
      variables: {
        id: orderId,
      },
      onCompleted: () => {
        executeNowThenWait(
          closeModalAndRefetch,
          showChangesSavedSuccessfully,
          500
        );
      },
    });
  };

  const handleCloseModal = () => {
    closeCancelOrderModal();
  };

  return (
    <NewModal
      open={modalIsOpen}
      handleClose={handleCloseModal}
      paperPropsSx={styles.CancelModalPaperSx}
    >
      <Stack
        direction="column"
        spacing={0}
        justifyContent="space-between"
        sx={styles.CancelModalMainStackSx}
      >
        <Typography variant="h5" sx={styles.CancelModalTitleSx}>
          {headerCopy}
        </Typography>
        <Stack
          direction="row"
          spacing={0}
          justifyContent="flex-end"
          sx={{ width: '100%' }}
        >
          <MyButton
            gaContext={{
              textCopy: 'Close',
              navigates_to: 'N/A',
              purpose: 'Closes Cancel Order Modal',
            }}
            id="close-cancel-order-button"
            color="secondary"
            onClick={handleCloseModal}
            sx={styles.CancelModalButtonBaseSx}
          >
            <Typography variant="button">Close</Typography>
          </MyButton>
          <MyButton
            id="submit-cancel-order-button"
            sx={styles.CancelModalButtonBaseSx}
            onClick={handleCancelOrderClick}
            variant="contained"
            gaContext={{
              navigates_to: 'N/A',
              textCopy: 'Yes, cancel',
              purpose: 'Cancels order',
            }}
          >
            <Typography variant="button">Yes, cancel</Typography>
          </MyButton>
        </Stack>
      </Stack>
    </NewModal>
  );
};

export default OrderCancelModal;
