import { BORDER_COLOR } from '../../../styles';

const FONT_COLOR = 'rgba(19, 20, 20, 1)';

export const FAQCardContainerSx = {
  border: `1px solid ${BORDER_COLOR}`,
  borderRadius: '10px',
  width: '270px',
  height: '160px',
  padding: '15px',
  fontSize: '12px',
};

export const FAQCardIconSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  borderRadius: '5px',
  '& svg': {
    width: '30px',
    height: '30px',
    color: `${FONT_COLOR}`,
  },
};
export const FAQCardLinksSx = {
  borderRadius: '5px',
  pt: '10px',
  color: `${FONT_COLOR}`,
  a: {
    textDecoration: 'none',
    color: `${FONT_COLOR}`,
    ':hover': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
};

export const FAQCardLinkRowSx = (index: number) => ({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: !(index % 2) ? `1px solid ${BORDER_COLOR}` : 'none',
  padding: '10px',
  cursor: 'pointer',
  userSelect: 'none',
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

export const FAQCardLinkCopySx = {
  display: 'flex',
  width: '80%',
};

export const FAQCardLinkSx = {
  display: 'flex',
  width: '20%',
  flexDirection: 'row',
  justifyContent: 'flex-end',
};
