import { createSlice } from '@reduxjs/toolkit';

import { setActiveTabReducer } from './reducers';
import { HomeInitialState } from './types';

const initialState: HomeInitialState = {
  activeTab: 'orders',
  initialLoad: true,
};

export const homeUISlice = createSlice({
  name: 'homeUISlice',
  initialState,
  reducers: {
    setActiveTabReducer,
    resetState: () => initialState,
  },
});

export const { resetState, setActiveTabReducer: setActiveTab } =
  homeUISlice.actions;
