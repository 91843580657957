import React from 'react';
import { useGetAssetsTotalsConfig } from './hooks';
import TotalsCard from '../totals/TotalsCard';

const AssetsTotals = () => {
  const config = useGetAssetsTotalsConfig();
  return (
    <div>
      <TotalsCard {...config} />
    </div>
  );
};

export default AssetsTotals;
