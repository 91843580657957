import { Avatar as MuiAvatar } from '@mui/material';
import { AvatarProps } from './types';
import { getAvatarSx } from './styles';

const Avatar = (props: AvatarProps) => {
  return (
    <MuiAvatar
      sx={getAvatarSx(props)}
      data-testid={props?.['data-testid'] ?? ''}
      {...props}
    >
      {props.text}
    </MuiAvatar>
  );
};

export default Avatar;
