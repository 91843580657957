import { AssetsState } from './types';

export const INITIAL_STATE: () => AssetsState = () => {
  return {
    assets: [],
    count: 0,
    skip: 0,
    take: 12,
    loading: 'idle',
    initialLoading: 'idle',
    hoursSaved: 0,
    hoursSavedLoading: 'idle',
  };
};
