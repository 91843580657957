import { createSlice } from '@reduxjs/toolkit';
import { setShippingPopoverHoveredOrderReducer } from './reducers';
import { OrdersDatagridState } from './types';

export const INITIAL_ORDERS_DATAGRID_STATE: OrdersDatagridState = {
  shippingPopover: {
    hoveredOrder: null,
  },
};

export const orderDatagridSlice = createSlice({
  name: 'orderDatagridSlice',
  initialState: INITIAL_ORDERS_DATAGRID_STATE,
  reducers: {
    setShippingPopoverHoveredOrderReducer,
    resetState: () => INITIAL_ORDERS_DATAGRID_STATE,
  },
});

export const {
  resetState,
  setShippingPopoverHoveredOrderReducer: setShippingPopoverHoveredOrder,
} = orderDatagridSlice.actions;
