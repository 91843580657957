import { CSSProperties } from 'react';

export const WhatsNewContainerSx = {
  height: '40px',
  mr: '10px',
  display: 'flex',
  flexDirection: 'row',
  '& div:hover': {
    width: '128px !important',
    transition: 'width 0.1s',
    pr: '2px',
    '#whats-new-copy': {
      visibility: 'visible',
      pl: '5px',
    },
  },
};

export const WhatsNewButtonSx = {
  height: '40px',
  border: '1px solid rgba(19, 20, 20, 1)',
  m: 0,
  p: 0,
  color: 'black',
  minWidth: '0px',
  fontWeight: '700',
  width: '40px !important',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  '& svg': {
    position: 'relative',
    left: '13px',
  },
  ':hover': {
    width: '128px !important',
    transition: 'width 0.1s',
    pr: '2px',
    '#whats-new-copy': {
      visibility: 'visible',
      pl: '5px',
    },
  },
};

export const WhatsNewCopySx = {
  display: 'flex',
  justifyContent: 'center',
  height: '40px',
  pl: '5px',
  visibility: 'hidden',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  position: 'relative',
  top: '1px',
};

export const WhatsNewALinkSx: CSSProperties = {
  textDecoration: 'none',
  color: 'black',
  // ':hover': {
  //   color: 'black',
  // },
};
