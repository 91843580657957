import React from 'react';
import { SVGProps } from '../../types';

const InFulfillmentIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4DB7C6" />
      <path
        d="M17.333 8.66797L17.9597 7.29464L19.333 6.66797L17.9597 6.0413L17.333 4.66797L16.7063 6.0413L15.333 6.66797L16.7063 7.29464L17.333 8.66797Z"
        fill="white"
      />
      <path
        d="M9.6665 8.66797L10.2932 7.29464L11.6665 6.66797L10.2932 6.0413L9.6665 4.66797L9.03984 6.0413L7.6665 6.66797L9.03984 7.29464L9.6665 8.66797Z"
        fill="white"
      />
      <path
        d="M17.333 12.3359L16.7063 13.7093L15.333 14.3359L16.7063 14.9626L17.333 16.3359L17.9597 14.9626L19.333 14.3359L17.9597 13.7093L17.333 12.3359Z"
        fill="white"
      />
      <path
        d="M15.8065 10.08L13.9199 8.19333C13.7865 8.06667 13.6199 8 13.4465 8C13.2732 8 13.1065 8.06667 12.9732 8.19333L5.52654 15.64C5.26654 15.9 5.26654 16.32 5.52654 16.58L7.41321 18.4667C7.54654 18.6 7.71321 18.6667 7.88654 18.6667C8.05988 18.6667 8.22654 18.6 8.35988 18.4733L15.8065 11.0267C16.0665 10.7667 16.0665 10.34 15.8065 10.08ZM13.4465 9.61333L14.3865 10.5533L13.6065 11.3333L12.6665 10.3933L13.4465 9.61333ZM7.88654 17.06L6.94654 16.12L11.7265 11.3333L12.6665 12.2733L7.88654 17.06Z"
        fill="white"
      />
    </svg>
  );
};

export default InFulfillmentIcon;
