import { createAsyncThunk } from '@reduxjs/toolkit';
import { bffClient } from 'api/apollo';
import { DocumentNode } from '@apollo/client';

export const getOrders = createAsyncThunk(
  'home/orders/getOrders',
  async (params: { query: DocumentNode; variables: {}; append: boolean }) => {
    const { query, variables } = params;
    return bffClient.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);

export const getHoursSaved = createAsyncThunk(
  'home/orders/getHoursSaved',
  async (params: { query: DocumentNode; variables: {}; append: boolean }) => {
    const { query, variables } = params;
    return bffClient.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    });
  }
);
