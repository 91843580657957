export const additionalTextSx = { 
    maxWidth: '350px',
    height: '28px',
    mt: '2px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
};

export const ShipmentStatusPillSubtextSx = {
    cursor: 'pointer',
}
