import { SxProps } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-pro';
import theme from 'themes/theme';

export const EmployeeStepMainContainerSx: SxProps = {
  overflowY: 'scroll',
  pt: '24px',
};

export const NewEmployeeButtonSx: SxProps = {
  border: `1px solid ${theme.palette.colors.woodSmoke}`,
  borderRadius: '24px',
  pl: '40px',
  pr: '16px',
  py: '6px',
};

export const NewEmployeeButtonIconSx: SxProps = {
  left: '24px',
  position: 'absolute',
};

export const NewEmployeeButtonBoxSx: SxProps = {
  position: 'relative',
  width: '172px',
};

export const EmployeeDatagridSx: SxProps = {
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: '#FAF7F099',
      border: '2px solid #1314143B',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    },
    '&.Mui-selected': {
      backgroundColor: '#FAF7F099',
      border: '2px solid #1314143B',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
      '&:hover, &.Mui-hovered': {
        backgroundColor: '#FAF7F099',
        border: '2px solid #1314143B',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: '#FAF7F099',
          border: '2px solid #1314143B',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        },
      },
    },
  },
};
