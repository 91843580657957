import { gql } from '@apollo/client';

export const v2FindOrdersByStatusTransition = (output: string) => gql`
  query v2FindOrdersByStatusTransition(
    $where: V2OrderStatusTransitionWhereInput!
    $orderBy: [V2OrderStatusTransitionOrderByWithRelationInput!]
    $skip: Int
    $take: Int
  ) {
    v2FindOrdersByStatusTransition(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      ${output}
    }
  }
`;

export const V2CalculateHoursOfSavedWork = () => gql`
  query V2CalculateHoursOfSavedWork($where: V2OrderWhereInput!) {
    v2CalculateHoursOfSavedWork(where: $where)
  }
`;

export const V2FindAndCountOrders = gql`
  query V2FindAndCountOrders($where: V2OrderWhereInput!) {
    v2FindAndCountOrders(where: $where) {
      count
    }
  }
`;
