import React from 'react';
import some from 'lodash/some';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  GridColDef,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import { Depot, AssetStatusSelfDescriptive } from 'global-constants';
import { AssetImageChip } from 'components';
import { NavigationContext } from 'context/NavigationContext';
import { capitalize } from 'services/string';
import {
  AssetConditionMapping,
  AssetTypeMapping,
  AssetStatusMapping,
} from 'pages/Assets/constants';
import { formatDate } from 'services';
import { PURCHASE_DATE_COLUMN_TOOLTIP_COPY } from './constants';
import AssetStatusPill from 'components/AssetStatusPill';
import { AssetStatusPillViews } from 'components/AssetStatusPill/constants';
import { useTheme } from '@mui/material';
import {
  dateUTCStringToMediumDate,
  getEasternUSTime,
  dayUTCIsToday,
} from 'services/date';
import { selectColumns } from 'store/slices/assets/columns/selectors';
import { AssetColumnKey } from 'store/slices/assets/columns/types';

export const useGetDevicesColumns = (
  rowSelectionModel: GridRowSelectionModel,
  rowHoveringModel: string,
  setAssetImagesModal: () => void,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): GridColDef[] => {
  const theme = useTheme();
  const navigator = React.useContext(NavigationContext);
  const columns = useSelector(selectColumns);

  const [columnsConfig, setColumnsConfig] = React.useState<GridColDef[]>([]);

  const mapping: (AssetColumnKey | 'radio')[] = columns.map(
    (column) => column.key,
  );
  mapping.unshift('radio');
  const atLeastOneColumnOn = some(columns, (column) => column.active);

  React.useEffect(() => {
    const config: Record<AssetColumnKey | 'radio', GridColDef> = {
      radio: {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderHeader: () => <></>,
        filterable: false,
        disableReorder: true,
        renderCell(params) {
          const isRowSelected = rowSelectionModel.length
            ? params.id === rowSelectionModel[0]
            : false;
          const isHovered = params.id.toString() === rowHoveringModel;
          const showRadioButton = isRowSelected || isHovered;
          return (
            <Radio
              id={`device_id_${params.id}_radio_button`}
              sx={{
                visibility: showRadioButton ? 'visible' : 'hidden',
              }}
              checked={isRowSelected}
            />
          );
        },
      },
      'asset-number': {
        field: 'createdAt', // Sorting by createdAt because asset-number is a string sort
        headerName: 'Asset Number',
        minWidth: 150,
        filterable: false,
        valueGetter(params) {
          return params.row.assetNumber;
        },
      },
      type: {
        field: 'type',
        headerName: 'Type',
        minWidth: 100,
        type: 'singleSelect',
        sortable: false,
        filterable: false,
        valueGetter(params) {
          return capitalize(params?.row?.assetType?.name || '');
        },
        renderCell: (params) => <div>{params.value}</div>,
        valueOptions: Object.entries(AssetTypeMapping).map(
          ([value, label]) => ({
            value,
            label,
          }),
        ),
      },
      make: {
        field: 'make',
        headerName: 'Make',
        minWidth: 120,
        filterable: false,
      },
      model: {
        field: 'model',
        headerName: 'Model',
        minWidth: 200,
        filterable: false,
      },
      condition: {
        field: 'cosmeticCondition',
        headerName: 'Condition',
        minWidth: 100,
        type: 'singleSelect',
        filterable: false,
        renderCell: (params) => {
          const { assetImages } = params.row;
          const hasImages = assetImages?.length > 0;

          if (hasImages) {
            return (
              <Tooltip title="View asset images" arrow>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="8px"
                  onClick={setAssetImagesModal}
                >
                  {params.value} <AssetImageChip assetImages={assetImages} />
                </Stack>
              </Tooltip>
            );
          }

          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap="8px"
            >
              {params.value}
            </Stack>
          );
        },
        valueOptions: Object.entries(AssetConditionMapping).map(
          ([value, label]) => ({
            value,
            label,
          }),
        ),
      },
      'keyboard-configuration': {
        field: 'keyboard',
        headerName: 'Keyboard configuration',
        minWidth: 100,
        filterable: false,
        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Keyboard configuration
            </Typography>
          );
        },
      },
      'depot-location': {
        field: 'depotLocation',
        headerName: 'Depot location',
        type: 'singleSelect',
        sortable: false,
        filterable: false,
        minWidth: 200,
        renderCell: (params) => {
          return <div>{params.row.depot?.name}</div>;
        },
        valueOptions: Object.entries(Depot).map(([_, label]) => ({
          value: label, // The label is the value
          label,
        })),
      },
      'purchase-date': {
        field: 'purchaseDate',
        headerName: 'Purchase date',
        sortable: true,
        filterable: false,
        minWidth: 200,
        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
                wordBreak: 'break-all',
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              Purchase date
              <span
                style={{
                  fontSize: 'inherit',
                  paddingLeft: '5px',
                  verticalAlign: 'middle',
                }}
              >
                {'  '}
                <Tooltip
                  title={PURCHASE_DATE_COLUMN_TOOLTIP_COPY}
                  placement="bottom"
                  arrow
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    htmlColor="rgba(0, 0, 0, .4)"
                    sx={{ position: 'relative', top: '2px' }}
                  />
                </Tooltip>
              </span>
            </Typography>
          );
        },
        renderCell: (params) => {
          let purchaseDateDisplay = '-';

          if (params.row.purchaseDate) {
            const cutDate = params.row.purchaseDate.split('T')[0];
            purchaseDateDisplay = formatDate(cutDate, 'MM/DD/YYYY');
          }

          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap="5px"
            >
              <Typography
                sx={{
                  whiteSpace: 'normal',
                  fontSize: '14px',
                  lineHeight: '18px',
                  wordBreak: 'break-all',
                  position: 'relative',
                }}
              >
                {purchaseDateDisplay}
              </Typography>
              {params.row.isPurchaseDateVerified ? (
                <VerifiedIcon color="primary" />
              ) : null}
            </Stack>
          );
        },
      },
      'updated-at': {
        field: 'updatedAt',
        headerName: 'Last updated',
        minWidth: 150,
        filterable: false,
        renderCell: (params: any) => {
          const { updatedAt } = params.row;

          const localTime = getEasternUSTime(updatedAt);
          const updatedAtIsToday = dayUTCIsToday(updatedAt);
          const formattedDate = dateUTCStringToMediumDate(updatedAt);
          const displayDateUpdated = updatedAtIsToday ? 'Today' : formattedDate;

          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                wordBreak: 'break-all',
                position: 'relative',
              }}
            >
              <span>{displayDateUpdated}</span>
              <br />
              <span>{localTime}</span>
            </Typography>
          );
        },
      },
      status: {
        field: 'status',
        headerName: 'Status',
        minWidth: 200,
        filterable: false,
        valueGetter(params) {
          return capitalize(
            params?.row?.status?.split('MONO_')[1]?.split('_').join(' ') || '',
          );
        },
        renderCell: (params) => {
          return (
            <AssetStatusPill
              status={
                params?.row?.status as unknown as AssetStatusSelfDescriptive
              }
              pillView={AssetStatusPillViews.TABLE}
            />
          );
        },
        type: 'singleSelect',
        valueOptions: Object.entries(AssetStatusMapping).map(
          ([value, label]) => ({
            value,
            label,
          }),
        ),
      },
      assignee: {
        field: 'assignee',
        headerName: 'Assignee',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              {params.value?.firstName} {params.value?.lastName}
            </div>
          );
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Assignee
            </Typography>
          );
        },
      },
      color: {
        field: 'color',
        headerName: 'Color',
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Color
            </Typography>
          );
        },
      },
      'date-of-last-check-in': {
        field: 'dateOfLastCheckIn',
        headerName: 'Date of last checkin',
        minWidth: 200,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          let dateOfLastCheckIn = '-';
          if (params.value) {
            const cutDate = params.value.split('T')[0];
            dateOfLastCheckIn = formatDate(cutDate, 'MM/DD/YYYY');
          }
          return <div>{dateOfLastCheckIn}</div>;
        },

        valueGetter(params) {
          return params.row.inventoryLog?.dateOfLastCheckIn || '';
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Date of last check in
            </Typography>
          );
        },
      },
      'date-of-last-check-out': {
        field: 'dateOfLastCheckOut',
        headerName: 'Date of last checkout',
        minWidth: 200,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          let dateOfLastCheckOut = '-';
          if (params.value) {
            const cutDate = params.value.split('T')[0];
            dateOfLastCheckOut = formatDate(cutDate, 'MM/DD/YYYY');
          }
          return <div>{dateOfLastCheckOut}</div>;
        },

        valueGetter(params) {
          return params.row.inventoryLog?.dateOfLastCheckOut || '';
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Date of last check out
            </Typography>
          );
        },
      },
      'device-issue': {
        field: 'deviceAssessment',
        headerName: 'Device issue',
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Device issue
            </Typography>
          );
        },
      },
      'display-size': {
        field: 'displaySize',
        headerName: 'Device issue',
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Display size
            </Typography>
          );
        },
      },
      memory: {
        field: 'memory',
        headerName: 'Memory',
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Memory
            </Typography>
          );
        },
      },

      'personal-email': {
        field: 'personalEmail',
        headerName: 'Personal email',
        minWidth: 200,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        valueGetter: (params) => {
          return params.row.assignee?.personalEmail || '';
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Personal email
            </Typography>
          );
        },
      },

      processor: {
        field: 'processor',
        headerName: 'Processor',
        minWidth: 200,
        filterable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Processor
            </Typography>
          );
        },
      },

      'serial-number': {
        field: 'serialNumber',
        headerName: 'Serial number',
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Serial number
            </Typography>
          );
        },
      },

      storage: {
        field: 'storage',
        headerName: 'Storage',
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Storage
            </Typography>
          );
        },
      },
      'work-email': {
        field: 'workEmail',
        headerName: 'Work email',
        minWidth: 200,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },

        valueGetter: (params) => {
          return params.row.assignee?.workEmail || '';
        },

        renderHeader: () => {
          return (
            <Typography
              sx={{
                whiteSpace: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              Work Email
            </Typography>
          );
        },
      },
      notes: {
        field: 'details',
        disableReorder: true,
        sortable: false,
        filterable: false,
        headerName: 'Notes',
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
          const isRowHovered = params.id === rowHoveringModel;
          const customerNoteTextYes = params.row.customerNote;
          const handleCellClick = () => {
            const pathToAssetDetails = navigator.constructPathToAssetDetails(
              params.id.toString(),
            );

            window.open(pathToAssetDetails, '_blank');
          };

          return (
            <span
              onClick={handleCellClick}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                paddingLeft: '5px',
                paddingRight: '15px',
                borderRadius: '20px',
                background:
                  customerNoteTextYes && !isRowHovered
                    ? theme.palette.colors.grayE0
                    : 'none',
              }}
            >
              {isRowHovered ? (
                <>
                  <InfoOutlinedIcon
                    fontSize="small"
                    style={{ marginRight: '4px', cursor: 'pointer' }}
                  />
                  <span
                    className="details-text"
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      borderBottom: '1.5px solid rgba(0, 0, 0, 0.4)',
                    }}
                  >
                    <Tooltip title="Asset Details" arrow>
                      <Typography
                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      >
                        Details
                      </Typography>
                    </Tooltip>
                  </span>
                </>
              ) : (
                <>
                  <IconButton
                    size="small"
                    sx={{
                      '&&:hover': {
                        backgroundColor: 'colors.merino',
                      },
                    }}
                  >
                    <StickyNote2OutlinedIcon
                      style={{
                        color: theme.palette.colors.gray94,
                      }}
                    />
                  </IconButton>
                  <span
                    style={{
                      color: theme.palette.colors.gray75,
                      fontWeight: 'bold',
                      marginLeft: '4px',
                    }}
                  >
                    {customerNoteTextYes ? '1' : '0'}
                  </span>
                </>
              )}
            </span>
          );
        },
      },
    };
    const columnsConfig: GridColDef[] = mapping.map((key) => {
      return config[key];
    });
    setColumnsConfig(columnsConfig);
  }, [columns, atLeastOneColumnOn, rowSelectionModel, rowHoveringModel]);
  return columnsConfig;
};
