import { RootState } from 'store/index';

export const selectOrders = (state: RootState) => {
  return state.home.orders.orders;
};

export const selectOrdersCount = (state: RootState) => {
  return state.home.orders.count;
};

export const selectOrdersSkip = (state: RootState) => {
  return state.home.orders.skip;
};

export const selectOrdersTake = (state: RootState) => {
  return state.home.orders.take;
};

export const selectOrdersLoading = (state: RootState) => {
  return state.home.orders.loading;
};

export const selectHoursSaved = (state: RootState) => {
  return state.home.orders.hoursSaved;
};

export const selectHoursSavedLoading = (state: RootState) => {
  return state.home.orders.hoursSavedLoading;
};
