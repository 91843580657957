import { FIND_ASSETS_BY_STATUS_TRANSITION_QUERY } from '../panel/assets/AssetsPanel/queries';
import { ASSETS_LOOK_BACK_DATE } from '../panel/assets/AssetsPanel/constants';
import { Organization } from 'types';
import { FIND_ORDERS_BY_STATUS_TRANSITION_QUERY } from '../panel/orders/OrdersPanel/queries';
import { ORDERS_LOOK_BACK_DATE } from '../panel/orders/OrdersPanel/constants';

export const getInitialOrdersVariables = (organization: Organization) => {
  return {
    append: false,
    query: FIND_ORDERS_BY_STATUS_TRANSITION_QUERY,
    variables: {
      where: {
        deletedAt: { equals: null },
        createdAt: {
          gt: ORDERS_LOOK_BACK_DATE,
        },
        order: {
          is: {
            organizationId: {
              equals: organization?.id,
            },
          },
        },
      },
      orderBy: [{ createdAt: 'desc' }],
      skip: 0,
      take: 12,
    },
  };
};

export const getInitialAssetsVariables = (organization: Organization) => {
  return {
    append: false,
    query: FIND_ASSETS_BY_STATUS_TRANSITION_QUERY,
    variables: {
      where: {
        deletedAt: { equals: null },
        createdAt: {
          gt: ASSETS_LOOK_BACK_DATE,
        },
        asset: {
          is: {
            organizationId: {
              equals: organization?.id,
            },
          },
        },
      },
      orderBy: [{ createdAt: 'desc' }],
      skip: 0,
      take: 12,
    },
  };
};
