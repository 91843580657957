import React from 'react';
import { SVGProps } from '../../types';

const ExpiredPendingReturnIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F38A34" />
      <path
        d="M11.3335 13.9987H12.6668V15.332H11.3335V13.9987ZM11.3335 8.66536H12.6668V12.6654H11.3335V8.66536ZM11.9935 5.33203C8.3135 5.33203 5.3335 8.3187 5.3335 11.9987C5.3335 15.6787 8.3135 18.6654 11.9935 18.6654C15.6802 18.6654 18.6668 15.6787 18.6668 11.9987C18.6668 8.3187 15.6802 5.33203 11.9935 5.33203ZM12.0002 17.332C9.0535 17.332 6.66683 14.9454 6.66683 11.9987C6.66683 9.05203 9.0535 6.66536 12.0002 6.66536C14.9468 6.66536 17.3335 9.05203 17.3335 11.9987C17.3335 14.9454 14.9468 17.332 12.0002 17.332Z"
        fill="white"
      />
    </svg>
  );
};

export default ExpiredPendingReturnIcon;
