import { BORDER_COLOR } from '../../../styles';
import { FONT_SIZE } from '../../../styles';
import { PANEL_CONTAINER_WIDTH } from '../../TabsContainer/styles';

export const PANEL_ITEM_WIDTH = PANEL_CONTAINER_WIDTH - 42;

export const PanelItemSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  borderBottom: `1px solid ${BORDER_COLOR}`,
  minHeight: '66px',
  maxHeight: '66px',
  height: '66px',
  width: `${PANEL_ITEM_WIDTH}px`,
  pt: '12px',
  pb: '12px',
  pl: '8px',
  pr: '8px',
  fontSize: `${FONT_SIZE}px`,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'rgba(250, 250, 250, 1)',
    '& svg': {
      visibility: 'visible',
    },
  },
};

export const PanelLeftSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  width: '8%',
};

export const PanelMiddleSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
};

export const PanelCopy = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
};

export const PanelName = {
  display: 'inline-block',
  maxWidth: '180px',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
};
export const PanelAssetType = {};
export const PanelAssetStatus = {};

export const PanelStatus = {
  fontWeight: '600',
  display: 'flex',
  flexDirection: 'row',
};

export const PanelDate = {
  color: 'rgba(96, 96, 93, 1)',
};
