import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './constants';

import {
  getAllOrdersPendingReducer,
  getAllOrdersFulfilledReducer,
  getAllOrdersRejectedReducer,
  getOpenOrdersPendingReducer,
  getOpenOrdersFulfilledReducer,
  getOpenOrdersRejectedReducer,
  getInTransitOrdersPendingReducer,
  getInTransitOrdersFulfilledReducer,
  getInTransitOrdersRejectedReducer,
  getCompleteOrdersPendingReducer,
  getCompleteOrdersFulfilledReducer,
  getCompleteOrdersRejectedReducer,
  getNeedsAttentionOrdersPendingReducer,
  getNeedsAttentionOrdersFulfilledReducer,
  getNeedsAttentionOrdersRejectedReducer,
  getExpiringSoonOrdersPendingReducer,
  getExpiringSoonOrdersFulfilledReducer,
  getExpiringSoonOrdersRejectedReducer,
  // Assets
  getAllAssetsPendingReducer,
  getAllAssetsFulfilledReducer,
  getAllAssetsRejectedReducer,
  getInInventoryAssetsPendingReducer,
  getInInventoryAssetsFulfilledReducer,
  getInInventoryAssetsRejectedReducer,
  getReadyToDeployAssetsPendingReducer,
  getReadyToDeployAssetsFulfilledReducer,
  getReadyToDeployAssetsRejectedReducer,
  getNeedsAttentionAssetsPendingReducer,
  getNeedsAttentionAssetsFulfilledReducer,
  getNeedsAttentionAssetsRejectedReducer,
  getAllocatedAssetsPendingReducer,
  getAllocatedAssetsFulfilledReducer,
  getAllocatedAssetsRejectedReducer,
} from './reducers';
import {
  getAllOrdersTotals,
  getOpenOrdersTotals,
  getInTransitOrdersTotals,
  getCompleteOrdersTotals,
  getNeedsAttentionOrdersTotals,
  getExpiringSoonOrdersTotals,
  getAllocatedAssetsTotals,
  getInInventoryAssetsTotals,
  getReadyToDeployAssetsTotals,
  getNeedsAttentionAssetsTotals,
  getAllAssetsTotals,
} from './actions';

export const totalsSlice = createSlice({
  name: 'totalsSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    resetStateReducer: () => INITIAL_STATE(),
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrdersTotals.pending, getAllOrdersPendingReducer);
    builder.addCase(getAllOrdersTotals.fulfilled, getAllOrdersFulfilledReducer);
    builder.addCase(getAllOrdersTotals.rejected, getAllOrdersRejectedReducer);

    builder.addCase(getOpenOrdersTotals.pending, getOpenOrdersPendingReducer);
    builder.addCase(
      getOpenOrdersTotals.fulfilled,
      getOpenOrdersFulfilledReducer
    );
    builder.addCase(getOpenOrdersTotals.rejected, getOpenOrdersRejectedReducer);

    builder.addCase(
      getInTransitOrdersTotals.pending,
      getInTransitOrdersPendingReducer
    );
    builder.addCase(
      getInTransitOrdersTotals.fulfilled,
      getInTransitOrdersFulfilledReducer
    );
    builder.addCase(
      getInTransitOrdersTotals.rejected,
      getInTransitOrdersRejectedReducer
    );

    builder.addCase(
      getCompleteOrdersTotals.pending,
      getCompleteOrdersPendingReducer
    );
    builder.addCase(
      getCompleteOrdersTotals.fulfilled,
      getCompleteOrdersFulfilledReducer
    );
    builder.addCase(
      getCompleteOrdersTotals.rejected,
      getCompleteOrdersRejectedReducer
    );

    builder.addCase(
      getNeedsAttentionOrdersTotals.pending,
      getNeedsAttentionOrdersPendingReducer
    );
    builder.addCase(
      getNeedsAttentionOrdersTotals.fulfilled,
      getNeedsAttentionOrdersFulfilledReducer
    );
    builder.addCase(
      getNeedsAttentionOrdersTotals.rejected,
      getNeedsAttentionOrdersRejectedReducer
    );

    builder.addCase(
      getExpiringSoonOrdersTotals.pending,
      getExpiringSoonOrdersPendingReducer
    );
    builder.addCase(
      getExpiringSoonOrdersTotals.fulfilled,
      getExpiringSoonOrdersFulfilledReducer
    );
    builder.addCase(
      getExpiringSoonOrdersTotals.rejected,
      getExpiringSoonOrdersRejectedReducer
    );

    // Assets

    builder.addCase(getAllAssetsTotals.pending, getAllAssetsPendingReducer);
    builder.addCase(getAllAssetsTotals.fulfilled, getAllAssetsFulfilledReducer);
    builder.addCase(getAllAssetsTotals.rejected, getAllAssetsRejectedReducer);

    builder.addCase(
      getAllocatedAssetsTotals.pending,
      getAllocatedAssetsPendingReducer
    );
    builder.addCase(
      getAllocatedAssetsTotals.fulfilled,
      getAllocatedAssetsFulfilledReducer
    );
    builder.addCase(
      getAllocatedAssetsTotals.rejected,
      getAllocatedAssetsRejectedReducer
    );

    builder.addCase(
      getInInventoryAssetsTotals.pending,
      getInInventoryAssetsPendingReducer
    );
    builder.addCase(
      getInInventoryAssetsTotals.fulfilled,
      getInInventoryAssetsFulfilledReducer
    );
    builder.addCase(
      getInInventoryAssetsTotals.rejected,
      getInInventoryAssetsRejectedReducer
    );

    builder.addCase(
      getReadyToDeployAssetsTotals.pending,
      getReadyToDeployAssetsPendingReducer
    );
    builder.addCase(
      getReadyToDeployAssetsTotals.fulfilled,
      getReadyToDeployAssetsFulfilledReducer
    );
    builder.addCase(
      getReadyToDeployAssetsTotals.rejected,
      getReadyToDeployAssetsRejectedReducer
    );

    builder.addCase(
      getNeedsAttentionAssetsTotals.pending,
      getNeedsAttentionAssetsPendingReducer
    );
    builder.addCase(
      getNeedsAttentionAssetsTotals.fulfilled,
      getNeedsAttentionAssetsFulfilledReducer
    );
    builder.addCase(
      getNeedsAttentionAssetsTotals.rejected,
      getNeedsAttentionAssetsRejectedReducer
    );
  },
});

const { resetStateReducer: resetState } = totalsSlice.actions;

export { resetState };
