import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { TableInitialState } from './types';

export const setAssetTablePaginationModelReducer = (
  state: WritableDraft<TableInitialState>,
  action: PayloadAction<GridPaginationModel>
) => {
  state.paginationModel = action.payload;
};
