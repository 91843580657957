import { createSlice } from '@reduxjs/toolkit';
import {
  downloadAssetErrors,
  downloadAssetTemplate,
  getAssetsCSV,
  getAssetsCSVDirect,
  getAssetUploadCollaborator,
  searchAssetUploads,
  uploadAssets,
} from './actions';
import { AssetsCSVInitialState } from './types';

import {
  getAssetsPendingReducer,
  getAssetsFulfilledReducer,
  getAssetsRejectedReducer,
  getAssetsDirectPendingReducer,
  getAssetsDirectFulfilledReducer,
  getAssetsDirectRejectedReducer,
  setAssetsCSVDownloadURLReducer,
  uploadAssetsRejectedReducer,
  getAssetTemplateRejectedReducer,
  getAssetTemplatePendingReducer,
  getAssetTemplateFulfilledReducer,
  getAssetErrorsRejectedReducer,
  getAssetErrorsPendingReducer,
  getAssetErrorsFulfilledReducer,
  searchAssetUploadsFulfilledReducer,
  setUploadViewedReducer,
  setHistoricUploadQueryReducer,
  setSelectedUploadStateReducer,
  getAssetUploadCollaboratorFulfilledReducer,
  setUploadsReducer,
  setPopoverOpenStateReducer,
  setPopoverFileReducer,
  uploadAssetsPendingReducer,
  uploadAssetsFulfilledReducer,
  clearShowNewUploadNotificationReducer,
  togglePopoverHistoryLookbackDropdownReducer,
  resetHistoricUploadQueryReducer,
  resetCSVDownloadStateReducer,
  resetUploadTemplateDownloadStateReducer,
  resetUploadErrorsDownloadStateReducer,
  resetUploadFileStateReducer,
} from './reducers';

const lookbackConfig = process.env.REACT_APP_ASSET_UPLOADS_SEARCH_FROM_BASE || '3';
const defaultLookback = parseInt(lookbackConfig, 10);

export const initialState: AssetsCSVInitialState = {
  loadingState: 'idle',
  url: '',
  data: '',
  errorMessage: '',
  upload: {
    uploads: [],
    newUploadQuery: {
      lookback: defaultLookback,
    },
    historicUploadQuery: {
      lookback: 14,
      limit: 5,
      offset: 0,
      count: 0,
    },
    selectedUpload: {
      errorsData: '',
      errorsLoadingState: 'idle',
    },
    templateData: '',
    templateLoadingState: 'idle',
    fileUploadLoadingState: 'idle',
    showNewUploadNotification: false,
    errorMessage: '',
    popover: {
      open: false,
      file: null,
      history: {
        lookbackDropdownOpen: false,
      },
    },
  },
};

export const assetsCSVSlice = createSlice({
  name: 'assetsCSVSlice',
  initialState,
  reducers: {
    setUploadsReducer,
    setAssetsCSVDownloadURLReducer,
    setUploadViewedReducer,
    setHistoricUploadQueryReducer,
    resetHistoricUploadQueryReducer,
    setSelectedUploadStateReducer,
    setPopoverOpenStateReducer,
    setPopoverFileReducer,
    clearShowNewUploadNotificationReducer,
    togglePopoverHistoryLookbackDropdownReducer,
    resetCSVDownloadStateReducer,
    resetUploadTemplateDownloadStateReducer,
    resetUploadErrorsDownloadStateReducer,
    resetUploadFileStateReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(getAssetsCSV.pending, getAssetsPendingReducer);
    builder.addCase(getAssetsCSV.fulfilled, getAssetsFulfilledReducer);
    builder.addCase(getAssetsCSV.rejected, getAssetsRejectedReducer);
    // Direct
    builder.addCase(getAssetsCSVDirect.pending, getAssetsDirectPendingReducer);
    builder.addCase(
      getAssetsCSVDirect.fulfilled,
      getAssetsDirectFulfilledReducer
    );
    builder.addCase(
      getAssetsCSVDirect.rejected,
      getAssetsDirectRejectedReducer
    );
    builder.addCase(uploadAssets.rejected, uploadAssetsRejectedReducer);
    builder.addCase(uploadAssets.pending, uploadAssetsPendingReducer);
    builder.addCase(uploadAssets.fulfilled, uploadAssetsFulfilledReducer);
    builder.addCase(
      downloadAssetTemplate.rejected,
      getAssetTemplateRejectedReducer
    );
    builder.addCase(
      downloadAssetTemplate.pending,
      getAssetTemplatePendingReducer
    );
    builder.addCase(
      downloadAssetTemplate.fulfilled,
      getAssetTemplateFulfilledReducer
    );
    builder.addCase(
      downloadAssetErrors.rejected,
      getAssetErrorsRejectedReducer
    );
    builder.addCase(downloadAssetErrors.pending, getAssetErrorsPendingReducer);
    builder.addCase(
      downloadAssetErrors.fulfilled,
      getAssetErrorsFulfilledReducer
    );
    builder.addCase(
      searchAssetUploads.fulfilled,
      searchAssetUploadsFulfilledReducer
    );
    builder.addCase(
      getAssetUploadCollaborator.fulfilled,
      getAssetUploadCollaboratorFulfilledReducer
    );
  },
});

export const {
  resetCSVDownloadStateReducer: resetCSVDownloadState,
  setUploadsReducer: setUploads,
  setAssetsCSVDownloadURLReducer: setAssetsCSVDownloadURL,
  setUploadViewedReducer: setUploadViewed,
  setHistoricUploadQueryReducer: setHistoricUploadQuery,
  resetHistoricUploadQueryReducer: resetHistoricUploadQuery,
  setSelectedUploadStateReducer: setSelectedUploadState,
  setPopoverOpenStateReducer: setPopoverOpenState,
  setPopoverFileReducer: setPopoverFile,
  clearShowNewUploadNotificationReducer: clearShowNewUploadNotification,
  togglePopoverHistoryLookbackDropdownReducer:
    togglePopoverHistoryLookbackDropdown,
  resetUploadTemplateDownloadStateReducer: resetUploadTemplateDownloadState,
  resetUploadErrorsDownloadStateReducer: resetUploadErrorsDownloadState,
  resetUploadFileStateReducer: resetUploadFileState,
} = assetsCSVSlice.actions;
