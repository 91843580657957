import { useSelector } from 'react-redux';

import { transformOrder } from './utils';
import { getOrders } from 'store/slices/home/orders/actions';

import { ICONS, ORDERS_LOOK_BACK_DATE } from './constants';
import { FIND_ORDERS_BY_STATUS_TRANSITION_QUERY } from './queries';
import {
  selectOrders,
  selectOrdersTake,
  selectOrdersSkip,
  selectOrdersLoading,
  selectOrdersCount,
} from 'store/slices/home/orders/selectors';
import { PanelProps } from '../../Panel/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';

export const useGetOrdersPanelConfig = () => {
  const orders = useSelector(selectOrders);
  const take = useSelector(selectOrdersTake);
  const skip = useSelector(selectOrdersSkip);
  const loading = useSelector(selectOrdersLoading);
  const count = useSelector(selectOrdersCount);
  const organization = useGetUserOrganization();
  const transformedOrders = orders.map(transformOrder);
  const type: 'assets' | 'orders' = 'orders';

  const config: PanelProps = {
    data: transformedOrders,
    type,
    icons: ICONS,
    loading,
    apiCount: count,
    getData: getOrders,
    getDataParams: {
      append: true,
      query: FIND_ORDERS_BY_STATUS_TRANSITION_QUERY,
      variables: {
        where: {
          deletedAt: { equals: null },
          createdAt: {
            gt: ORDERS_LOOK_BACK_DATE,
          },
          order: {
            is: {
              organizationId: {
                equals: organization?.id,
              },
            },
          },
        },
        orderBy: [{ createdAt: 'desc' }],
        skip,
        take,
      },
    },
  };
  return config;
};
