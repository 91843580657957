import { nanoid } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { FilterEntry, BaseFilterEntry, FilterStateKey } from 'store/shared/types';
import FilterDrawerContentProps from 'components/complex_filter/FilterDrawerContent/types';
import {
  DropDownOption,
  UpsertOptionsHOF,
  FilterLayoutItem,
} from 'components/complex_filter/types';
import FilterChip from 'components/complex_filter/FilterChip';
import { getKebabCase } from 'services/format';
import TypeaheadProps from 'components/complex_filter/fields/Typeahead/types';
import DropDownProps from 'components/complex_filter/fields/DropDown/types';
import FullTextProps from 'components/complex_filter/fields/FullText/types';
import CheckBoxFieldProps from 'components/complex_filter/fields/CheckBoxField/types';

export const isMUIDateValid = (
  mDate: Moment | null,
  earliestYear: number = 2019,
): boolean => {
  if (mDate === null) return true;

  return (
    mDate.format() === 'Invalid date' ||
    Number.isNaN(mDate.year()) ||
    mDate.year() <= earliestYear
  );
};

export const getFilterDateValue = (
  mDate: Moment | null,
  options: {
    earliestYear: number;
    dateFormat: string;
  } = {
    earliestYear: 1970,
    dateFormat: 'MM/DD/YYYY',
  },
): string | null => {
  if (mDate === null) return null;

  const isInvalid =
    mDate.format() === 'Invalid date' ||
    Number.isNaN(mDate.year()) ||
    mDate.year() <= options.earliestYear;

  return isInvalid ? null : mDate.format(options.dateFormat);
};

export const getRangeDateValue = (
  mDate: Moment | null,
  options: {
    earliestYear: number;
    dateFormat: string;
  } = {
    earliestYear: 1970,
    dateFormat: 'MM/DD/YYYY',
  },
): Moment | string | null => {
  if (mDate === null) return null;

  const isInvalid =
    mDate.format() === 'Invalid date' ||
    Number.isNaN(mDate.year()) ||
    mDate.year() <= options.earliestYear;

  return isInvalid ? mDate : mDate.format(options.dateFormat);
};

export const checkValidDate = (
  mDate: Moment | null,
  options: {
    earliestYear: number;
  } = {
    earliestYear: 1970,
  },
): boolean => {
  if (mDate === null) return false;

  return mDate.format() === 'Invalid date' ||
    Number.isNaN(mDate.year()) ||
    mDate.year() <= options.earliestYear
    ? false
    : true;
};

export const renderCommonChipHOC =
  (handleDelete: (id: string, key: string) => void) =>
  (contents: FilterEntry) => {
    const { displayName, id, selectDate, key: filterKey } = contents;
    const key = `${id}--${selectDate.toString()}`;
    const onDelete = () => handleDelete(id, filterKey);

    return FilterChip({ key, label: displayName, onDelete });
  };

export const upsertDropdownOptionsHOF: UpsertOptionsHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
    options: DropDownOption[],
  ) =>
  () => {
    const ids = options.map((option) => nanoid());
    const upsertOptions: BaseFilterEntry[] = options.map((option, index) => ({
      id: `${key}-${ids[index]}`,
      displayName: option.displayName,
      value: option.value,
      key,
    }));

    upsertEntriesCallback(key, upsertOptions);
  };

export const upsertDummyOptionsHOF =
  (
    key: string,
    upsertCallback: (key: string, options: BaseFilterEntry[]) => void,
    options: DropDownOption[],
  ) =>
  (data: any): void => {};

export const getDropDownEntries = (
  key: string,
  options: DropDownOption[],
): BaseFilterEntry[] => {
  if (!options.length) return [];

  const ids = options.map((option) => nanoid());
  return options.map((option, index) => ({
    id: `${key}-${ids[index]}`,
    displayName: option.displayName,
    value: option.value,
    key,
  }));
};

export const getCommonQueryVariables = (rawInput: string) => ({
  variables: rawInput,
});

export const getCommonQueryVariablesHOF =
  (field: string) =>
  (rawInput: string, getFieldValue?: (val: string) => string) => ({
    variables: {
      [field]: getFieldValue ? getFieldValue(rawInput) : rawInput,
    },
  });

export const getBaseSelectorPrefix = (
  filterSubTree: FilterStateKey,
  fieldType: string,
  filterKey: string,
) =>
  getKebabCase([filterSubTree, 'filter', 'field-type', fieldType, filterKey]);

export const getTypeaheadProps = (
  layoutItem: FilterLayoutItem,
  filterDrawerProps: FilterDrawerContentProps,
): TypeaheadProps => {
  const { fieldType, filterKey } = layoutItem;
  const {
    filterSubTree,
    typeAheadHandlersConfig,
    selectAvailableEntries,
    selectChosenEntries,
    onOpenInput,
    onClearSelectionsByKey,
    onCloseInput,
    selectExpandedFilter,
    onSearchChangeByKey,
    selectSearchInputByKey,
    onBlurClearByKey,
    onBatchUpsertFilterEntries,
    onTypeaheadOptionsChange,
    selectIsFilterDisabled,
  } = filterDrawerProps;

  const baseSelectorPrefix = getBaseSelectorPrefix(
    filterSubTree,
    fieldType as string,
    filterKey,
  );

  return {
    baseSelectorPrefix,
    selectAvailableEntries,
    selectChosenEntries,
    onOpenInput,
    onClearSelectionsByKey,
    onCloseInput,
    selectExpandedFilter,
    onSearchChangeByKey,
    onBlurClearByKey,
    onBatchUpsertFilterEntries,
    onTypeaheadOptionsChange,
    selectSearchInputByKey,
    selectIsFilterDisabled,
    filterSubTree,
    ...typeAheadHandlersConfig[layoutItem.filterKey],
    ...layoutItem,
  };
};

export const getFullTextProps = (
  layoutItem: FilterLayoutItem,
  filterDrawerProps: FilterDrawerContentProps,
): FullTextProps => {
  const { fieldType, filterKey } = layoutItem;
  const {
    filterSubTree,
    fullTextHandlersConfig,
    selectAvailableEntries,
    selectChosenEntries,
    onOpenInput,
    onClearSelectionsByKey,
    onCloseInput,
    selectExpandedFilter,
    onSearchChangeByKey,
    selectSearchInputByKey,
    onBlurClearByKey,
    onBatchUpsertFilterEntries,
    onFullTextOptionsChange,
    selectIsFilterDisabled,
  } = filterDrawerProps;

  const baseSelectorPrefix = getBaseSelectorPrefix(
    filterSubTree,
    fieldType as string,
    filterKey,
  );

  return {
    baseSelectorPrefix,
    selectAvailableEntries,
    selectChosenEntries,
    onOpenInput,
    onClearSelectionsByKey,
    onCloseInput,
    selectExpandedFilter,
    onSearchChangeByKey,
    onBlurClearByKey,
    onBatchUpsertFilterEntries,
    onFullTextOptionsChange,
    selectSearchInputByKey,
    selectIsFilterDisabled,
    filterSubTree,
    ...fullTextHandlersConfig[layoutItem.filterKey],
    ...layoutItem,
  };
};

export const getDropDownProps = (
  layoutItem: FilterLayoutItem,
  filterDrawerProps: FilterDrawerContentProps,
): DropDownProps => {
  const { fieldType, filterKey } = layoutItem;
  const {
    filterSubTree,
    dropDownHandlersConfig,
    selectExpandedFilter,
    onClearSelectionsByKey,
    selectAvailableEntries,
    selectChosenEntries,
    onBatchUpsertFilterEntries,
    loadAsyncDropdownOptions,
    loadDropDownOptions,
    onSelectDropdown,
    onOpenInput,
    onCloseInput,
    clearAllByKey,
    selectIsFilterDisabled,
  } = filterDrawerProps;

  const baseSelectorPrefix = getBaseSelectorPrefix(
    filterSubTree,
    fieldType as string,
    filterKey,
  );

  return {
    filterSubTree,
    baseSelectorPrefix,
    selectExpandedFilter,
    onClearSelectionsByKey,
    selectAvailableEntries,
    selectChosenEntries,
    onBatchUpsertFilterEntries,
    loadAsyncDropdownOptions,
    loadDropDownOptions,
    onSelectDropdown,
    onOpenInput,
    onCloseInput,
    clearAllByKey,
    selectIsFilterDisabled,
    dropdownHandlersConfig: dropDownHandlersConfig,
    ...layoutItem,
  };
};

export const getDateTimeProps = (
  layoutItem: FilterLayoutItem,
  filterDrawerProps: FilterDrawerContentProps,
) => {
  const { fieldType, filterKey } = layoutItem;
  const {
    filterSubTree,
    onDateModeChange,
    selectDateFilterMode,
    onReplaceDateFilterEntry,
    onOpenInput,
    onCloseInput,
    selectFilterOpen,
    selectDateRange,
    selectPickedDate,
    setDateRangeInputChange,
    onRangeCalendarChange,
    onAfterDateCalendarChange,
    onBeforeDateCalendarChange,
    onClearDateByModeKey,
    onSetBeforeDateInputChange,
    onSetAfterDateInputChange,
    onResetFilterByKey,
  } = filterDrawerProps;

  return {
    filterSubTree,
    onDateModeChange,
    selectDateFilterMode,
    onReplaceDateFilterEntry,
    onOpenInput,
    onCloseInput,
    selectFilterOpen,
    selectDateRange,
    selectPickedDate,
    setDateRangeInputChange,
    onRangeCalendarChange,
    onAfterDateCalendarChange,
    onBeforeDateCalendarChange,
    onClearDateByModeKey,
    onSetBeforeDateInputChange,
    onSetAfterDateInputChange,
    onResetFilterByKey,
    ...layoutItem,
  };
};

export const getCheckBoxFieldProps = (
  layoutItem: FilterLayoutItem,
  filterDrawerProps: FilterDrawerContentProps,
): CheckBoxFieldProps => {
  const { fieldType, filterKey } = layoutItem;
  const {
    filterSubTree,
    fullTextHandlersConfig,
    selectAvailableEntries,
    selectIsFilterDisabled,
    checkboxHandlersConfig,
    onCheckBoxChange,
  } = filterDrawerProps;

  const baseSelectorPrefix = getBaseSelectorPrefix(
    filterSubTree,
    fieldType as string,
    filterKey,
  );

  return {
    baseSelectorPrefix,
    selectAvailableEntries,
    selectIsCheckboxDisabled: selectIsFilterDisabled,
    filterSubTree,
    handleCheckboxChangeHOF: onCheckBoxChange,
    optionLabel: checkboxHandlersConfig[layoutItem.filterKey].optionLabel,
    ...fullTextHandlersConfig[layoutItem.filterKey],
    ...layoutItem,
  };
};
