import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  OrderDetailStackSx,
  OrderDetailTextHeaderSx,
  OrderDetailTextBoldSx,
  OrderDetailTextSx,
  getOrderDetailGridItemSx,
} from './styles';
import { OrderSummaryProps } from './types';
import { getFormattedDate, getFormattedTime } from './utils';

const OrderSummary = (props: OrderSummaryProps) => {
  if (props.order === undefined) return null;

  const getFormattedDateTime = (orderField: string) => {
    const dateTimeString =
      // eslint-disable-next-line security/detect-object-injection
      orderField in props.order
        ? (props.order[orderField as keyof typeof props.order] as string)
        : '';
    if (!dateTimeString) return '';
    return `${getFormattedDate(dateTimeString)} @ ${getFormattedTime(
      dateTimeString
    )}`;
  };

  const formattedCreatedAtDateTime = getFormattedDateTime('createdAt');
  const formattedDueDateTime = getFormattedDateTime('dueDate');

  const getOMAliasAssigneeName = () =>
    props.order?.orderManager
      ? `${props.order?.orderManager.firstName} ${props.order?.orderManager.lastName}`
      : '';

  const getPurchaserName = () =>
    props.order?.purchaser
      ? `${props.order?.purchaser.firstName} ${props.order?.purchaser.lastName}`
      : '';

  const assigneeName = getOMAliasAssigneeName();
  const purchaserName = getPurchaserName();
  const hasEmail =
    props.order?.purchaser?.workEmail || props.order?.purchaser?.personalEmail;

  return (
    <Grid container sx={OrderDetailStackSx}>
      <Grid item sx={getOrderDetailGridItemSx()}>
        <Stack>
          <Typography sx={OrderDetailTextHeaderSx}>ORDER NUMBER</Typography>
          <Typography sx={OrderDetailTextBoldSx}>
            {props.order?.orderNumber}
          </Typography>
        </Stack>
      </Grid>
      <Grid item sx={getOrderDetailGridItemSx()}>
        <Stack>
          <Typography sx={OrderDetailTextHeaderSx}>ORDER TYPE</Typography>
          <Typography sx={OrderDetailTextSx}>
            {props.order?.orderType.name}
          </Typography>
        </Stack>
      </Grid>
      <Grid item sx={getOrderDetailGridItemSx()}>
        <Stack>
          <Typography sx={OrderDetailTextHeaderSx}>CREATED ON</Typography>
          <Typography sx={OrderDetailTextSx}>
            {formattedCreatedAtDateTime}
          </Typography>
        </Stack>
      </Grid>
      <Grid item sx={getOrderDetailGridItemSx(!!formattedDueDateTime)}>
        <Stack>
          <Typography sx={OrderDetailTextHeaderSx}>DUE DATE</Typography>
          <Typography sx={OrderDetailTextSx}>{formattedDueDateTime}</Typography>
        </Stack>
      </Grid>
      <Grid item sx={getOrderDetailGridItemSx(!!assigneeName)}>
        <Stack>
          <Typography sx={OrderDetailTextHeaderSx}>ASSIGNEE</Typography>
          <Typography sx={OrderDetailTextSx}>{assigneeName}</Typography>
        </Stack>
      </Grid>
      <Grid item sx={getOrderDetailGridItemSx(!!purchaserName)}>
        <Stack>
          <Typography sx={OrderDetailTextHeaderSx}>PURCHASER</Typography>
          <Typography sx={OrderDetailTextSx}>{purchaserName}</Typography>
        </Stack>
      </Grid>
      <Grid item sx={getOrderDetailGridItemSx(!!hasEmail)}>
        <Stack>
          <Typography sx={OrderDetailTextHeaderSx}>PURCHASER EMAIL</Typography>
          <Typography sx={OrderDetailTextSx}>
            {props.order?.purchaser?.workEmail ||
              props.order?.purchaser?.email ||
              props.order?.purchaser?.personalEmail ||
              ''}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
