import React from 'react';
import Stack from '@mui/material/Stack';
import Panel from '../../Panel';

import { useGetOrdersPanelConfig } from './hooks';

const OrdersPanel = () => {
  const config = useGetOrdersPanelConfig();

  return (
    <Stack>
      <Panel {...config} />
    </Stack>
  );
};

export default OrdersPanel;
