import React from 'react';
import { SVGProps } from '../../types';

const HoldIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F27058" />
      <path
        d="M10.0002 14.6673H11.3335V9.33398H10.0002V14.6673ZM12.0002 5.33398C8.32016 5.33398 5.3335 8.32065 5.3335 12.0007C5.3335 15.6807 8.32016 18.6673 12.0002 18.6673C15.6802 18.6673 18.6668 15.6807 18.6668 12.0007C18.6668 8.32065 15.6802 5.33398 12.0002 5.33398ZM12.0002 17.334C9.06016 17.334 6.66683 14.9407 6.66683 12.0007C6.66683 9.06065 9.06016 6.66732 12.0002 6.66732C14.9402 6.66732 17.3335 9.06065 17.3335 12.0007C17.3335 14.9407 14.9402 17.334 12.0002 17.334ZM12.6668 14.6673H14.0002V9.33398H12.6668V14.6673Z"
        fill="white"
      />
    </svg>
  );
};

export default HoldIcon;
