import { combineSlices } from '@reduxjs/toolkit';
import { orderManagementSearchSlice } from './search';
import { orderManagementTabSlice } from './tabs';
import { orderManagementDetailsSlice } from './details';
import { orderManagementFilterSlice } from './filter';

export const orderManagementSlice = combineSlices({
  search: orderManagementSearchSlice.reducer,
  tab: orderManagementTabSlice.reducer,
  details: orderManagementDetailsSlice.reducer,
  filter: orderManagementFilterSlice.reducer,
});
