import React from 'react';
import { SVGProps } from '../../types';

const PendingRepairIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F9C54D" />
      <g clip-path="url(#clip0_5074_37789)">
        <path
          d="M12.5553 10.4217L12.4339 10.7273L12.6664 10.9599L18.7198 17.0132C18.7963 17.0898 18.7829 17.1859 18.7289 17.2372L18.7288 17.237L18.7198 17.2461L17.1864 18.7794C17.1217 18.8442 17.0183 18.8442 16.9535 18.7794L10.8802 12.7061L10.6525 12.4784L10.3511 12.5915C8.97983 13.1057 7.38215 12.8147 6.28686 11.7194L6.28609 11.7187C5.07745 10.5153 4.84734 8.7001 5.59168 7.25847L7.69975 9.36654L8.05206 9.71885L8.40561 9.36779L9.35228 8.42779L9.70787 8.0747L9.35399 7.7199L7.23861 5.59901C8.68427 4.85347 10.496 5.08324 11.7059 6.29273C12.8207 7.41371 13.1041 9.04094 12.5553 10.4217ZM16.7198 18.0799L17.0753 18.4354L17.4289 18.0779L18.0155 17.4845L18.3651 17.131L18.0135 16.7794L12.0069 10.7728C12.2216 10.4149 12.369 10.0257 12.4392 9.61223C12.6326 8.53243 12.3138 7.37301 11.4735 6.53277L11.4735 6.53276L11.4717 6.5309C10.7353 5.80231 9.76293 5.45934 8.79846 5.51378L7.68328 5.57674L8.47309 6.36654L10.1795 8.07299L8.05997 10.1925L6.35353 8.4861L5.56372 7.6963L5.50077 8.81147C5.44641 9.77433 5.78825 10.7556 6.5274 11.4875C7.33933 12.2988 8.45278 12.6154 9.50452 12.4612C9.92012 12.4025 10.3228 12.2689 10.6981 12.0582L16.7198 18.0799Z"
          fill="#131414"
          stroke="#131414"
        />
      </g>
      <defs>
        <clipPath id="clip0_5074_37789">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PendingRepairIcon;
