import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { Order } from 'types';

export const getNotificationsQueryVariables = (
  partialOrder: Pick<Order, 'id'> & { shipments?: { id: string }[] },
  paginationModel: GridPaginationModel,
  orderDesc: string = 'createdAt'
) => {
  const orderShipments = partialOrder.shipments || [];

  const filterByShipmentIds =
    orderShipments.length === 0
      ? {}
      : {
          recordIds: orderShipments.map((shipment) => shipment.id),
        };

  return {
    recordId: partialOrder.id,
    ...filterByShipmentIds,
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    orderDesc,
  };
};
