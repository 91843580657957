import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const ExpandedRowCellHeaderTextSx: SxProps = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '24px',
};

export const ExpandableRowCellDetailTextSx: SxProps = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '1.43',
};

export const ExpandableRowCellTrackingLinkSx: SxProps = {
  ...ExpandableRowCellDetailTextSx,
  fontWeight: 600,
};

export const OrderItemTableSx: SxProps = {
  '& .MuiTableCell-root': {
    padding: '8px',
  },
};

export const ExpandableProductTitleRowCellTextSx: SxProps = {
  ...ExpandableRowCellDetailTextSx,
  width: '275px',
  maxWidth: '275px',
};

export const ExpandedTableRowSx: SxProps = {
  '&.MuiTableRow-root': {
    verticalAlign: 'baseline',
  },
};

export const ShipmentPillSx: SxProps = {
  height: '24px',
  maxWidth: '165px',
};

export const FailedPillTypeSx: SxProps = {
  color: theme.palette.colors.gray60,
  fontFamily: 'Inter',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '14px',
  maxWidth: '140px',
  pt: '2px',
};
