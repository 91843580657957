import React from 'react';
import { SVGProps } from '../../types';

const QuoteInProgressIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F9C54D" />
      <path
        d="M6.66667 16.0013V15.5013C6.20948 15.5013 5.83333 15.1252 5.83333 14.668V8.0013C5.83333 7.54411 6.20948 7.16797 6.66667 7.16797H17.3333C17.7905 7.16797 18.1667 7.54411 18.1667 8.0013V14.668C18.1667 15.1252 17.7905 15.5013 17.3333 15.5013V16.0013V16.5013H19.5V16.8346H4.5V16.5013H6.66667V16.0013ZM6.66667 7.5013H6.16667V8.0013V14.668V15.168H6.66667H17.3333H17.8333V14.668V8.0013V7.5013H17.3333H6.66667Z"
        fill="#131414"
        stroke="#131414"
      />
    </svg>
  );
};

export default QuoteInProgressIcon;
