/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import { SearchScopeDropdown } from 'components';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import {
  OM_SEARCH_OPTIONS,
  OrderManagementSuggestionFilterName,
  OM_CLEAR_ALL_FILTERS_BUTTON_ID,
} from './constants';
import { AutoCompleteOptionTuple, DEFAULT_AUTOCOMPLETE_OPTIONS } from 'types';
import { SearchOption } from 'components/SearchScopeDropdown/types';
import { OrderManagementLookaheadKeys } from 'store/slices/order_management/search/types';
import { selectOMSearchByKey } from 'store/slices/order_management/search/selectors';
import {
  setOMLookAheadOptionAndKey,
  setOMLookAheadKey,
  resetNonComboOMSearch,
} from 'store/slices/order_management/search';
import { useGetOrderSuggestions } from 'pages/OrderManagement/hooks/useGetOrderSuggestions';
import { selectOMTabView } from 'store/slices/order_management/tabs/selectors';
import { OMSearchFilterKeys } from 'store/slices/order_management/search/constants';
import { orderManagementFilterActions as OMDashComplexFilterActions } from 'store/slices/order_management/filter';

const OMComboSearch = () => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const debouncedSearchTerm = useDebouncedQuery(searchTerm);
  const [searchOption, setSearchOption] = React.useState<SearchOption>(
    OM_SEARCH_OPTIONS[0]
  );
  const [autocompleteOptions, setAutocompleteOptions] = React.useState<
    AutoCompleteOptionTuple[]
  >(DEFAULT_AUTOCOMPLETE_OPTIONS);
  const [selectedSuggestion, setSelectedSuggestion] =
    React.useState<AutoCompleteOptionTuple | null>(null);

  const currentTabView = useSelector(selectOMTabView);
  const lookAheadOption = useSelector(
    selectOMSearchByKey(OMSearchFilterKeys.LOOKAHEAD_OPTION)
  );

  const searchPlaceholder = `Search for order by ${searchOption.displayName.toLowerCase()}`;

  useGetOrderSuggestions(
    debouncedSearchTerm,
    searchOption.filterName as OrderManagementSuggestionFilterName,
    setAutocompleteOptions
  );

  const resetSearchTerm = () => {
    setSearchTerm('');
    setAutocompleteOptions(DEFAULT_AUTOCOMPLETE_OPTIONS);
  };

  const handleSearchTermChange = (e: any) => {
    if (e) {
      setSearchTerm(e.target.value);
    }
  };

  const handleSearchOptionChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    const option = OM_SEARCH_OPTIONS.find(
      (searchOption) => searchOption.filterName === value
    );
    if (option) {
      setSearchOption(option);
      setSelectedSuggestion(null);

      const optionType = option.filterName as OrderManagementLookaheadKeys;

      dispatch(setOMLookAheadKey(optionType));
    }
  };

  const handleSuggestionSelection = (
    _event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setSelectedSuggestion(value);

    const optionType = searchOption.filterName as OrderManagementLookaheadKeys;
    const optionValueTuple = value as AutoCompleteOptionTuple;
    const optionValue = value ? optionValueTuple[1] : '';

    dispatch(setOMLookAheadOptionAndKey({ optionType, optionValue }));
    const clearAllFiltersButton: HTMLElement = document.getElementById(
      `${OM_CLEAR_ALL_FILTERS_BUTTON_ID}`
    ) as HTMLElement;

    dispatch(
      OMDashComplexFilterActions.softResetFilterStateReducer({
        closeFilterDrawer: true,
      })
    );

    if (clearAllFiltersButton) {
      clearAllFiltersButton.click();
      dispatch(resetNonComboOMSearch());
    }
  };

  const resetLocalComboSearch = () => {
    setAutocompleteOptions(DEFAULT_AUTOCOMPLETE_OPTIONS);
    setSelectedSuggestion(null);
    setSearchTerm('');
  };

  React.useEffect(() => {
    resetLocalComboSearch();
  }, [currentTabView]);

  React.useEffect(() => {
    if (lookAheadOption) return;
    resetLocalComboSearch();
  }, [lookAheadOption]);

  return (
    <Box minWidth="px" data-component-name="OMComboSearch">
      <SearchScopeDropdown
        searchTerm={searchTerm}
        resetSearchTerm={resetSearchTerm}
        handleSearchTermChange={handleSearchTermChange}
        selectedSearchOption={searchOption}
        searchOptions={OM_SEARCH_OPTIONS}
        handleSearchOptionChange={handleSearchOptionChange}
        suggestions={autocompleteOptions}
        selectedSuggestion={selectedSuggestion}
        handleSuggestionSelection={handleSuggestionSelection}
        placeholder={searchPlaceholder}
      />
    </Box>
  );
};

export default OMComboSearch;
