import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const CLASS_NAMES_SUFFIX = {
  appliedBox: '-applied-box-class',
  clearAllType: '-clear-all-type-class',
  checkBoxLabel: '-checkbox-label-class',
  checkBoxMain: '-checkbox-main-class',
};

export const getClearStackSx = (
  hasSelections: boolean,
  selectorPrefix: string,
  overrideSx: SxProps = {}
): SxProps => ({
  backgroundColor: theme.palette.colors.white,
  boxSizing: 'border-box',
  borderLeft: '1px solid #00000099',
  borderRight: '1px solid #00000099',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  display: hasSelections ? 'flex' : 'none',
  fontSize: '12px',
  fontWeight: 600,
  height: '40px',
  maxHeight: '40px',
  width: '100%',
  position: 'absolute',
  mt: '2px',
  px: '16px',
  zIndex: 1550,
  '& .clear-all-btn': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  [`& .MuiBox-root.${selectorPrefix}${CLASS_NAMES_SUFFIX.appliedBox}`]: {
    color: theme.palette.colors.woodSmoke,
    fontWeight: 600,
    pl: 0,
  },
  [`& .MuiTypography-root.${selectorPrefix}${CLASS_NAMES_SUFFIX.clearAllType}`]:
    {
      fontSize: '12px',
      fontWeight: 600,
    },
  ...overrideSx,
});

export const ClearAllButtonSx: SxProps = {
  backgroundColor: 'transparent',
  borderRadius: '10px',
  color: theme.palette.colors.woodSmoke,
  fontSize: '12px !important',
  fontWeight: 600,
  height: '36px',
  my: '1px',
  maxHeight: '40px',
  minWidth: '80px',
  px: 0,
  '&:hover': {
    backgroundColor: theme.palette.colors.transparentGrey,
  },
};
