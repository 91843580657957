import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import OrderManagementSearchState, {
  OMSearchComboTextKeys,
  OrderManagementLookaheadKeys,
} from './types';

export const resetComboSearchReducer = (
  state: WritableDraft<OrderManagementSearchState>
) => {
  state.lookAheadOption = '';
};

export const setOMLookAheadKeyReducer = (
  state: WritableDraft<OrderManagementSearchState>,
  action: PayloadAction<string>
) => {
  const clearValue = action.payload !== state.lookAheadOptionType;
  state.lookAheadOptionType = action.payload as OrderManagementLookaheadKeys;

  if (clearValue) {
    state.lookAheadOption = '';
  }
};

export const setLookAheadOptionReducer = (
  state: WritableDraft<OrderManagementSearchState>,
  action: PayloadAction<{
    optionType: string;
    optionValue: string;
  }>
) => {
  state.lookAheadOptionType = action.payload
    .optionType as OrderManagementLookaheadKeys;
  state.lookAheadOption = action.payload.optionValue;
};

export const setSearchFilterByKeyReducer = (
  state: WritableDraft<OrderManagementSearchState>,
  action: PayloadAction<{ key: OMSearchComboTextKeys; value: string }>
) => {
  state[action.payload.key] = action.payload.value;
};

export const setOrderManagerLoadStatusReducer = (
  state: WritableDraft<OrderManagementSearchState>,
  action: PayloadAction<boolean>
) => {
  state.isLoadingOrderManagers = action.payload;
};

export const clearSearchFilterByKeyReducer = (
  state: WritableDraft<OrderManagementSearchState>,
  action: PayloadAction<OMSearchComboTextKeys>
) => {
  const resetValue =
    action.payload === 'lookAheadOptionType' ? 'orderNumber' : '';
  state[action.payload] = resetValue;
};

export const clearOMSelfAssignSwitchReducer = (
  state: WritableDraft<OrderManagementSearchState>,
) => {
  state.isOMSelfAssignChecked = false;
  state.orderManagerFilter = '';
};

export const assignOMSelfAssignSwitchReducer = (
  state: WritableDraft<OrderManagementSearchState>,
  action: PayloadAction<string>
) => {
  state.isOMSelfAssignChecked = true;
  state.orderManagerFilter = action.payload;
};

export const resetNonComboSearchReducer = (
  state: WritableDraft<OrderManagementSearchState>
) => {
  state.orderTypeFilter = '';
  state.statusFilter = '';
  state.organizationFilter = '';
  state.orderManagerFilter = '';
  state.depotFilter = '';
};
