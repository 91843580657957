import React from 'react';
import Box from '@mui/material/Box';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGINATION_MODEL,
  GENERIC_PAGE_SIZE_OPTIONS,
} from 'global-constants';
import DataGrid from 'components/DataGrid';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { NotificationsTableProps } from './types';
import { DataGridBoxSx } from './styles';
import {
  SEARCH_NOTIFICATIONS,
} from './queries';
import { useGetOrderNotificationsColumns } from './hooks/useGetOrderNotificationsColumns';
import { getNotificationsQueryVariables } from './utils';

const NotificationsTable = (props: NotificationsTableProps) => {
  const availableNotificationTypes = Object.keys(props.notificationTypeLookup);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const { data, loading } = useSafeQuery(SEARCH_NOTIFICATIONS, {
    variables: getNotificationsQueryVariables(props.order, paginationModel),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const orderNotifications = data?.searchNotifications.notifications || [];

  const rowCount = data?.searchNotifications.count || 0;

  const columns = useGetOrderNotificationsColumns(
    props.order,
    props.sendNotification,
    props.notificationTypeLookup,
  );

  return (
    <Box sx={DataGridBoxSx}>
      <DataGrid
        rows={orderNotifications}
        rowCount={rowCount}
        columns={columns}
        loading={loading || props.showLoadingIndicator || !availableNotificationTypes.length}
        autoHeight={false}
        rowSelection={false}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
};

export default NotificationsTable;
