import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { OrderView } from 'pages/OrderManagement/enum';
import { TabsSx } from './styles';
import { setOMTabView } from 'store/slices/order_management/tabs';
import { selectOMTabView } from 'store/slices/order_management/tabs/selectors';
import {
  resetNonComboOMSearch,
  resetOMComboSearch,
} from 'store/slices/order_management/search';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums/feature-flags';

const ViewTabs = () => {
  const { isFlagOff: complexOMFilterDisabled } = useFeatureFlagService(
    FeatureFlagNames.COMPLEX_ORDER_DASH_FILTERING
  );

  const dispatch = useDispatch();

  const handleOnChange = (_event: React.SyntheticEvent, value: OrderView) => {
    if (complexOMFilterDisabled()) {
      dispatch(resetNonComboOMSearch());
    }
    dispatch(resetOMComboSearch());
    dispatch(setOMTabView(value));
  };

  const currentViewTab = useSelector(selectOMTabView);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        sx={TabsSx}
        value={currentViewTab}
        onChange={handleOnChange}
        aria-label="order management view filter panel"
      >
        <Tab label="ALL ORDERS" value={OrderView.ALL_ORDERS} />
        <Tab label="ALL NEW ORDERS" value={OrderView.ALL_NEW_ORDERS} />
        <Tab label="PROCUREMENT" value={OrderView.PROCUREMENT} />
        <Tab label="KITS" value={OrderView.KITS} />
        <Tab label="RETRIEVALS" value={OrderView.RETRIEVALS} />
        <Tab label="REDEPLOYMENT" value={OrderView.REDEPLOYMENT} />
        <Tab label="CANCELLED" value={OrderView.CANCELLED} />
        <Tab label="EXPIRED" value={OrderView.EXPIRED} />
      </Tabs>
    </Box>
  );
};

export default ViewTabs;
