import { PanelItemData } from '../../PanelItem/types';
import { Order } from 'store/slices/home/orders/types';

export const transformOrder = (order: Order) => {
  const orderItems = order?.orderItems;
  let assetType = '';
  let createdAt = '';
  if (orderItems?.length) {
    const asset = orderItems[0]?.asset;
    assetType = asset?.assetType?.name || '';
  }
  let recipientName = '';

  if (order?.recipient?.firstName && order?.recipient?.lastName) {
    recipientName = `${order.recipient.firstName} ${order.recipient.lastName}'s`;
  }

  if (order?.orderStatusTransition?.createdAt) {
    createdAt = order.orderStatusTransition.createdAt;
  }

  const orderItem: PanelItemData = {
    recipientName,
    assetType,
    assetStatus: '',
    orderStatus: order?.status,
    orderType: order?.orderType.name,
    createdAt,
    orderId: order?.id,
    orgId: order?.organizationId,
  };
  return orderItem;
};
