import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import { OrderView } from 'pages/OrderManagement/enum';
import { OM_VIEW_META } from './constants';
import OMPageViewState from './types';

export const setOMTabReducer = (state: WritableDraft<OMPageViewState>, action: PayloadAction<OrderView>) => {
  state.view = action.payload;
  state.meta = OM_VIEW_META[action.payload];

  switch(action.payload) {
    case OrderView.ALL_NEW_ORDERS:
    case OrderView.CANCELLED:
    case OrderView.EXPIRED:
      state.disableStatusFilter = true;
      state.disableOrderTypeFilter = false;
    break;

    case OrderView.PROCUREMENT:
    case OrderView.REDEPLOYMENT:
    case OrderView.RETRIEVALS:
      state.disableStatusFilter = false;
      state.disableOrderTypeFilter = true;
    break;

    default:
      state.disableStatusFilter = false;
      state.disableOrderTypeFilter = false;
    break;
  }
};
