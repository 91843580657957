import React from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { TotalsCardLabelProps } from './types';

import * as styles from './styles';

const TotalsCardLabel = (props: TotalsCardLabelProps) => {
  const { info, type } = props;
  if (!info) return null;
  return (
    <Link to={info.link} style={styles.LinkSx}>
      <Stack sx={styles.LabelSx(type)}>
        <Stack sx={styles.LabelLeftSx(type)}>
          <Stack sx={styles.LabelCountSx(type)}>{info.total}</Stack>
          &nbsp;
          <Stack sx={styles.LabelCopySx(type)}>{info.copy} &nbsp;</Stack>
          {info.copyTooltip && info.copyTooltipIcon ? (
            <Stack sx={styles.LabelTooltipIconSx}>
              <Tooltip title={info.copyTooltip}>
                <info.copyTooltipIcon fontSize="small" />
              </Tooltip>
            </Stack>
          ) : null}
        </Stack>
        <Stack sx={styles.LabelRightSx(type)}>
          <info.icon sx={styles.LabelIconSx(type)} />
        </Stack>
      </Stack>
    </Link>

  );
};

export default TotalsCardLabel;
