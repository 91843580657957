import React from 'react';
import { SVGProps } from '../../types';

const ActivationLockedIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F27058" />
      <path
        d="M14.8332 9.33464V9.83464H15.3332H15.9998C16.457 9.83464 16.8332 10.2108 16.8332 10.668V17.3346C16.8332 17.7918 16.457 18.168 15.9998 18.168H7.99984C7.54265 18.168 7.1665 17.7918 7.1665 17.3346V10.668C7.1665 10.2108 7.54265 9.83464 7.99984 9.83464H8.6665H9.1665V9.33464V8.0013C9.1665 6.43744 10.436 5.16797 11.9998 5.16797C13.5637 5.16797 14.8332 6.43744 14.8332 8.0013V9.33464ZM13.9998 9.83464H14.4998V9.33464V8.0013C14.4998 6.61849 13.3826 5.5013 11.9998 5.5013C10.617 5.5013 9.49984 6.61849 9.49984 8.0013V9.33464V9.83464H9.99984H13.9998ZM15.9998 17.8346H16.4998V17.3346V10.668V10.168H15.9998H7.99984H7.49984V10.668V17.3346V17.8346H7.99984H15.9998ZM12.8332 14.0013C12.8332 14.4585 12.457 14.8346 11.9998 14.8346C11.5426 14.8346 11.1665 14.4585 11.1665 14.0013C11.1665 13.5441 11.5426 13.168 11.9998 13.168C12.457 13.168 12.8332 13.5441 12.8332 14.0013Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default ActivationLockedIcon;
