import React from 'react';
import { SxProps } from '@mui/material';
import theme from 'themes/theme';

const OUTER_PADDING = 12;
const ICON_WIDTH = 64;
const ORDER_ITEM_GAP = OUTER_PADDING;
const ORDER_ITEM_HEIGHT = ICON_WIDTH;
const STANDARD_PADDING = '16px';

const BORDER_BOX = 'border-box';
const COMMON_BORDER_STYLE = '1px solid rgba(19, 20, 20, 0.12)';

export const OrderItemPanelCardContainerSx: SxProps = {
  backgroundColor: theme.palette.colors.grayFA,
};

export const OrderItemPanelInnerGridSx: SxProps = {
  boxSizing: 'border-box',
  width: '100%',
};

export const SampleBoxSx: SxProps = {
  minHeight: '60px',
  width: '100%',
};

export const OrderItemsPanelContentStackSx: SxProps = {
  minHeight: '64px',
  width: '100%',
  '& > .MuiBox-root, & > .MuiStack-root': {
    alignSelf: 'center',
    boxSizing: BORDER_BOX,
    px: STANDARD_PADDING,
    '& .MuiChip-root': {
      m: 0,
    },
  },
};

export const TrackingLinkBoxSx: SxProps = {
  alignSelf: 'flex-start !important',
  height: '100%',
  width: '30%',
  px: '16px',
};

export const TrackingLinkInnerBoxSx: SxProps = {
  height: '60px',
  display: 'flex',
  alignItems: 'center',
};

export const ShipmentStatusBoxSx: SxProps = {
  alignSelf: 'flex-start !important',
  height: '100%',
  width: '31%',
};

export const ShipmentStatusInnerBoxSx= (hasDescription: boolean): SxProps => {
  return {
    height: '60px',
    display: 'flex',
    ...(hasDescription ? { 
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
      boxSizing: BORDER_BOX,
      mb: '10px',
    } : {
      alignItems: 'center',
    }),
};
};

export const ShipmentStatusDescriptionSx: SxProps = {
  width: '190px',
  height: '56px',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '12px',
  color: theme.palette.colors.gray60,
}

export const ShipmentStatusPillSx: SxProps = {
  mt: '8px',
  fontWeight: 'bold',
  width: 'auto',
  maxWidth: '180px',
  cursor: 'pointer',
};

export const OrderItemsBoxSx: SxProps = {
  width: '52%',
};

export const getOrderItemBoxSx = (
  currentIndex: number,
  length: number
): SxProps => ({
  boxSizing: BORDER_BOX,
  display: 'flex',
  flexDirection: 'row',
  ...(currentIndex > 0 && length > 1 ? { mb: `${ORDER_ITEM_GAP}px` } : {}),
  height: `${ORDER_ITEM_HEIGHT}px`,
  width: '100%',
});

export const OrderItemImageBoxSx: SxProps = {
  width: `${ICON_WIDTH}px`,
  mr: `${OUTER_PADDING}px`,
  height: '100%',
};

export const OrderItemTitleBoxSx: SxProps = {
  alignItems: 'center',
  display: 'flex',
  pr: STANDARD_PADDING,
  width: `calc(66.66% - ${ICON_WIDTH + OUTER_PADDING}px)`,
};

export const OrderItemRetrievalTypeSx: SxProps = {
  letterSpacing: '1px',
};

export const OrderItemAssetBoxSx: SxProps = {
  alignSelf: 'center',
  px: STANDARD_PADDING,
  width: '33.33%',
};

export const ProcurementImageStyle: React.CSSProperties = {
  height: 'auto',
  width: 'auto',
  maxHeight: '64px',
  maxWidth: '64px',
};

export const getOrderItemPanelDividerGridSx = (
  isLastCard: boolean = false
): SxProps => ({
  borderTop: isLastCard ? 'none' : COMMON_BORDER_STYLE,
  boxSizing: BORDER_BOX,
  mx: '20px',
});

export const OrderItemPanelDividerSx: SxProps = {
  backgroundColor: 'rgba(19, 20, 20, 0.12)',
  mx: `${OUTER_PADDING}px`,
};

/* START of Backup Icon styles */
export const BackupIconStyles: Record<string, SxProps> = {
  IconSx: {},
  AvatarSx: {
    backgroundColor: 'rgba(19, 20, 20, 0.04)',
    boxSizing: BORDER_BOX,
    color: theme.palette.colors.woodSmoke,
    height: '54px',
    m: '5px',
    width: '54px',
    '& .MuiSvgIcon-root': {
      fontSize: '30px',
    },
  },
};
/* END of Backup Icon styles */
