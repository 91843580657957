import { useSelector } from 'react-redux';

import { transformAsset } from './utils';
import { getAssets } from 'store/slices/home/assets/actions';

import { ASSETS_LOOK_BACK_DATE, ICONS } from './constants';
import { FIND_ASSETS_BY_STATUS_TRANSITION_QUERY } from './queries';
import {
  selectAssets,
  selectAssetsTake,
  selectAssetsSkip,
  selectAssetsLoading,
  selectAssetsCount,
} from 'store/slices/home/assets/selectors';
import { PanelProps } from '../../Panel/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';

export const useGetAssetsPanelConfig = () => {
  const assets = useSelector(selectAssets);
  const take = useSelector(selectAssetsTake);
  const skip = useSelector(selectAssetsSkip);
  const loading = useSelector(selectAssetsLoading);
  const count = useSelector(selectAssetsCount);
  const organization = useGetUserOrganization();
  const transformedAssets = assets.map(transformAsset);

  const type: 'assets' | 'orders' = 'assets';

  const config: PanelProps = {
    data: transformedAssets,
    type,
    icons: ICONS,
    loading,
    apiCount: count,
    getData: getAssets,
    getDataParams: {
      append: true,
      query: FIND_ASSETS_BY_STATUS_TRANSITION_QUERY,
      variables: {
        where: {
          deletedAt: { equals: null },
          createdAt: {
            gt: ASSETS_LOOK_BACK_DATE,
          },
          asset: {
            is: {
              organizationId: {
                equals: organization?.id,
              },
            },
          },
        },
        orderBy: [{ createdAt: 'desc' }],
        skip,
        take,
      },
    },
  };
  return config;
};
