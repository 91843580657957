import React from 'react';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { OrderType, UserPermissions } from 'global-constants';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextIconButton from 'components/TextIconButton';
import OMSelfAssignSwitch from 'pages/OrderManagement/components/OMSelfAssignSwitch';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { usePermissionRedirect } from 'hooks/usePermissionRedirect';
import { useSafeQuery } from 'hooks/useSafeQuery';
import DetailedOrderView from './components/DetailedOrderView';
import OrderCancelModal from './components/OrderCancelModal';
import OrderFiltersBar from './components/OrderFiltersBar';
import CreateNewOrderModal from './components/CreateNewOrderModal';
import ViewTabs from './components/ViewTabs';
import { GET_ALL_ORDER_TYPES } from './queries';
import * as OrderManagementStyles from './styles';
import ManagementDashboardToggle from 'components/ManagementToggle';
import Spacer from 'components/Spacer';
import OMComboSearch from './components/OMComboSearch';
import OMOrdersTable from './components/OMOrdersTable';
import {
  resetOMDetails,
  toggleOMCreateModal,
} from 'store/slices/order_management/details';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { FeatureFlagNames } from 'enums/feature-flags';
import OMDashComplexFilterDrawer from './filters/OMComplexFilterDrawer';
import OMDashComplexFilterBank from './filters/OMComplexFilterBank';

const OrderManagement = () => {
  const dispatch = useDispatch();
  const styles = OrderManagementStyles;
  const [orderTypeLookup, setOrderTypeLookup] = React.useState<
    Record<string, any>
  >({});
  const userPermissions = useGetUserPermissions();

  const {
    OnFlagTrueWrapper: IfComplexFilterFlagOn,
    OnFlagFalseWrapper: IfComplexFilterFlagOff,
  } = useFeatureFlagService(FeatureFlagNames.COMPLEX_ORDER_DASH_FILTERING, {
    forceFlagValue: true,
  });

  //If user is not permitted to view OrderManagement, redirect to home page
  usePermissionRedirect(
    userPermissions,
    UserPermissions.ORDER__MANAGEMENT__VIEW
  );

  const { loading: loadingAllOrderTypes } = useSafeQuery(GET_ALL_ORDER_TYPES, {
    onCompleted: ({ getAllOrderTypes = [] }) => {
      if (getAllOrderTypes.length === 0) return;

      const orderTypeEntries = Object.entries(OrderType);
      const orderTypeValuesLookup = getAllOrderTypes.reduce(
        (acc: Record<string, any>, orderType: any) => {
          const { name, ...restOfOrderType } = orderType;
          acc[name] = restOfOrderType;
          return acc;
        },
        {} as Record<string, any>
      );
      setOrderTypeLookup(() =>
        orderTypeEntries.reduce((acc, [field, value]) => {
          const tempInfo = orderTypeValuesLookup[value];
          acc[field] = { matchValue: value, ...tempInfo };
          acc[value] = { matchValue: field, ...tempInfo };
          return acc;
        }, {} as Record<string, any>)
      );
    },
  });

  const orderTypeLookupAvailable =
    !loadingAllOrderTypes && Object.keys(orderTypeLookup).length > 0;

  const openCreateNewOrderModal = () => {
    dispatch(toggleOMCreateModal(true));
  };

  const onInitialOMPageLoad = () => {
    dispatch(resetOMDetails());
  };

  React.useEffect(() => {
    onInitialOMPageLoad();
  }, []);

  return (
    <>
      <Container sx={styles.MainContainerSx}>
        <Stack direction="column">
          <ManagementDashboardToggle />
          <Spacer height="16px" />
        </Stack>
        <Stack direction="column">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb="24px"
          >
            <Typography sx={styles.MainHeaderSx}>Order Management</Typography>
            <TextIconButton
              text="Create new order"
              ariaLabel="create-new-order-btn"
              variant="outlined"
              disabled={!orderTypeLookupAvailable}
              icon={<AddCircleOutlineIcon />}
              buttonStyles={styles.AddOrderButtonStylesSx}
              textStyles={styles.AddOrderButtonTextStylesSx}
              onClick={openCreateNewOrderModal}
            />
          </Stack>
        </Stack>
        <ViewTabs />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt="12px"
        >
          <OMComboSearch />
          <Stack
            direction="row"
            rowGap="16px"
            columnGap="16px"
            alignItems="center"
          >
            <OMSelfAssignSwitch />
            <IfComplexFilterFlagOn>
              <OMDashComplexFilterDrawer />
            </IfComplexFilterFlagOn>
          </Stack>
        </Stack>

        <IfComplexFilterFlagOff>
          <OrderFiltersBar />
        </IfComplexFilterFlagOff>

        <IfComplexFilterFlagOn>
          <OMDashComplexFilterBank />
        </IfComplexFilterFlagOn>
        {orderTypeLookupAvailable ? (
          <OMOrdersTable orderTypeLookup={orderTypeLookup} />
        ) : (
          <></>
        )}
      </Container>
      {orderTypeLookupAvailable ? (
        <DetailedOrderView orderTypeLookup={orderTypeLookup} />
      ) : (
        <></>
      )}
      <OrderCancelModal />
      {orderTypeLookupAvailable ? (
        <CreateNewOrderModal orderTypeLookup={orderTypeLookup} />
      ) : null}
    </>
  );
};

export default OrderManagement;
