import React from 'react';
import { useDispatch } from 'react-redux';
import { Stack, useTheme } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { Avatar, Pill } from 'components';
import OrderStatusPill from 'components/OrderStatusPill';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { Order, OrderManager } from 'types';
import { SEARCH_ORDER_MANAGERS } from './queries';
import OrderManagerMenu from './components/OrderManagerMenu';
import { formatDate } from 'services/date';
import { useMediaQuery } from '@mui/material';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useGetOrderManagementColumns = (mutate: any): GridColDef[] => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();

  return [
    {
      field: 'assignee',
      headerName: 'Assignee',
      flex: 0.6,
      type: 'actions',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      //eslint-disable-next-line sonarjs/cognitive-complexity
      getActions(params: GridRowParams) {
        const { data: orderManagerData } = useSafeQuery(SEARCH_ORDER_MANAGERS);
        const orderManagers =
          orderManagerData?.searchOrderManagers?.orderManagers || [];

        const isOrderManager = !!params.row.orderManager;
        const [assignedOrderManager, setAssignedOrderManager] =
          React.useState<OrderManager | null>(params.row.orderManager);

        const [open, setOpen] = React.useState(false);
        const anchorRefAdd = React.useRef<HTMLButtonElement>(null);
        const anchorRefEdit = React.useRef<HTMLButtonElement>(null);

        const handleClick = (event: any) => {
          const newOrderManagerId = event.target.value;
          if (newOrderManagerId) {
            const orderManagerIdPayload =
              newOrderManagerId === assignedOrderManager?.id
                ? null
                : newOrderManagerId;
            mutate({
              variables: {
                id: params.row.id,
                orderManagerId: orderManagerIdPayload,
              },
              onCompleted() {
                const newOrderManager = orderManagers.find(
                  (orderManager: OrderManager) =>
                    orderManager.id === orderManagerIdPayload
                );
                setAssignedOrderManager(newOrderManager);
                dispatch(
                  notificationSlice.actions.setNotice({
                    showNotice: true,
                    noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
                  })
                );
                setOpen(true);
              },
              update(cache: any, { data: updateOrder }: any) {
                cache.modify({
                  optimistic: true,

                  fields: {
                    searchOrders(data: any) {
                      const newOrderManager = orderManagers.find(
                        (orderManager: OrderManager) =>
                          orderManager.id === updateOrder.orderManagerId
                      );
                      const orders = data.orders || [];
                      return orders.map((order: Order) => {
                        return order.id === updateOrder.id
                          ? {
                              ...order,
                              orderManager: newOrderManager,
                            }
                          : order;
                      });
                    },
                  },
                });
              },
            });
          } else {
            setOpen((prevOpen) => !prevOpen);
          }
        };

        const prevOpen = React.useRef(open);
        React.useEffect(() => {
          prevOpen.current = open;
        }, [open]);

        if (!assignedOrderManager) {
          return [
            <GridActionsCellItem
              size="small"
              label="Assign Order Manager"
              disableRipple
              icon={
                <>
                  <IconButton
                    ref={anchorRefAdd}
                    size="small"
                    disableRipple
                    sx={{
                      backgroundColor: 'colors.maizeYO',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <AddIcon color="secondary" />
                  </IconButton>
                  <OrderManagerMenu
                    open={open}
                    anchorEl={anchorRefAdd}
                    assignedOrderManagerId={undefined}
                    orderManagers={orderManagers}
                  />
                </>
              }
              onClick={handleClick}
            />,
          ];
        }

        const orderManagerInitials = assignedOrderManager
          ? `${assignedOrderManager?.firstName[0]}${assignedOrderManager?.lastName[0]}`
          : null;

        return [
          <GridActionsCellItem
            size="small"
            label="Change Order Manager"
            disableRipple
            ref={anchorRefEdit}
            icon={
              <>
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                  <Avatar
                    text={orderManagerInitials}
                    bgcolor="colors.grayBD"
                    color="colors.white"
                    height={35}
                    width={35}
                  />
                  <KeyboardArrowDownIcon
                    fontSize="large"
                    htmlColor={theme.palette.colors.gray75}
                  />
                </Stack>
                <OrderManagerMenu
                  open={open}
                  anchorEl={anchorRefEdit}
                  assignedOrderManagerId={assignedOrderManager?.id}
                  orderManagers={orderManagers}
                />
              </>
            }
            onClick={handleClick}
          />,
        ];
      },
    },
    {
      field: 'orderType',
      headerName: 'Order type',
      flex: isLargeScreen ? 2 : 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return (
          <Pill text={params.row?.orderType?.name} color="colors.mercury" />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Order Status',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return <OrderStatusPill status={params.row?.status} />;
      },
    },
    {
      field: 'orderNumber',
      headerName: 'Order number',
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        return params?.row?.orderNumber;
      },
    },
    {
      field: 'recipientName',
      headerName: 'Recipient name',
      flex: isLargeScreen ? 2 : 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        if (params?.row?.dropRecipient) {
          return `${params?.row?.dropRecipient?.firstName} ${params?.row?.dropRecipient?.lastName}`;
        }
        if (params.row.recipient) {
          return `${params?.row?.recipient?.firstName} ${params?.row?.recipient?.lastName}`;
        }
        return '';
      },
    },
    {
      field: 'recipientEmail',
      headerName: 'Recipient email',
      flex: isLargeScreen ? 2 : 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        if (params?.row?.dropRecipient) {
          params?.row?.dropRecipient?.email;
        }
        if (params?.row?.recipient) {
          return params?.row?.recipient?.email;
        }
        return '';
      },
    },
    {
      field: 'account',
      headerName: 'Account',
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        return (params.row?.organizationObject?.name ?? '' )|| (params.row?.organization?.name ?? '');
      },
    },
    {
      field: 'purchaser',
      headerName: 'Purchaser',
      flex: isLargeScreen ? 2 : 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        return params.row?.purchaser
          ? `${params.row?.purchaser?.firstName} ${params.row?.purchaser?.lastName}`
          : '-';
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due date',
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        if (!params.row?.dueDate) return '';
        return new Date(formatDate(params.row?.dueDate));
      },
    },
    {
      field: 'depot',
      headerName: 'Depot location',
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        return params.row?.depot?.name ?? '';
      },
    },
  ];
};
