import { SearchOption } from 'components/SearchScopeDropdown/types';

export enum OrderManagementSuggestionFilterName {
  ORDER_NUMBER = 'orderNumber',
  RECIPIENT_NAME = 'recipientName',
  RECIPIENT_EMAIL = 'recipientEmail',
}

export const OM_SEARCH_OPTIONS: SearchOption[] = [
  {
    displayName: 'Order number',
    filterName: OrderManagementSuggestionFilterName.ORDER_NUMBER,
  },
  {
    displayName: 'Recipient name',
    filterName: OrderManagementSuggestionFilterName.RECIPIENT_NAME,
  },
  {
    displayName: 'Recipient email',
    filterName: OrderManagementSuggestionFilterName.RECIPIENT_EMAIL,
  },
];

export const OM_CLEAR_ALL_FILTERS_BUTTON_ID =
  'order-management-filters-clear-all-button';
