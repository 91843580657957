import React from 'react';
import { SVGProps } from '../../types';

const CreatedIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4DB7C6" />
      <path
        d="M16.6667 6.0013H13.88C13.6 5.22797 12.8667 4.66797 12 4.66797C11.1333 4.66797 10.4 5.22797 10.12 6.0013H7.33333C6.6 6.0013 6 6.6013 6 7.33464V18.0013C6 18.7346 6.6 19.3346 7.33333 19.3346H16.6667C17.4 19.3346 18 18.7346 18 18.0013V7.33464C18 6.6013 17.4 6.0013 16.6667 6.0013ZM12 6.0013C12.3667 6.0013 12.6667 6.3013 12.6667 6.66797C12.6667 7.03464 12.3667 7.33464 12 7.33464C11.6333 7.33464 11.3333 7.03464 11.3333 6.66797C11.3333 6.3013 11.6333 6.0013 12 6.0013ZM16.6667 18.0013H7.33333V7.33464H8.66667V9.33464H15.3333V7.33464H16.6667V18.0013Z"
        fill="white"
      />
    </svg>
  );
};

export default CreatedIcon;
