import { createSlice } from '@reduxjs/toolkit';
import {
  clearSelectedOrderReducer,
  updateSelectedOrderReducer,
  assignOMPurchasersReducer,
  clearOMPurchasersReducer,
  toggleOMCancelModalReducer,
  toggleOMCreateModalReducer,
  toggleOMDetailsModalReducer,
  setOMOrderOpenDetailsReducer,
  setOMSelectedOrderReducer,
} from './reducers';
import OrderManagementDetailsState from './types';

export const INITIAL_OM_DETAILS_STATE: OrderManagementDetailsState = {
  selectedOrder: undefined,
  cancelOrderOpen: false,
  createOrderOpen: false,
  detailsModalOpen: false,
  selectablePurchasers: [],
};

export const orderManagementDetailsSlice = createSlice({
  name: 'OMDetailsSlice',
  initialState: INITIAL_OM_DETAILS_STATE,
  reducers: {
    clearSelectedOrderReducer,
    updateSelectedOrderReducer,
    assignOMPurchasersReducer,
    clearOMPurchasersReducer,
    toggleOMCancelModalReducer,
    toggleOMCreateModalReducer,
    toggleOMDetailsModalReducer,
    setOMOrderOpenDetailsReducer,
    setOMSelectedOrderReducer,
    reset: () => INITIAL_OM_DETAILS_STATE,
  },
});

export const {
  reset: resetOMDetails,
  clearSelectedOrderReducer: clearSelectedOrder,
  updateSelectedOrderReducer: updateSelectedOrder,
  assignOMPurchasersReducer: assignOMPurchasers,
  clearOMPurchasersReducer: clearOMPurchasers,
  toggleOMCancelModalReducer: toggleOMCancelModal,
  toggleOMCreateModalReducer: toggleOMCreateModal,
  toggleOMDetailsModalReducer: toggleOMDetailsModal,
  setOMOrderOpenDetailsReducer: setOMOrderOpenDetails,
  setOMSelectedOrderReducer: setOMSelectedOrder,
} = orderManagementDetailsSlice.actions;
