import React, { CSSProperties, LegacyRef } from 'react';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { FixedSizeList, VariableSizeList, ListProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import PanelItem from '../PanelItem';
import CTA from '../../../widgets/CTA';
import FAQCards from '../../../widgets/faq/FAQCards';
import {
  useGetOrderCTAConfig,
  useGetOrderFAQConfig,
  useGetAssetCTAConfig,
  useGetAssetFAQConfig,
} from '../../../hooks';
import * as styles from './styles';

import { PanelProps } from './types';
import { AppDispatch } from 'store';

const Panel = (props: PanelProps) => {
  const { data, apiCount, type, icons, loading, getData, getDataParams } =
    props;
  const dispatch = useDispatch<AppDispatch>();
  const ordersCTAConfig = useGetOrderCTAConfig(props);
  const orderFAQConfig = useGetOrderFAQConfig();
  const assetCTAConfig = useGetAssetCTAConfig(props);
  const assetFAQConfig = useGetAssetFAQConfig();

  const hasNextPage = data.length < apiCount;
  const isNextPageLoading = loading === 'pending';

  const itemCount = hasNextPage ? data.length + 1 : data.length;
  const onNextPageLoad = () => {
    dispatch(getData(getDataParams));
  };

  const loadMoreItems = isNextPageLoading ? () => {} : onNextPageLoad;

  const isItemLoaded = (index: number) => !hasNextPage || index < data.length;

  const Item: (params: {
    index: number;
    style: CSSProperties;
  }) => JSX.Element = ({ index, style }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = (
        <Stack sx={styles.PanelLoadingIndicatorSx}>
          <CircularProgress color="primary" />
        </Stack>
      );
    } else {
      let Icon = null;
      const d = data[index];
      if (type === 'orders' && d?.orderStatus) {
        Icon = icons[d.orderStatus];
      } else if (type === 'assets' && d?.assetStatus) {
        Icon = icons[d.assetStatus];
      }
      content = <PanelItem key={index} type={type} Icon={Icon} data={d} />;
    }

    return <div style={style}>{content}</div>;
  };

  return (
    <Stack sx={styles.PanelContainerSx}>
      {data.length > 5 ? (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {(params: {
            onItemsRendered: ListProps['onItemsRendered'];
            ref: LegacyRef<any> | undefined;
          }) => {
            const { onItemsRendered, ref } = params;
            return (
              <FixedSizeList
                width={styles.PANEL_WIDTH}
                height={styles.PANEL_HEIGHT}
                itemSize={styles.PANEL_ITEM_HEIGHT}
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
              >
                {Item}
              </FixedSizeList>
            );
          }}
        </InfiniteLoader>
      ) : null}
      {data.length <= 5 && loading === 'fulfilled'
        ? data.map((d, index) => {
            let Icon = null;
            if (type === 'orders' && d?.orderStatus) {
              Icon = icons[d.orderStatus];
            } else if (type === 'assets' && d?.assetStatus) {
              Icon = icons[d.assetStatus];
            }
            return <PanelItem key={index} type={type} Icon={Icon} data={d} />;
          })
        : null}
      {data.length <= 5 && type === 'orders' && loading === 'fulfilled' ? (
        <CTA {...ordersCTAConfig} />
      ) : null}
      {data.length === 0 && type === 'orders' && loading === 'fulfilled' ? (
        <FAQCards {...orderFAQConfig} />
      ) : null}
      {data.length <= 5 && type === 'assets' && loading === 'fulfilled' ? (
        <CTA {...assetCTAConfig} />
      ) : null}
      {data.length === 0 && type === 'assets' && loading === 'fulfilled' ? (
        <FAQCards {...assetFAQConfig} />
      ) : null}
    </Stack>
  );
};

export default Panel;
