import { useDispatch, useSelector } from 'react-redux';
import { selectShippingPopoverHoveredOrder } from 'store/slices/orders/datagrid/selectors';
import { setShippingPopoverHoveredOrder } from 'store/slices/orders/datagrid';


export const useGetConfig = () => {
  const dispatch = useDispatch();

  const hoveredOrder = useSelector(selectShippingPopoverHoveredOrder);
  const setHoveredOrder = (order: string | null) => {
    dispatch(setShippingPopoverHoveredOrder(order));
  };

  return {
    hoveredOrder,
    setHoveredOrder,
  };
};
