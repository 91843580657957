import { RootState } from 'store';
import { Order } from 'types';
import { OMDetailsModalsStatus, OMDetailsOrgPurchasers } from './types';

export const selectOMDetailsSelectedOrder = (state: RootState): Order | undefined =>
  state.orderManagement.details?.selectedOrder ?? undefined;

export const selectOMDetailsPurchasers = (state: RootState): OMDetailsOrgPurchasers[] =>
  state.orderManagement.details.selectablePurchasers;

export const selectOMModalStatus =
  (modalStatus: OMDetailsModalsStatus) => (state: RootState): boolean =>
    state.orderManagement.details[modalStatus];

export const selectOMCancelModalStatus = selectOMModalStatus('cancelOrderOpen');
export const selectOMCreateOrderModalStatus = selectOMModalStatus('createOrderOpen');
export const selectOMOrderDetailsModalStatus = selectOMModalStatus('detailsModalOpen');
