/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateField } from '@mui/x-date-pickers/DateField';
import moment from 'moment';
import { DATE_TIME_MODES } from 'components/complex_filter/constants';
import * as DateTimeStyles from './styles';
import { FilterStateKey } from 'store/shared/types';
import DateTimeProps from './types';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DateTime = (props: DateTimeProps) => {
  const styles = DateTimeStyles;
  const ref = React.useRef(null);

  const {
    placeholder,
    filterKey,
    filterSubTree,
    selectDateFilterMode,
    onDateModeChange,
    onOpenInput,
    onCloseInput,
    selectFilterOpen,
    selectDateRange,
    selectPickedDate,
    setDateRangeInputChange,
    onRangeCalendarChange,
    onAfterDateCalendarChange,
    onBeforeDateCalendarChange,
    onClearDateByModeKey,
    onSetBeforeDateInputChange,
    onSetAfterDateInputChange,
    onResetFilterByKey,
  } = props;

  const getCurrentMode = selectDateFilterMode(
    filterSubTree as FilterStateKey,
    filterKey,
  );
  const getCalendarOpen = selectFilterOpen(
    filterSubTree as FilterStateKey,
    filterKey,
  );
  const getDateRange = selectDateRange(filterSubTree as FilterStateKey, filterKey);
  const getBeforeDate = selectPickedDate(
    filterSubTree as FilterStateKey,
    filterKey,
    'before',
  );
  const getAfterDate = selectPickedDate(
    filterSubTree as FilterStateKey,
    filterKey,
    'after',
  );

  const currentMode = useSelector(getCurrentMode);
  const calendarOpen = useSelector(getCalendarOpen);
  const incomingDateRange = useSelector(getDateRange);
  const incomingBeforeDate = useSelector(getBeforeDate);
  const incomingAfterDate = useSelector(getAfterDate);

  if (incomingDateRange === undefined) {
    console.error('Incoming Range (range) was undefined.');
    return <></>;
  }

  if (incomingBeforeDate === undefined) {
    console.error('Incoming Before Date was undefined.');
    return <></>;
  }

  if (incomingAfterDate === undefined) {
    console.error('Incoming After Date was undefined.');
    return <></>;
  }

  const showRangeClear =
    DATE_TIME_MODES.range === currentMode && incomingDateRange.every(Boolean);
  const showAfterDateClear =
    DATE_TIME_MODES.after === currentMode && incomingAfterDate;
  const showBeforeDateClear =
    DATE_TIME_MODES.before === currentMode && incomingBeforeDate;

  const showClearButton = [
    showRangeClear,
    showAfterDateClear,
    showBeforeDateClear,
  ].some(Boolean);

  const momentBeforeDate = moment(incomingBeforeDate)?.isValid()
    ? moment(incomingBeforeDate)
    : null;
  const momentAfterDate = moment(incomingAfterDate)?.isValid()
    ? moment(incomingAfterDate)
    : null;

  const momentStartDate = moment(incomingDateRange[0])?.isValid()
    ? moment(incomingDateRange[0])
    : null;

  const momentEndDate = moment(incomingDateRange[1])?.isValid()
    ? moment(incomingDateRange[1])
    : null;

  const handleOpenCalendar = () => {
    onOpenInput(filterKey);
  };

  const handleCloseCalendar = () => {
    onCloseInput();
  };

  return (
    <>
      <Box sx={styles.DateBoxSx} ref={ref}>
        {DATE_TIME_MODES.range === currentMode ? (
          <SingleInputDateRangeField
            onChange={setDateRangeInputChange(filterKey)}
            value={[momentStartDate, momentEndDate]}
            sx={styles.RangeInputSx}
            label={placeholder}
          />
        ) : (
          <></>
        )}
        {DATE_TIME_MODES.before === currentMode ? (
          <DateField
            onChange={onSetBeforeDateInputChange(filterKey)}
            label={placeholder}
            sx={styles.DateInputSx}
            value={momentBeforeDate}
          />
        ) : (
          <></>
        )}
        {DATE_TIME_MODES.after === currentMode ? (
          <DateField
            onChange={onSetAfterDateInputChange(filterKey)}
            label={placeholder}
            sx={styles.DateInputSx}
            value={momentAfterDate}
          />
        ) : (
          <></>
        )}

        <IconButton
          aria-label="toggleCalendar"
          onClick={handleOpenCalendar}
          sx={styles.CalendarButtonSx}
        >
          <CalendarTodayIcon />
        </IconButton>
        {showClearButton ? (
          <IconButton
            aria-label="clearOptionButton"
            // onClick={onClearDateByModeKey(filterKey, currentMode)}
            onClick={onResetFilterByKey(filterKey)}
            sx={styles.ClearButtonSx}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Box>
      {calendarOpen ? (
        <Popover
          anchorEl={ref.current || null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={calendarOpen}
          onClose={handleCloseCalendar}
          sx={styles.PopoverSx}
        >
          <Stack direction="column">
            <ToggleButtonGroup
              exclusive
              size="small"
              value={currentMode}
              onChange={onDateModeChange(filterKey)}
              sx={styles.ToggleButtonGroupSx}
            >
              <ToggleButton
                sx={styles.ToggleButtonSx}
                value={DATE_TIME_MODES.range}
              >
                Date range
              </ToggleButton>
              <ToggleButton
                sx={styles.ToggleButtonSx}
                value={DATE_TIME_MODES.before}
              >
                Before
              </ToggleButton>
              <ToggleButton
                sx={styles.ToggleButtonSx}
                value={DATE_TIME_MODES.after}
              >
                After
              </ToggleButton>
            </ToggleButtonGroup>
            {DATE_TIME_MODES.range === currentMode ? (
              <DateRangeCalendar
                value={[momentStartDate, momentEndDate]}
                onChange={onRangeCalendarChange(filterKey)}
                calendars={2}
                sx={styles.RangeCalendarSx}
                focusedView={null}
                disableFuture
              />
            ) : (
              <></>
            )}

            {DATE_TIME_MODES.before === currentMode ? (
              <DateRangeCalendar
                value={[momentBeforeDate, null]}
                onChange={onBeforeDateCalendarChange(filterKey)}
                availableRangePositions={['start']}
                calendars={2}
                sx={styles.RangeCalendarSx}
                disableFuture
              />
            ) : (
              <></>
            )}

            {DATE_TIME_MODES.after === currentMode ? (
              <DateRangeCalendar
                value={[momentAfterDate, null]}
                onChange={onAfterDateCalendarChange(filterKey)}
                availableRangePositions={['start']}
                calendars={2}
                sx={styles.RangeCalendarSx}
                disableFuture
              />
            ) : (
              <></>
            )}
          </Stack>
        </Popover>
      ) : (
        <></>
      )}
    </>
  );
};

export default DateTime;
