/* eslint-disable sonarjs/cognitive-complexity */
import moment from 'moment';
import { FilterEntry } from 'store/shared/types';
import { OrderView } from 'pages/OrderManagement/enum';
import { OMDashFilterKeys } from 'pages/OrderManagement/filters/shared/constants';
import { OrderType, OrderStatus } from 'global-constants';
import { getFilterOpFromEntries } from 'store/shared/utils';
import { UNASSIGNED_ORDER_MANAGER_ID_VALUE } from 'pages/OrderManagement/constants';

export const getOMWhereClauseFromComboSearch = (
  lookAheadOptionType: string,
  lookAheadOption: string
) => {
  if (!lookAheadOption) {
    return {
      createdAt: { gt: 1 },
    };
  }

  const recipientWhereContents = getCollaboratorIdsWhereClause(lookAheadOption);

  return lookAheadOptionType === 'orderNumber'
    ? { [lookAheadOptionType]: { equals: lookAheadOption } }
    : {
        recipient: {
          is: recipientWhereContents,
        },
      };
};

export const getOMWhereClauseFromFilterEntries = (
  filterEntries: FilterEntry[],
  options: Record<string, any> = {}
) => {
  const { orderManagerFilter = '' } = options;

  const whereClauses: any[] = [];
  let hasOrderManagerFilterEntries = false;

  if (!filterEntries.length && !orderManagerFilter) return whereClauses;

  const filterLookup: Record<string, FilterEntry[]> = {};
  filterEntries.forEach((entry: FilterEntry) => {
    const { key } = entry;
    if (!filterLookup[key]) {
      filterLookup[key] = [entry];
    } else {
      filterLookup[key].push(entry);
    }
  });

  let recipientIds: string[] = [];
  let purchaserIds: string[] = [];
  const filterKeys = Object.keys(filterLookup);

  if (filterKeys.length) {
    filterKeys.forEach((filterKey) => {
      const filterValues = filterLookup[filterKey].map((entry) => entry.value);
      const filterEntry = filterLookup[filterKey][0];

      const filterOp = getFilterOpFromEntries(filterValues);
      const passedValue =
        filterValues.length === 1 ? filterValues[0] : filterValues;
      switch (filterKey) {
        case OMDashFilterKeys.ORDER_NUMBER:
          whereClauses.push({ id: { [filterOp]: passedValue } });
          break;
        case OMDashFilterKeys.ORDER_TYPE:
          whereClauses.push({ orderTypeId: { [filterOp]: passedValue } });
          break;
        case OMDashFilterKeys.ORDER_DATE:
          applyOrderDateQuery(filterEntry.id, whereClauses, 'createdAt');
          break;
        case OMDashFilterKeys.DUE_DATE:
          applyOrderDateQuery(filterEntry.id, whereClauses, 'dueDate');
          break;
        case OMDashFilterKeys.STATUS:
          whereClauses.push({ status: { [filterOp]: passedValue } });
          break;
        case OMDashFilterKeys.DEPOT:
          whereClauses.push({ depotId: { [filterOp]: passedValue } });
          break;
        case OMDashFilterKeys.ORGANIZATION:
          whereClauses.push({ organizationId: { [filterOp]: passedValue } });
          break;
        case OMDashFilterKeys.SHIPPING_SELECTION:
          whereClauses.push({ shippingTypeId: { [filterOp]: passedValue } });
          break;
        case OMDashFilterKeys.ORDER_MANAGER:
          hasOrderManagerFilterEntries = true;
          applyOrderManagerQuery(
            filterValues,
            whereClauses,
            orderManagerFilter
          );
          break;
        case OMDashFilterKeys.TRACKING_NUMBER:
          whereClauses.push(getOMTrackingWhereClause(filterValues[0]));
          break;
        case OMDashFilterKeys.CUSTOMER_NOTES:
          whereClauses.push(
            getOMHasCustomerNotesWhereClause()
          );
          break;
        case OMDashFilterKeys.PURCHASER:
        case OMDashFilterKeys.PURCHASER_EMAIL:
          purchaserIds = purchaserIds.concat(filterValues as string[]);
          break;
        case OMDashFilterKeys.RECIPIENT:
        case OMDashFilterKeys.RECIPIENT_EMAIL:
          recipientIds = recipientIds.concat(filterValues as string[]);
          break;
        default:
          break;
      }
    });
  }

  if (!hasOrderManagerFilterEntries && orderManagerFilter) {
    applyOrderManagerQuery([], whereClauses, orderManagerFilter);
  }

  if (recipientIds.length) {
    const recipientsInnerClause = getCollaboratorIdsWhereClause(recipientIds);

    whereClauses.push({
      recipient: {
        is: recipientsInnerClause,
      },
    });
  }

  if (purchaserIds.length) {
    const purchasersInnerClause = getCollaboratorIdsWhereClause(
      purchaserIds,
      'purchaserId'
    );

    whereClauses.push(purchasersInnerClause);
  }

  return whereClauses;
};

export const applyOrderDateQuery = (
  dateString: string,
  whereClauses: any[],
  dateField: string
) => {
  const dateParts = dateString.split('::');
  const dateType = dateParts[1];

  if (dateType === 'after') {
    const selectedDate = moment(dateParts[2], 'MM/DD/YYYY').utc();
    if (selectedDate.isValid()) {
      whereClauses.push({ [dateField]: { gt: selectedDate.format() } });
    }
  } else if (dateType === 'before') {
    const selectedDate = moment(dateParts[2], 'MM/DD/YYYY').add(1, 'day').utc();
    if (selectedDate.isValid()) {
      whereClauses.push({ [dateField]: { lt: selectedDate.format() } });
    }
  } else if (dateType === 'range') {
    const rangeParts = dateParts[2]?.split('-') || [];
    const selectedAfterDate = moment(rangeParts[0], 'MM/DD/YYYY').utc();
    const selectedBeforeDate = moment(rangeParts[1], 'MM/DD/YYYY')
      .add(1, 'day')
      .utc();
    if (selectedAfterDate.isValid() && selectedBeforeDate.isValid()) {
      whereClauses.push({
        [dateField]: {
          gt: selectedAfterDate.format(),
          lt: selectedBeforeDate.format(),
        },
      });
    }
  }
};

export const applyOrderManagerQuery = (
  passedOrderManagerIds: string[],
  whereClauses: any[],
  orderManagerFilter: string
) => {
  const hasUnassigned = passedOrderManagerIds.includes(
    UNASSIGNED_ORDER_MANAGER_ID_VALUE
  );
  const cleanOrderManagerIds = passedOrderManagerIds
    .slice()
    .filter((id) => id !== UNASSIGNED_ORDER_MANAGER_ID_VALUE);
  const filterOp = getFilterOpFromEntries(cleanOrderManagerIds);

  if (hasUnassigned && orderManagerFilter) {
    console.error(
      'Cannot have both unassigned (filter drawer) & manager from the self-assign switch.'
    );
    return;
  }

  if (passedOrderManagerIds.length && orderManagerFilter) {
    console.error(
      'Cannot have both any passedOrderManagerIds & manager from the self-assign switch.'
    );
    return;
  }

  if (!cleanOrderManagerIds.length && orderManagerFilter) {
    whereClauses.push({
      orderManagerId: { equals: orderManagerFilter },
    });
  }

  if (!cleanOrderManagerIds.length && hasUnassigned) {
    whereClauses.push({
      orderManagerId: null,
    });
  }

  if (cleanOrderManagerIds.length && !hasUnassigned) {
    whereClauses.push({
      orderManagerId: {
        [filterOp]:
          cleanOrderManagerIds.length === 1
            ? cleanOrderManagerIds[0]
            : cleanOrderManagerIds,
      },
    });
  }

  if (cleanOrderManagerIds.length && hasUnassigned) {
    whereClauses.push({
      OR: [
        {
          orderManagerId: {
            [filterOp]:
              cleanOrderManagerIds.length === 1
                ? cleanOrderManagerIds[0]
                : cleanOrderManagerIds,
          },
        },
        {
          orderManagerId: null,
        },
      ],
    });
  }
};

export const getCollaboratorIdsWhereClause = (
  collaboratorIds: string[] | string,
  fieldName: string = 'collaboratorId'
) => {
  const filterOp = getFilterOpFromEntries(collaboratorIds);

  const isSoloArray =
    Array.isArray(collaboratorIds) && collaboratorIds.length === 1;
  return {
    [fieldName]: {
      [filterOp]: isSoloArray ? collaboratorIds[0] : collaboratorIds,
    },
  };
};

export const getOMTabsWhereClauses = (
  orderTypeLookup: Record<string, any>,
  tab: OrderView
) => {
  const whereClauses: any[] = [];

  switch (tab) {
    case OrderView.ALL_ORDERS:
      break;
    case OrderView.ALL_NEW_ORDERS:
      whereClauses.push({
        status: {
          in: [
            OrderStatus.CREATED as string,
            OrderStatus.PENDING_RECIPIENT_INFORMATION as string,
          ],
        },
      });
      break;
    case OrderView.PROCUREMENT:
      whereClauses.push({
        orderTypeId: {
          in: [
            orderTypeLookup[OrderType.PROCUREMENT_TO_DEPOT].id,
            orderTypeLookup[OrderType.PROCUREMENT_TO_RECIPIENT].id,
          ],
        },
      });
      break;
    case OrderView.KITS:
      whereClauses.push({
        hasKits: { equals: true },
      });
      break;
    case OrderView.RETRIEVALS:
      whereClauses.push({
        orderTypeId: {
          in: [
            orderTypeLookup[OrderType.BULK_RETRIEVAL].id,
            orderTypeLookup[OrderType.DROP_RETRIEVAL].id,
            orderTypeLookup[OrderType.RETRIEVAL].id,
          ],
        },
      });
      break;
    case OrderView.REDEPLOYMENT:
      whereClauses.push({
        orderTypeId: {
          in: [orderTypeLookup[OrderType.DEPLOYMENT].id],
        },
      });
      break;
    case OrderView.CANCELLED:
      whereClauses.push({
        status: {
          equals: 'CANCELED',
        },
      });
      break;
    case OrderView.EXPIRED:
      whereClauses.push({
        status: {
          in: [
            OrderStatus.EXPIRED_PENDING_RETURN as string,
            OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION as string,
          ],
        },
      });
      break;
    default:
      break;
  }

  return whereClauses;
};

export const getOMTrackingWhereClause = (trackingId: string) => ({
  shipments: {
    some: {
      trackings: {
        some: {
          id: {
            equals: trackingId,
          },
        },
      },
    },
  },
});

export const getOMHasCustomerNotesWhereClause = () => ({
  AND: [{ checkoutNotes: { not: null } }, { checkoutNotes: { notIn: [''] } }],
});
