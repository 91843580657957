/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import ComplexFilterDrawer from 'components/complex_filter/ComplexFilterDrawer';
import { FILTER_STATE_KEYS } from 'store/constants';
import { useGetFilterDrawerConfig } from './hooks';

const OMDashComplexFilterDrawer = () => {
  const filterDrawerConfig = useGetFilterDrawerConfig(
    FILTER_STATE_KEYS.ORDER_MANAGEMENT
  );

  if (!filterDrawerConfig) return <></>;

  return <ComplexFilterDrawer {...filterDrawerConfig} />;
};

export default OMDashComplexFilterDrawer;
