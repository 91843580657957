import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';

import { LoadingState } from 'store/slices/global/types';
import { OrdersState, Order } from './types';
import { getOrders } from './actions';

export const setInitialOrdersReducer = (
  state: WritableDraft<OrdersState>,
  action: PayloadAction<Order[]>
) => {
  state.orders = action.payload;
};

export const setOrdersReducer = (
  state: WritableDraft<OrdersState>,
  action: PayloadAction<Order[]>
) => {
  state.orders = state.orders.concat(action.payload);
};

export const setOrdersCountReducer = (
  state: WritableDraft<OrdersState>,
  action: PayloadAction<number>
) => {
  state.count = action.payload;
};

export const setOrdersSkipReducer = (
  state: WritableDraft<OrdersState>,
  action: PayloadAction<number>
) => {
  state.skip = action.payload;
};

export const setOrdersTakeReducer = (
  state: WritableDraft<OrdersState>,
  action: PayloadAction<number>
) => {
  state.take = action.payload;
};

export const setOrdersLoadingReducer = (
  state: WritableDraft<OrdersState>,
  action: PayloadAction<LoadingState>
) => {
  state.loading = action.payload;
};

export const getOrdersPendingReducer: CaseReducer<
  OrdersState,
  ReturnType<typeof getOrders.pending>
> = (state, action) => {
  state.loading = 'pending';
};

export const getOrdersFulfilledReducer: CaseReducer<
  OrdersState,
  ReturnType<typeof getOrders.fulfilled>
> = (state, action) => {
  state.loading = 'fulfilled';
  if (!action.meta.arg.append) {
    state.orders = action.payload.data['v2FindOrdersByStatusTransition'].orders;
    state.count = action.payload.data['v2FindOrdersByStatusTransition'].count;
    state.skip = state.take;
  } else {
    state.orders = state.orders.concat(
      action.payload.data['v2FindOrdersByStatusTransition'].orders
    );
    state.count = action.payload.data['v2FindOrdersByStatusTransition'].count;
    state.skip += state.take;
  }
};

export const getOrdersRejectedReducer: CaseReducer<
  OrdersState,
  ReturnType<typeof getOrders.rejected>
> = (state, action) => {
  state.loading = 'rejected';
};

export const getHoursSavedRejectedReducer: CaseReducer<
  OrdersState,
  ReturnType<typeof getOrders.rejected>
> = (state, action) => {
  state.hoursSavedLoading = 'rejected';
};

export const getHoursSavedsPendingReducer: CaseReducer<
  OrdersState,
  ReturnType<typeof getOrders.pending>
> = (state, action) => {
  state.hoursSavedLoading = 'pending';
};

export const getHoursSavedFulfilledReducer: CaseReducer<
  OrdersState,
  ReturnType<typeof getOrders.fulfilled>
> = (state, action) => {
  state.hoursSavedLoading = 'fulfilled';
  state.hoursSaved = action.payload.data['v2CalculateHoursOfSavedWork'];
};
