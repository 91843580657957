import React from 'react';
import { useDispatch } from 'react-redux';

import { setActiveTab } from 'store/slices/ui/home';
import { ActiveTab } from 'store/slices/ui/home/types';

import { resetState as resetHomeUIState } from 'store/slices/ui/home';
import { resetState as resetTotalsState } from 'store/slices/home/totals';
import { resetState as resetOrdersState } from 'store/slices/home/orders';
import { resetState as resetAssetsState } from 'store/slices/home/assets';

import useGetUserOrganization from 'hooks/useGetUserOrganization';

import { getAssets } from 'store/slices/home/assets/actions';
import { getOrders } from 'store/slices/home/orders/actions';
import { AppDispatch } from 'store';
import { getInitialAssetsVariables, getInitialOrdersVariables } from './utils';

export const useResetHomeUIStateOnUmount = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetHomeUIState());
    };
  }, []);
};

export const useResetAssetsOnUnmount = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetAssetsState());
    };
  }, []);
};

export const useResetOrdersOnUnmount = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetOrdersState());
    };
  }, []);
};

export const useResetTotalsOnUnmount = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetTotalsState());
    };
  }, []);
};

export const useHandleOnClick = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();

  return (tab: ActiveTab) =>
    React.useCallback(() => {
      dispatch(setActiveTab(tab));
      if (tab === 'assets' && organization?.id) {
        dispatch(getOrders(getInitialOrdersVariables(organization)));
      } else if (tab === 'orders' && organization?.id) {
        dispatch(getAssets(getInitialAssetsVariables(organization)));
      }
    }, [organization]);
};

export const useGetInitialAssetsOnOrganizationChange = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();

  React.useEffect(() => {
    if (organization?.id) {
      dispatch(getAssets(getInitialAssetsVariables(organization)));
    }
  }, [organization]);
};

export const useGetInitialOrdersOnOrganizationChange = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();

  React.useEffect(() => {
    if (organization?.id) {
      dispatch(getOrders(getInitialOrdersVariables(organization)));
    }
  }, [organization]);
};
