import React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import Popover from '@mui/material/Popover';

import Avatar from 'components/Avatar';
import ListItem from '@mui/material/ListItem';
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from '@mui/material/Skeleton';

import { getInitials } from 'services/getInitials';
import { useCollaboratorControllerSearch } from 'services/openapi/apiComponents';
import { UserAvatarProps } from './types';
import { AVATAR_SIZE } from './constants';
import { UserPermissions } from 'global-constants';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import {
  UserAvatarPopoverSx,
  AvatarContainerSx,
  ListContainerSx,
} from './styles';
import { capitalizeWords } from 'services/format';
import {
  useHandleOnClickToggle,
  useHandlePopoverOnClose,
  useHandleGotoBilling,
  useHandleGotoUserPermissions,
  useHandleLogout,
} from './hooks';
import { selectGlobalAvatarPopover } from 'store/slices/global/selectors';

const UserAvatar = (props: UserAvatarProps) => {
  const ref = React.useRef();

  const { navigator } = props;
  const theme = useTheme();
  const globalAvatarPopover = useSelector(selectGlobalAvatarPopover);
  const userPermissions = useGetUserPermissions();

  const handleOnClickToggle = useHandleOnClickToggle();
  const handlePopoverOnClose = useHandlePopoverOnClose();

  const { user, isAuthenticated, isLoading } = useAuth0();

  const { data, isLoading: isFetchingLoggedInUser } =
    useCollaboratorControllerSearch(
      {
        queryParams: {
          email: user?.email,
        },
      },
      {
        enabled: !!user?.email,
        retry: false,
      }
    );

  const billingVisible = userPermissions.includes(
    UserPermissions.BILLING__VIEW
  );

  const handleGotoBilling = useHandleGotoBilling(navigator);
  const handleGotoUserPermissions = useHandleGotoUserPermissions(navigator);
  const handleLogout = useHandleLogout();

  if (!isAuthenticated || isLoading) return <></>;

  const loggedInUser = data?.items[0];
  const loggedInUserInitial = getInitials(loggedInUser);

  if (isFetchingLoggedInUser) {
    return (
      <Skeleton
        variant="circular"
        animation="wave"
        width={AVATAR_SIZE}
        height={AVATAR_SIZE}
      />
    );
  }

  return (
    <div>
      <Stack ref={ref} onClick={handleOnClickToggle} sx={AvatarContainerSx}>
        <Avatar
          text={loggedInUserInitial}
          bgcolor={theme.palette.colors.white}
          width={AVATAR_SIZE}
          height={AVATAR_SIZE}
        />
      </Stack>
      <Popover
        open={globalAvatarPopover.show}
        onClose={handlePopoverOnClose}
        anchorEl={ref.current || null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={UserAvatarPopoverSx}
      >
        <List sx={ListContainerSx}>
          <ListItem className="username-dropdown">
            {capitalizeWords(user?.nickname ?? '')}
          </ListItem>
          <ListItem className="user-email-dropdown">
            {loggedInUser?.workEmail || user?.email}
          </ListItem>
          <Divider />
          <ListItemButton onClick={handleGotoUserPermissions}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            User Permissions
          </ListItemButton>
          {billingVisible ? (
            <ListItemButton onClick={handleGotoBilling}>
              <ListItemIcon>
                <PaymentOutlinedIcon />
              </ListItemIcon>
              Billing
            </ListItemButton>
          ) : null}
          <Divider />
          <ListItemButton onClick={handleLogout}>SIGN OUT</ListItemButton>
        </List>
      </Popover>
    </div>
  );
};

export default UserAvatar;
