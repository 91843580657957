export const POPOVER_MAX_HEIGHT_PAPER = '700px';
export const POPOVER_MAX_WIDTH_PAPER = '345px';
export const POPOVER_HEADER_HEIGHT = '120px';
export const POPOVER_FOOTER_MAX_HEIGHT = '60px';
export const ICON_TOP = '2px';
export const POPOVER_CONTENT_MAX_HEIGHT = `calc(${POPOVER_MAX_HEIGHT_PAPER} - ${POPOVER_HEADER_HEIGHT} - ${POPOVER_FOOTER_MAX_HEIGHT} - ${ICON_TOP})`;

export const ColumnButtonBoxSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const IconTextSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  top: ICON_TOP,
  '&.MuiTypography-root.MuiTypography-button': {
    position: 'relative',
    top: '1px',
  },
};

export const PopoverContainerSx = {
  display: 'flex',
  padding: '0',
  margin: '0',
};

export const PopoverPaperContainerSx = (changed: boolean) => ({
  display: 'flex',
  height: !changed
    ? `calc(${POPOVER_MAX_HEIGHT_PAPER} - ${POPOVER_FOOTER_MAX_HEIGHT})`
    : POPOVER_MAX_HEIGHT_PAPER,
  width: POPOVER_MAX_WIDTH_PAPER,
});

export const ContainerSx = {
  display: 'flex',
};

export const ColumnSelectContainer = {
  height: '30px',
  borderRadius: '30px',
  pl: '10px',
  pr: '10px',
  pt: '2px',
  pb: '2px',
  ':hover': {
    backgroundColor: 'rgba(19, 20, 20, 0.04)',
  },
  width: '112px',
};
