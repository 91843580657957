import { combineSlices } from '@reduxjs/toolkit';
import { ordersSlice } from './orders';
import { assetsSlice } from './assets';
import { totalsSlice } from './totals';

export const homeSlice = combineSlices({
  orders: ordersSlice.reducer,
  assets: assetsSlice.reducer,
  totals: totalsSlice.reducer,
});
