import React from 'react';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';

import { a11yProps } from 'globals/utils/a11y';
import * as styles from './styles';
import { ListTabProps } from './types';

const ListTab = (props: ListTabProps) => {
  const { label, count, id, onClick, active } = props;
  const MuiIcon = props.muiIcon;
  const CustomIcon = props.customIcon;
  let icon = null;
  if (MuiIcon) {
    icon = <MuiIcon />;
  } else if (CustomIcon) {
    icon = CustomIcon({
      fill: active ? 'black' : 'rgba(0, 0, 0, 0.57)',
      stroke: active ? 'black' : 'rgba(0, 0, 0, 0.57)',
      width: 20,
      height: 20,
    });
  }
  return (
    <Tab
      onClick={onClick}
      aria-label="Assets and Orders Tabs List"
      {...a11yProps(id)}
      label={
        <Stack sx={styles.ListTabContainerSx}>
          <Stack sx={styles.ListTabIconSx(active)}>{icon}</Stack>
          <Stack sx={styles.ListTabLabelSx(active)}>{label}</Stack>
          <Stack sx={styles.ListTabCountSx(active)}>{count}</Stack>
        </Stack>
      }
    />
  );
};

export default ListTab;
