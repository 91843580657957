import { createSlice } from '@reduxjs/toolkit';
import { OrderView } from 'pages/OrderManagement/enum';
import { OM_VIEW_META } from './constants';
import { setOMTabReducer } from './reducers';
import OMPageViewState from './types';

export const INITIAL_OM_TAB_VIEW_STATE: OMPageViewState = {
  view: OrderView.ALL_ORDERS,
  meta: OM_VIEW_META.ALL_ORDERS,
  disableStatusFilter: false,
  disableOrderTypeFilter: false,
};

export const orderManagementTabSlice = createSlice({
  name: 'OMTabSlice',
  initialState: INITIAL_OM_TAB_VIEW_STATE,
  reducers: {
    setOMTabReducer,
    resetOMTabReducer: () => INITIAL_OM_TAB_VIEW_STATE,
  },
});

export const {
  resetOMTabReducer: resetOMTabView,
  setOMTabReducer: setOMTabView,
} = orderManagementTabSlice.actions;
