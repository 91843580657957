import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearShowNewUploadNotification,
  resetHistoricUploadQuery,
  resetUploadErrorsDownloadState,
  resetUploadFileState,
  resetUploadTemplateDownloadState,
  setHistoricUploadQuery,
  setPopoverFile,
  setPopoverOpenState,
  setSelectedUploadState,
  setUploads,
  setUploadViewed,
  togglePopoverHistoryLookbackDropdown,
} from 'store/slices/assets/csv';
import {
  downloadAssetErrors,
  downloadAssetTemplate,
  getAssetUploadCollaborator,
  searchAssetUploads,
  uploadAssets,
} from 'store/slices/assets/csv/actions';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { AppDispatch } from 'store';

import {
  getSelectedUpload,
  selectAssetNewUploadCount,
  selectAssetShowNewUploadNotification,
  selectAssetUploadErrorMessage,
  selectAssetUploadErrorsData,
  selectAssetUploadErrorsLoadingState,
  selectAssetUploadFileUploadLoadingState,
  selectAssetUploadHistoricUploadQuery,
  selectAssetUploadNewUploadQuery,
  selectAssetUploadPopoverFile,
  selectAssetUploadPopoverHistoryLookbackDropdownOpen,
  selectAssetUploadPopoverOpen,
  selectAssetUploads,
  selectAssetUploadTemplateData,
  selectAssetUploadTemplateLoadingState,
  selectAssetUploadViewable,
} from 'store/slices/assets/csv/selectors';
import { notificationSlice } from 'store/slices';
import { createCSVFile } from 'shared/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { UPLOAD_LOCALSTORAGE_KEY, UPLOAD_SUCCESS_MESSAGE } from './constants';
import { ViewableUploadMetadata } from 'store/shared/uploads/types';
import { getLookbackTimestamp } from 'store/shared/uploads/utils';
import { getErrorsFileName, getPersistableUploads } from './utils';
import * as storage from 'api/storage';
import { UploadStatus } from 'api/rest/uploads/constants';

export const useHandleDownloadTemplateClick = () => {
  const dispatch = useDispatch<AppDispatch>();
  const templateData = useSelector(selectAssetUploadTemplateData);

  return React.useCallback(() => {
    if (!templateData) {
      dispatch(downloadAssetTemplate());
    }
  }, []);
};

export const useHandleDownloadErrorsClick = () => {
  const organization = useGetUserOrganization();
  const dispatch = useDispatch<AppDispatch>();
  const selectedUpload = useSelector(getSelectedUpload);

  return React.useCallback(() => {
    if (organization && selectedUpload?.timestamp) {
      dispatch(
        downloadAssetErrors({
          organizationId: organization.id,
          timestamp: selectedUpload.timestamp,
        })
      );
    }
  }, [selectedUpload, organization]);
};

export const useHandleTemplateCSVDownloadError = () => {
  const loadingState = useSelector(selectAssetUploadTemplateLoadingState);
  const errorMessage = useSelector(selectAssetUploadErrorMessage);
  const dispatch = useDispatch();
  return React.useEffect(() => {
    if (loadingState === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: errorMessage,
          horizontalOrigin: 'right',
          verticalOrigin: 'bottom',
        })
      );
    }
  }, [loadingState]);
};

export const useHandleErrorsCSVDownloadError = () => {
  const loadingState = useSelector(selectAssetUploadErrorsLoadingState);
  const errorMessage = useSelector(selectAssetUploadErrorMessage);
  const dispatch = useDispatch();
  return React.useEffect(() => {
    if (loadingState === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: errorMessage,
          horizontalOrigin: 'right',
          verticalOrigin: 'bottom',
        })
      );
    }
  }, [loadingState]);
};

export const useTemplateFileDownload = () => {
  const data = useSelector(selectAssetUploadTemplateData);
  const dispatch = useDispatch()
  return React.useEffect(() => {
    if (data) {
      createCSVFile(data, 'asset_template', 'csv');
    }

    return () => {
      dispatch(resetUploadTemplateDownloadState());
    }
  }, [data]);
};

export const useErrorsFileDownload = () => {
  const data = useSelector(selectAssetUploadErrorsData);
  const selectedUpload = useSelector(getSelectedUpload);
  const uploadFileName = selectedUpload?.originalFileName;
  const errorsFileName = getErrorsFileName(uploadFileName);
  const dispatch = useDispatch();

  return React.useEffect(() => {
    if (data) {
      createCSVFile(data, errorsFileName, 'csv');
    }

    return () => {
      dispatch(resetUploadErrorsDownloadState());
    }
  }, [data]);
};

export const useHandleSearchUploadsOnUploadFileComplete = () => {
  const loadingState = useSelector(selectAssetUploadFileUploadLoadingState);
  const newUploadQuery = useSelector(selectAssetUploadNewUploadQuery);
  const organization = useGetUserOrganization();
  const dispatch = useDispatch<AppDispatch>();
  return React.useEffect(() => {
    if (loadingState === 'fulfilled') {
      const searchFrom = getLookbackTimestamp(newUploadQuery.lookback);
      if (searchFrom)
        dispatch(
          searchAssetUploads({ organizationId: organization?.id, searchFrom })
        );
    }
  }, [loadingState]);
};

export const useHandleUploadFileResultNotification = () => {
  const loadingState = useSelector(selectAssetUploadFileUploadLoadingState);
  const errorMessage = useSelector(selectAssetUploadErrorMessage);
  const dispatch = useDispatch();
  return React.useEffect(() => {
    if (loadingState === 'fulfilled') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: UPLOAD_SUCCESS_MESSAGE,
          horizontalOrigin: 'right',
          verticalOrigin: 'bottom',
        })
      );
    }
    if (loadingState === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: errorMessage,
          horizontalOrigin: 'right',
          verticalOrigin: 'bottom',
        })
      );
    }

    return () => {
      dispatch(resetUploadFileState());
    }
      
  }, [loadingState]);
};

export const useHandleTogglePopover = () => {
  const dispatch = useDispatch<AppDispatch>();
  const open = useSelector(selectAssetUploadPopoverOpen);
  return React.useCallback(
    (open: boolean) => {
      dispatch(setPopoverOpenState(open));
      dispatch(setPopoverFile(null));
      dispatch(setSelectedUploadState(undefined));
      if (!open) {
        dispatch(resetHistoricUploadQuery());
      }
    },
    [open]
  );
};

export const useHandleSetPopoverFile = () => {
  const dispatch = useDispatch<AppDispatch>();
  return React.useCallback((file: File | null) => {
    dispatch(setPopoverFile(file));
  }, []);
};

export const useHandleFileUpload = () => {
  const file = useSelector(selectAssetUploadPopoverFile);
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();
  const collaborator = useAuth0().user;

  return React.useCallback(() => {
    if (file && organization && collaborator) {
      dispatch(
        uploadAssets({
          file,
          organizationId: organization.id,
          collaboratorId: collaborator.collaboratorId,
        })
      );
      dispatch(setPopoverFile(null));
    }
  }, [file, organization, collaborator]);
};

export const useSearchHistoricUploads = () => {
  const organization = useGetUserOrganization();
  const dispatch = useDispatch<AppDispatch>();
  const { limit, offset, lookback } = useSelector(
    selectAssetUploadHistoricUploadQuery
  );
  const open = useSelector(selectAssetUploadPopoverOpen);
  return React.useEffect(() => {
    const searchFrom = lookback > 0 ? getLookbackTimestamp(lookback) : undefined;
    if (limit && open) {
      dispatch(
        searchAssetUploads({
          organizationId: organization?.id,
          searchFrom,
          limit,
          offset,
        })
      );
    }
  }, [limit, offset, lookback, open]);
};

export const useHandleHistoryLookbackChange = () => {
  const dispatch = useDispatch();
  const { lookback } = useSelector(selectAssetUploadHistoricUploadQuery);
  return React.useCallback((newLookback: number) => {
      dispatch(
      setHistoricUploadQuery({
        lookback: newLookback,
      })
    );
  }, [lookback]);
};

export const useHandleHistoryPaginationChange = () => {
  const dispatch = useDispatch();
  const { offset } = useSelector(selectAssetUploadHistoricUploadQuery);
  return React.useCallback((offset: number) => {
    dispatch(
      setHistoricUploadQuery({
      offset,
  })
    );
  }, [offset]);
};

export const useHandleSetSelectedUploadState = () => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedUpload = useSelector(getSelectedUpload);
  const selectedUploadTimestamp = selectedUpload?.timestamp;
  return React.useCallback(
    (timestamp?: number) => {
      dispatch(setSelectedUploadState(timestamp));
    },
    [selectedUploadTimestamp]
  );
};

export const useHandleSearchRecentUploads = () => {
  const organization = useGetUserOrganization();
  const dispatch = useDispatch<AppDispatch>();
  const newUploadQuery = useSelector(selectAssetUploadNewUploadQuery);
  return React.useCallback(() => {
    const searchFrom = getLookbackTimestamp(newUploadQuery.lookback);
    if (searchFrom)
      dispatch(
        searchAssetUploads({ organizationId: organization?.id, searchFrom })
      );
  }, []);
};

export const loadUploadsFromLocalStorage = () => {
  const organization = useGetUserOrganization();
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (organization?.id) {
      const uploads = storage.getLocalStorageValue(
        `${UPLOAD_LOCALSTORAGE_KEY}-${organization?.id}`
      ) as ViewableUploadMetadata[];

      if (uploads?.length) 
      {
        dispatch(setUploads(uploads));
      } else {
        dispatch(setUploads([]));
      }
    }
  }, [organization]);
};

export const persistUploadsToLocalStorage = () => {
  const organization = useGetUserOrganization();
  const uploads = useSelector(selectAssetUploads);
  const open = useSelector(selectAssetUploadPopoverOpen);
  const newUploadQuery = useSelector(selectAssetUploadNewUploadQuery);
  const persistableLookback = newUploadQuery.lookback;
  React.useEffect(() => {
    if (organization && uploads && open) {
      const persistableUploads = getPersistableUploads(
        uploads,
        persistableLookback
      );
      storage.setLocalStorageValue(
        `${UPLOAD_LOCALSTORAGE_KEY}-${organization?.id}`,
        persistableUploads
      );
    }
  }, [open, uploads]);
};

export const useHandleNewUploadNotificationClick = () => {
  const dispatch = useDispatch<AppDispatch>();
  const newQuery = useSelector(selectAssetUploadNewUploadQuery);
  const historicQuery = useSelector(selectAssetUploadHistoricUploadQuery);
  const uploads = useSelector(selectAssetUploads);
  const open = useSelector(selectAssetUploadPopoverOpen);

  return React.useCallback(() => {
    const lookbackCutoffTimestamp = getLookbackTimestamp(newQuery.lookback);
    let earliestUnviewedIndex = uploads.length;
    let stop = false;

    while (earliestUnviewedIndex >= 0 && !stop) {
      // eslint-disable-next-line no-plusplus
      earliestUnviewedIndex--;
      const upload = uploads[earliestUnviewedIndex as number];
      if (
        !upload.viewed &&
        upload.status !== UploadStatus.PENDING &&
        upload.timestamp > lookbackCutoffTimestamp
      ) {
        stop = true;
      }
    }

    if (earliestUnviewedIndex > -1) {
      const offset =
        Math.floor(earliestUnviewedIndex / historicQuery.limit) *
        historicQuery.limit;
      dispatch(setHistoricUploadQuery({ ...historicQuery, offset }));
    }
    if (!open) {
      dispatch(setPopoverOpenState(true));
    }
  }, [uploads, open]);
};

export const useHandleCloseNewUploadNotification = () => {
  const showNewUploadNotification = useSelector(
    selectAssetShowNewUploadNotification
  );
  const dispatch = useDispatch<AppDispatch>();
  return React.useCallback(() => {
    dispatch(clearShowNewUploadNotification());
  }, [showNewUploadNotification]);
};

export const useSetUploadCollaborator = () => {
  const viewableUploads = useSelector(selectAssetUploadViewable);
  const dispatch = useDispatch<AppDispatch>();
  return React.useCallback(
    (upload: ViewableUploadMetadata) => {
      if (upload.collaboratorId && !upload.collaborator) {
        dispatch(
          getAssetUploadCollaborator({ collaboratorId: upload.collaboratorId })
        );
      }
    },
    [viewableUploads]
  );
};

export const useSetUploadsViewed = () => {
  const viewableUploads = useSelector(selectAssetUploadViewable);
  const open = useSelector(selectAssetUploadPopoverOpen);
  const dispatch = useDispatch<AppDispatch>();

  return React.useCallback(() => {
    if (open) {
      viewableUploads.forEach((upload) => {
        if (!upload.viewed) dispatch(setUploadViewed(upload.timestamp));
      });
    }
  }, [viewableUploads]);
};

export const useTogglePopoverHistoryLookbackDropdown = () => {
  const dispatch = useDispatch<AppDispatch>();
  const open = useSelector(selectAssetUploadPopoverHistoryLookbackDropdownOpen);
  return React.useCallback(
    (open: boolean) => {
      dispatch(togglePopoverHistoryLookbackDropdown(open));
    },
    [open]
  );
};

export const useGetConfig = () => {
  loadUploadsFromLocalStorage();
  persistUploadsToLocalStorage();
  useSearchHistoricUploads();
  useTemplateFileDownload();
  useErrorsFileDownload();
  useHandleTemplateCSVDownloadError();
  useHandleErrorsCSVDownloadError();
  useHandleSearchUploadsOnUploadFileComplete();
  useHandleUploadFileResultNotification();
  const collaborator = useAuth0().user;
  const viewableUploads = useSelector(selectAssetUploadViewable);
  const historicQuery = useSelector(selectAssetUploadHistoricUploadQuery);
  const selectedUpload = useSelector(getSelectedUpload);
  const open = useSelector(selectAssetUploadPopoverOpen);
  const file = useSelector(selectAssetUploadPopoverFile);
  const newUploadCount = useSelector(selectAssetNewUploadCount);
  const defaultLookback = useSelector(selectAssetUploadNewUploadQuery).lookback;
  const historyLookbackDropdownOpen = useSelector(
    selectAssetUploadPopoverHistoryLookbackDropdownOpen
  );
  const searchUploads = useHandleSearchRecentUploads();
  const onHistoryLookbackChange = useHandleHistoryLookbackChange();
  const onHistoryPagination = useHandleHistoryPaginationChange();
  const onUploadSelect = useHandleSetSelectedUploadState();
  const onFileUpload = useHandleFileUpload();
  const onDownloadTemplateClick = useHandleDownloadTemplateClick();
  const onDownloadErrorsClick = useHandleDownloadErrorsClick();
  const togglePopover = useHandleTogglePopover();
  const setFile = useHandleSetPopoverFile();
  const showNewUploadNotification = useSelector(
    selectAssetShowNewUploadNotification
  );
  const onNewUploadNotificationClick = useHandleNewUploadNotificationClick();
  const onCloseNewUploadNotification = useHandleCloseNewUploadNotification();
  const onToggleHistoryLookbackDropdown =
    useTogglePopoverHistoryLookbackDropdown();
  const pollingTimeoutConfig = process.env.REACT_APP_ASSET_UPLOADS_POLLING_TIMEOUT || '5000';
  const uploadPollingTimeout = parseInt(pollingTimeoutConfig, 10);

  return {
    domainLabel: 'assets',
    collaborator,
    defaultLookback,
    viewableUploads,
    historicQuery,
    selectedUpload,
    open,
    file,
    newUploadCount,
    showNewUploadNotification,
    historyLookbackDropdownOpen,
    togglePopover,
    setFile,
    searchUploads,
    onDownloadTemplateClick,
    onDownloadErrorsClick,
    onHistoryLookbackChange,
    onHistoryPagination,
    onUploadSelect,
    onFileUpload,
    onNewUploadNotificationClick,
    onCloseNewUploadNotification,
    onToggleHistoryLookbackDropdown,
    uploadPollingTimeout,
  };
};
