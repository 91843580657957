/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Organization, Purchaser } from 'types';
import { Button, Pill } from 'components';
import GenericAutocomplete from 'components/GenericAutocomplete';
import { useSafeMutation } from 'hooks/useSafeMutation';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import ConfirmOrderCreationCancelDialog from './components/ConfirmOrderCreationCancelDialog';
import {
  CREATE_ORDER_TYPE_SELECT_LABEL_ID,
  CREATE_ORDER_TYPE_SELECT_ID,
  CREATE_ORDER_PURCHASER_SELECT_LABEL_ID,
  CREATE_ORDER_PURCHASER_SELECT_ID,
} from './constants';
import { mapOrderFromResponse } from './utils';
import { CREATE_ORDER } from './mutations';
import * as CreateNewOrderModalStyles from './styles';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { SEARCH_ORGANIZATIONS } from './queries';
import GALoadingButton from 'components/buttons/google_analytics/GALoadingButton';
import useCreateOrderState from 'pages/OrderManagement/hooks/useCreateOrderState';
import { OrderType } from 'global-constants';
import CreateNewOrderModalProps from './types';
import { selectOMCreateOrderModalStatus } from 'store/slices/order_management/details/selectors';
import {
  toggleOMCreateModal,
  setOMSelectedOrder,
  toggleOMDetailsModal,
} from 'store/slices/order_management/details';
import {
  SEARCH_SHIPPING_TYPES,
  SEARCH_ORDERS,
} from 'pages/OrderManagement/queries';

const CreateNewOrderModal = (props: CreateNewOrderModalProps) => {
  const styles = CreateNewOrderModalStyles;
  const theme = useTheme();
  const dispatch = useDispatch();
  const client = useApolloClient();

  const {
    organizationId,
    purchaserId,
    getPurchaser,
    setPurchaserId,
    resetAllPendingOrderSelections,
    purchasersSelectable,
    setOrganizationId,
    resetPurchaser,
    isPurchaserDisabled,
  } = useCreateOrderState(props.orderTypeLookup);

  const isModalOpen = useSelector(selectOMCreateOrderModalStatus);

  const [orderType, setOrderType] = React.useState<string>('');

  const [openCancelDialog, setOpenCancelDialog] =
    React.useState<boolean>(false);

  const [organizationNameSearchTerm, setOrganizationNameSearchTerm] =
    React.useState('');
  const debouncedOrganizationNameSearchTerm = useDebouncedQuery(
    organizationNameSearchTerm
  );
  // Introducing a second piece of state for managing selected organization for autocomplete component
  const [selectedOrganization, setSelectedOrganization] = React.useState<
    Organization | undefined
  >(undefined);

  const orderTypesSelectable = Object.entries(OrderType) as [
    orderTypeEnumField: string,
    orderTypeEnumValue: string
  ][];

  const getOrderTypeId = (): string => {
    if (!orderType || !(orderType in props.orderTypeLookup)) return '';
    return props.orderTypeLookup[orderType].id;
  };

  const refetchQueries = async () => {
    await client.refetchQueries({
      include: [SEARCH_ORDERS],
    });
  };

  const { data, loading: isOrganizationsSearchLoading } = useSafeQuery(
    SEARCH_ORGANIZATIONS,
    {
      variables: {
        ...(debouncedOrganizationNameSearchTerm
          ? { name: debouncedOrganizationNameSearchTerm }
          : {}),
        limit: 100,
        offset: 0,
        orderAsc: 'name',
      },
    }
  );

  const organizations = data?.searchOrganizations.organizations || [];

  const handleOrderTypeChange = (event: SelectChangeEvent<string | null>) => {
    const newOrderType = event.target.value || '';
    if (orderType === newOrderType) return;
    setOrderType(newOrderType);
  };

  const handlePurchaserChange = (event: SelectChangeEvent<string | null>) => {
    const newPurchaserId = event.target.value || '';
    if (purchaserId === newPurchaserId) return;
    setPurchaserId(newPurchaserId);
  };

  const constructNewOrderPayload = () => {
    const { id: purchaserId } = getPurchaser() as Purchaser;
    const orderTypeId = getOrderTypeId();

    return {
      purchaserId,
      organizationId,
      orderTypeId,
    };
  };

  const handleCloseCreationModal = (openDetails: boolean = false) => {
    setOpenCancelDialog(false);
    setSelectedOrganization(undefined);
    setOrganizationNameSearchTerm('');
    resetAllPendingOrderSelections();
    dispatch(toggleOMCreateModal(false));

    if (openDetails) {
      dispatch(toggleOMDetailsModal(true));
    }
  };

  const [createNewOrder, { loading }] = useSafeMutation(CREATE_ORDER);

  const submitNewOrderCreation = (
    event: React.FormEventHandler<HTMLFormElement> | any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const newOrderPayload = constructNewOrderPayload();
    createNewOrder({
      variables: { ...newOrderPayload },
      onCompleted: (data) => {
        const newOrder = mapOrderFromResponse(data);
        dispatch(setOMSelectedOrder(newOrder));
        refetchQueries();
        handleCloseCreationModal(true);
      },
    });
  };

  const openCancelConfirmDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleOrganizationChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Organization
  ) => {
    const newOrgId = value.id || '';
    if (organizationId === newOrgId) return;
    setSelectedOrganization(value);
    setOrganizationId(newOrgId);
    resetPurchaser();
  };

  const isOrderCreationDisabled = (): boolean =>
    [orderType, organizationId, purchaserId].some((prop) => !prop) || loading;

  return (
    <>
      <Modal open={isModalOpen} onClose={openCancelConfirmDialog}>
        <form onSubmit={submitNewOrderCreation}>
          <Box sx={styles.CreateOrderBoxStyleSx}>
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{ height: '100%' }}
            >
              <Stack direction="column">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mt: '24px',
                    p: '24px',
                    pt: 0,
                    borderBottom: '1px solid rgba(19, 20, 20, 0.12)',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '24px', fontWeight: 600, lineHeight: 1 }}
                  >
                    Create new order
                  </Typography>
                  <IconButton onClick={openCancelConfirmDialog}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction="row"
                  sx={styles.CreateModalStepSectionSx}
                  alignItems="center"
                >
                  <Pill
                    text="Step 1/2"
                    color={theme.palette.colors.grayE0}
                    textColor={theme.palette.colors.offBlack}
                    chipOverrideSx={{
                      fontWeight: 600,
                      height: '24px',
                      mr: '8px',
                    }}
                  />
                  <span>Next: Add stock & Recipient info</span>
                </Stack>
                <Stack direction="column" sx={{ my: '24px' }}>
                  <Typography
                    sx={{ fontWeight: 700, lineHeight: 1, pl: '24px' }}
                  >
                    General Info
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ width: '100%', px: '24px' }}
                  justifyContent="space-between"
                >
                  <FormControl sx={styles.getCreateModalFormControlSx()}>
                    <InputLabel id={CREATE_ORDER_TYPE_SELECT_LABEL_ID}>
                      Select order type
                    </InputLabel>
                    <Select
                      id={CREATE_ORDER_TYPE_SELECT_ID}
                      labelId={CREATE_ORDER_TYPE_SELECT_LABEL_ID}
                      label="Select order type"
                      value={orderType}
                      onChange={handleOrderTypeChange}
                      sx={{
                        minWidth: '110px',
                      }}
                    >
                      {orderTypesSelectable.map(
                        ([orderTypeField, orderTypeName]) => (
                          <MenuItem value={orderTypeField} key={orderTypeField}>
                            {orderTypeName}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl sx={styles.getCreateModalFormControlSx()}>
                    <GenericAutocomplete
                      selectedItem={selectedOrganization}
                      placeholder="Select organization"
                      // @ts-ignore
                      onChange={handleOrganizationChange}
                      inputMode="search"
                      onInputChange={(_e, value) =>
                        setOrganizationNameSearchTerm(value)
                      }
                      options={organizations}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select organization"
                        />
                      )}
                      // @ts-ignore
                      getOptionLabel={(option: Organization) =>
                        `${option.name} `
                      }
                      loading={isOrganizationsSearchLoading}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" sx={{ width: '100%', px: '24px' }}>
                  <FormControl
                    sx={{
                      ...styles.getCreateModalFormControlSx(
                        isPurchaserDisabled()
                      ),
                      mt: 3,
                    }}
                    disabled={isPurchaserDisabled()}
                  >
                    <InputLabel id={CREATE_ORDER_PURCHASER_SELECT_LABEL_ID}>
                      Select purchaser
                    </InputLabel>
                    <Select
                      id={CREATE_ORDER_PURCHASER_SELECT_ID}
                      labelId={CREATE_ORDER_PURCHASER_SELECT_LABEL_ID}
                      label="Select purchaser"
                      value={purchaserId}
                      onChange={handlePurchaserChange}
                      sx={{
                        minWidth: '110px',
                      }}
                    >
                      {purchasersSelectable.map(
                        ([purchasersId, purchaserFullName]) => (
                          <MenuItem value={purchasersId}>
                            {purchaserFullName}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>

              <Stack direction="column">
                <Divider sx={{ mb: '24px' }} />

                <Stack
                  id="create-new-order-modal-button-stack"
                  direction="row"
                  spacing={0}
                  justifyContent="flex-end"
                  sx={{ width: '100%', mb: '24px', pr: '24px' }}
                >
                  <Button
                    gaContext={{
                      navigates_to: 'N/A',
                      textCopy: 'No, cancel',
                      purpose: 'Cancels New Order Creation',
                    }}
                    id="create-new-order-modal-cancel-button"
                    color="secondary"
                    onClick={openCancelConfirmDialog}
                    sx={styles.CreateButtonBaseSx}
                    transparentBackgroundOnHover
                  >
                    <Typography variant="button">No, cancel</Typography>
                  </Button>
                  <GALoadingButton
                    gaContext={{
                      navigates_to: 'N/A',
                      textCopy: 'Create order',
                      purpose: 'Creates An Order',
                    }}
                    disabled={isOrderCreationDisabled()}
                    variant="contained"
                    type="submit"
                    value="submit"
                    loading={loading}
                    color="primary"
                    sx={styles.CreateOrderModalSubmitButtonSx}
                    loadingIndicator={
                      <CircularProgress color="primary" size={20} />
                    }
                  >
                    <Typography variant="button">Create order</Typography>
                  </GALoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Modal>
      <ConfirmOrderCreationCancelDialog
        open={openCancelDialog}
        onCancel={handleCloseCreationModal}
        onClose={() => setOpenCancelDialog(false)}
      />
    </>
  );
};

export default CreateNewOrderModal;
