import { SxProps } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';
import theme from 'themes/theme';

export const BORDER_COLOR = 'rgba(19, 20, 20, 0.12)';
export const FONT_SIZE = 12;
export const MainContainerSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  pl: '2rem',
  pr: '2rem',
  pt: '2rem',
  position: 'relative',
  top: '62px',
  width: '100%',
  '@media (min-width: 1440px)': {
    left: `${DRAWER_WIDTH}px`,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
};

export const HomePageContentSx: SxProps = {
  display: 'flex',
  paddingTop: '15px',
  flexDirection: 'row',
  width: '100%',
  '@media (max-width: 1199px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
  '@media (min-width: 1259px)': {
    flexDirection: 'row',
  },
};

export const HomePageContentLeftSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  mr: '16px',
  width: '58%',
  '@media (max-width: 1199px)': {
    mb: '15px',
    width: '100%',
    justifyContent: 'center',
  },

  '@media (min-width: 1900px)': {
    width: '50%',
  },
};

export const HomePageContentRightSx: SxProps = {
  display: 'flex',
  width: '42%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '0 !important',
  '@media (max-width: 1199px)': {
    width: '100%',
    alignItems: 'center',
  },

  '@media (min-width: 1900px)': {
    width: '50%',
  },
};

export const HomePageHeaderSx: SxProps = {
  display: 'flex',
  width: '100%',
  color: theme.palette.colors.black,
  fontSize: '36px',
  lineHeight: '30px',
  flexDirection: 'row',
};

export const HomePageHeaderLeftSx = {
  width: '58%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  '@media (min-width: 1900px)': {
    width: '50%',
  },
};

export const HomePageHeaderRightSx = {
  display: 'flex',
  width: '42%',
  '@media (min-width: 1900px)': {
    width: '50%',
  },
};

export const HomePageHeaderTextSx = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '610px',
};
