import { capitalize } from 'services';
import {
  DropDownOption,
} from 'components/complex_filter/types';
import {
  OrderStatus,
} from 'global-constants';

export const ORDER_STATUS_TABLE: Record<string, string> = {
  Canceled: 'CANCELED',
  Complete: 'COMPLETE',
  Created: 'CREATED',
  Exception: 'EXCEPTION',
  'Expired Pending Recipient Information':
    'EXPIRED_PENDING_RECIPIENT_INFORMATION',
  'Expired Pending Return': 'EXPIRED_PENDING_RETURN',
  'In Fulfillment': 'IN_FULFILLMENT',
  'In Return': 'IN_RETURN',
  'Pending Approval': 'PENDING_APPROVAL',
  'Pending Certificate': 'PENDING_CERTIFICATE',
  'Pending Quote Approval': 'PENDING_QUOTE_APPROVAL',
  'Pending Recycle': 'PENDING_RECYCLE',
  'Pending Recipient Information': 'PENDING_RECIPIENT_INFORMATION',
  'Pending Repair': 'PENDING_REPAIR',
  'Pending Return': 'PENDING_RETURN',
  // Placed: 'Placed',
  'Processing Device': 'PROCESSING_DEVICE',
  // Quoted: 'Quoted',
  'Quote In Progress': 'QUOTE_IN_PROGRESS',
  Shipped: 'SHIPPED',
};

export const OrderStatusMapping = {
  [OrderStatus.CREATED]: 'Processing',
  [OrderStatus.CANCELLED]: 'Canceled',
  [OrderStatus.COMPLETE]: 'Complete',
  [OrderStatus.EXCEPTION]: 'Exception',
  [OrderStatus.EXPIRED_PENDING_RECIPIENT_INFORMATION]:
    'Expired - Pending Recipient Information',
  [OrderStatus.EXPIRED_PENDING_RETURN]: 'Expired - Pending Return',
  [OrderStatus.IN_FULFILLMENT]: 'In Fulfillment',
  [OrderStatus.IN_RETURN]: 'In Return',
  [OrderStatus.PENDING_APPROVAL]: 'Preparing Order',
  [OrderStatus.PENDING_RECIPIENT_INFORMATION]: 'Pending recipient response',
  [OrderStatus.PENDING_RETURN]: 'Pending return',
  [OrderStatus.SHIPPED]: 'Shipped',
  [OrderStatus.PROCESSING_DEVICE]: 'Processing Device',
  [OrderStatus.PENDING_REPAIR]: 'Pending Repair',
  [OrderStatus.PENDING_RECYCLE]: 'Pending Recycle',
  [OrderStatus.PENDING_CERTIFICATE]: 'Pending Certificate',
  [OrderStatus.QUOTE_IN_PROGRESS]: 'Quote in progress',
  [OrderStatus.PENDING_QUOTE_APPROVAL]: 'Pending Quote Approval',
};

export const ORDER_STATUS_DROPDOWN_OPTIONS: DropDownOption[] =
  Object.keys(OrderStatusMapping).map((statusKey: string) => ({
    displayName: OrderStatusMapping[statusKey as OrderStatus],
    value: statusKey === 'CANCELLED' ? 'CANCELED' : statusKey,
  }));

  export const SHIPMENT_STATUSES = [
    'ATTEMPT_FAILED',
    'AVAILABLE_FOR_PICKUP',
    'CREATED',
    'DELIVERED',
    'EXCEPTION',
    'EXPIRED',
    'INFO_RECEIVED',
    'IN_TRANSIT',
    'OUT_FOR_DELIVERY',
    'PENDING',
  ];

  export const SHIPMENT_STATUS_DROPDOWN_OPTIONS: DropDownOption[] =
  SHIPMENT_STATUSES.map((status) => ({
    displayName: status.split('_').map(capitalize).join(' '),
    value: status,
  }));
