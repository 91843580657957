import React from 'react';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import { ASSET_STATUS_LABELS } from 'globals/constants/assets';
import { ORDER_STATUSES_LABELS } from 'globals/constants/orders';

import { EXCEPTION_TOOLTIP_COPY } from './constants';
import { PanelItemProps } from './types';
import * as styles from './styles';

// eslint-disable-next-line sonarjs/cognitive-complexity
const PanelItem = (props: PanelItemProps) => {
  const { Icon, type, data } = props;
  const {
    orderId,
    orgId,
    recipientName,
    assetType,
    orderType,
    assetStatus,
    orderStatus,
    createdAt,
    assetId,
  } = data;

  let content = null;
  let orderDetailsLink = '';
  let assetDetailsLink = '';

  if (type === 'orders' && orderStatus) {
    const orderStatusName: string = ORDER_STATUSES_LABELS[orderStatus];
    orderDetailsLink = `/orders/${orderId}?orgId=${orgId}`;
    content = (
      <Stack sx={styles.PanelMiddleSx}>
        <Stack sx={styles.PanelCopy}>
          <Stack sx={styles.PanelName}>{recipientName}</Stack>
          <Stack sx={styles.PanelAssetType}>
            {recipientName ? '\u00A0' : null}
            {assetType}
            {assetType ? '\u00A0' : null}
          </Stack>
          <Stack sx={styles.PanelAssetStatus}>{orderType}:</Stack>
          &nbsp;
          <Stack sx={styles.PanelStatus}>
            {orderStatusName}
            {orderStatus === 'EXCEPTION' ? '\u00A0' : null}
            {orderStatus === 'EXCEPTION' ? (
              <Tooltip
                title={EXCEPTION_TOOLTIP_COPY(orderDetailsLink)}
                placement="bottom"
                arrow
              >
                <InfoOutlinedIcon
                  htmlColor="rgba(0, 0, 0, .4)"
                  sx={{ position: 'relative', top: '2px', fontSize: '14px' }}
                />
              </Tooltip>
            ) : null}
          </Stack>
        </Stack>
        <Stack sx={styles.PanelDate}>
          {moment(createdAt).format('MM/DD/YY HH:mm A')}
        </Stack>
      </Stack>
    );
  } else if (type === 'assets' && assetStatus) {
    const assetStatusName: string = ASSET_STATUS_LABELS[assetStatus];
    assetDetailsLink = `/assets/${assetId}`;
    content = (
      <Stack sx={styles.PanelMiddleSx}>
        <Stack sx={styles.PanelCopy}>
          <Stack sx={styles.PanelName}>{recipientName}</Stack>
          {recipientName ? '\u00A0' : null}
          <Stack sx={styles.PanelAssetType}>{assetType}:</Stack>
          {assetType ? '\u00A0' : null}
          <Stack sx={{ ...styles.PanelAssetStatus, ...styles.PanelStatus }}>
            {assetStatusName}
          </Stack>
          &nbsp;
        </Stack>
        <Stack sx={styles.PanelDate}>
          {moment(createdAt).format('MM/DD/YY HH:mm A')}
        </Stack>
      </Stack>
    );
  }
  return (
    <a
      style={{ textDecoration: 'inherit', color: 'inherit' }}
      href={type === 'orders' ? orderDetailsLink : assetDetailsLink}
      target="_blank"
    >
      <Stack sx={styles.PanelItemSx}>
        <Stack sx={styles.PanelLeftSx}>{Icon && Icon({})}</Stack>
        {content}
      </Stack>
    </a>
  );
};

export default PanelItem;
