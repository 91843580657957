import React from 'react';
import { SVGProps } from '../../types';

const CancelledIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F27058" />
      <path
        d="M15.9597 8.27203L12.5866 11.6451L12.2331 11.9987L12.5866 12.3523L15.9597 15.7254L15.7268 15.9583L12.3537 12.5851L12.0002 12.2316L11.6466 12.5851L8.2735 15.9583L8.0406 15.7254L11.4137 12.3523L11.7673 11.9987L11.4137 11.6451L8.0406 8.27203L8.2735 8.03914L11.6466 11.4123L12.0002 11.7658L12.3537 11.4123L15.7268 8.03914L15.9597 8.27203Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default CancelledIcon;
