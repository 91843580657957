import { SortOrder } from 'types';
import { SortDirection } from 'global-constants';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro';

export const ADD_EMPLOYEE_BUTTON_COPY = 'New employee';

export const EMPLOYEE_SEARCH_DELAY_MS = 300;

export enum EmployeeFilterColumns {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PERSONAL_EMAIL = 'personalEmail',
  WORK_EMAIL = 'workEmail',
}

export const DEFAULT_EMPLOYEE_SORT_ORDER: SortOrder = {
  direction: SortDirection.ORDER_ASCENDING,
  field: EmployeeFilterColumns.FIRST_NAME,
};

export const EmployeeFilterModel: GridFilterModel = {
  items: [
    { field: EmployeeFilterColumns.FIRST_NAME, operator: 'contains' },
    { field: EmployeeFilterColumns.LAST_NAME, operator: 'contains' },
    { field: EmployeeFilterColumns.EMAIL, operator: 'contains' },
  ],
  logicOperator: GridLogicOperator.And,
  quickFilterLogicOperator: GridLogicOperator.And,
};

export const DELAY_ARCHIVE_IN_MS = 200;

export const ADD_EMPLOYEE_MODAL = 'AddEmployeeModal';
export const EDIT_EMPLOYEE_MODAL = 'EditEmployeeModal';
