/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { selectOMSelfAssignSwitchChecked } from 'store/slices/order_management/search/selectors';
import {
  setOrderManagerLoadStatus,
  assignOMSelfAssignSwitch,
  clearOMSelfAssignSwitch
} from 'store/slices/order_management/search';
import { SEARCH_ORDER_MANAGERS } from 'pages/OrderManagement/hooks/useGetOrderManagementColumns/queries';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { OrderManager } from 'types';
import { orderManagementFilterActions } from 'store/slices/order_management/filter';
import { OMDashFilterKeys } from 'pages/OrderManagement/filters/shared/constants';

const OMSelfAssignSwitch = () => {
  const auth0 = useAuth0();
  const dispatch = useDispatch();

  const isSwitchedOn = useSelector(selectOMSelfAssignSwitchChecked);

  const { data: orderManagerData, loading: searchOrderManagersLoading } =
    useSafeQuery(SEARCH_ORDER_MANAGERS);

  const handleOnAssignedToMeChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      const orderManager =
        orderManagerData?.searchOrderManagers.orderManagers.filter(
          (orderManager: OrderManager) =>
            orderManager.email === auth0.user?.email
        ) as OrderManager[];

      if (orderManager.length) {
        dispatch(assignOMSelfAssignSwitch(orderManager[0].id));
        dispatch(
          orderManagementFilterActions.clearFilterEntriesByKeyReducer({
            key: OMDashFilterKeys.ORDER_MANAGER,
          })
        );
      }
      return;
    }
    dispatch(clearOMSelfAssignSwitch());
  };

  const dispatchLoadingStatus = (status: boolean) => {
    dispatch(setOrderManagerLoadStatus(status));
  };

  React.useEffect(() => {
    dispatchLoadingStatus(searchOrderManagersLoading);
  }, [searchOrderManagersLoading]);

  React.useEffect(() => {
    dispatch(clearOMSelfAssignSwitch());
  }, []);

  return (
    <FormControlLabel
      data-component-name="OMSelfAssignSwitch"
      sx={{ mr: 0 }}
      control={
        <Switch
          color="secondary"
          checked={isSwitchedOn}
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={handleOnAssignedToMeChange}
        />
      }
      label="Only Assigned to me"
    />
  );
};

export default OMSelfAssignSwitch;
