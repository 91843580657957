import { SxProps } from '@mui/material';
import {
  ORDER_MGMT_NEW_MODAL_MAX_PAPER_WIDTH,
  XS_SM_MODAL_HEIGHT_OFFSET,
  MD_XL_MODAL_HEIGHT_OFFSET,
  XS_SM_MODAL_WIDTH_OFFSET,
  MD_XL_MODAL_WIDTH_OFFSET,
  MD_XL_MODAL_INNER_HEIGHT_OFFSET,
} from './constants';

export const DetailedOrderPaperPropSx: SxProps = {
  maxWidth: ORDER_MGMT_NEW_MODAL_MAX_PAPER_WIDTH,
  maxHeight: '100vh',
  height: 'calc(100vh - 72x)',
  top: '12px',
};

export const OrderActionButtonContainerSx: SxProps = {
  borderBottom: '1px solid #DCDCDC',
  margin: '-24px', // to offset padding from parent
  padding: '18px 18px 9px 18px',
  marginBottom: '1rem',
};

export const OrderActionButtonGroupSx: SxProps = {
  '& > button': {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    padding: '6px 16px',
    '&:first-of-type': {
      mr: '16px',
    },
  },
};

export const TabsSx: SxProps = {
  '& .MuiTab-root': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    pb: 0,
    '&:not(:last-of-type)': {
      ml: '6px',
    },
    textTransform: 'uppercase',
    '&.Mui-selected': {
      color: 'colors.strongBlue',
    },
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'colors.strongBlue',
  },
};

export const BaseDimensionsDetailViewContainerSx: SxProps = {
  height: {
    xs: `calc(100vh - ${XS_SM_MODAL_HEIGHT_OFFSET})`,
    sm: `calc(100vh - ${XS_SM_MODAL_HEIGHT_OFFSET})`,
    md: `calc(100vh - ${MD_XL_MODAL_HEIGHT_OFFSET})`,
    lg: `calc(100vh - ${MD_XL_MODAL_INNER_HEIGHT_OFFSET})`,
    xl: `calc(100vh - ${MD_XL_MODAL_INNER_HEIGHT_OFFSET})`,
  },
  maxWidth: {
    xs: `calc(100vw - ${XS_SM_MODAL_WIDTH_OFFSET})`,
    sm: `calc(100vw - ${XS_SM_MODAL_WIDTH_OFFSET})`,
    md: `calc(100vw - ${MD_XL_MODAL_WIDTH_OFFSET})`,
    lg: `calc(100vw - ${MD_XL_MODAL_WIDTH_OFFSET})`,
    xl: `calc(100vw - ${MD_XL_MODAL_WIDTH_OFFSET})`,
  },
};

export const DetailViewContentContainerSx: SxProps = {
  ...BaseDimensionsDetailViewContainerSx,
  mr: '-24px',
  p: '0 !important',
};

export const getDetailViewContentContainerSx = (overrideSx: SxProps): SxProps => ({
  ...BaseDimensionsDetailViewContainerSx,
  mr: '-24px',
  p: '0 !important',
  ...overrideSx,
});

export const SubmitOrderLoadingButtonSx: SxProps = {
  '& span': {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px',
  },
};
