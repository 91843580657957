/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSafeMutation } from 'hooks/useSafeMutation';
import {
  OrderDetailsNotesTextFieldSx,
  OrderDetailsNotesSubmitButtonSx,
} from './styles';
import { ORDER_NOTES_WIDTH, OrderNotesStatus } from './constants';
import { OrderDetailsNotesProps } from './types';
import { UPDATE_ORDER_INTERNAL_NOTES } from './mutations';

const OrderDetailsNotes = (props: OrderDetailsNotesProps) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const [liveInternalNotes, setLiveInternalNotes] = React.useState<
    string | undefined
  >(props?.internalNotes ?? undefined);

  const [orderNotesStatus, setOrderNotesStatus] =
    React.useState<OrderNotesStatus>(OrderNotesStatus.SUBMIT_DISABLED);

  const propNotesEqualStateNotes = (notes: string): boolean =>
    notes === (props?.internalNotes ?? '');

  const getNotesOrderStatusOnChange = (noteValue: string): OrderNotesStatus =>
    propNotesEqualStateNotes(noteValue)
      ? OrderNotesStatus.SUBMIT_DISABLED
      : OrderNotesStatus.SUBMIT_ENABLED;

  const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setLiveInternalNotes(event.target.value);
    setOrderNotesStatus(() => getNotesOrderStatusOnChange(event.target.value));
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLiveInternalNotes(event.target.value);
    setOrderNotesStatus(() => getNotesOrderStatusOnChange(event.target.value));
  };

  const getSubmitOrderNotesDisabled = (): boolean =>
    orderNotesStatus === OrderNotesStatus.SUBMIT_DISABLED;

  const getSubmitOrderNotesLoading = (): boolean =>
    orderNotesStatus === OrderNotesStatus.LOADING;

  const refetchV2Orders = async () => {
    await client.refetchQueries({
      include: ['getPathOrders'],
    });
  };

  const handleOrderNotesSubmit = () =>
    refetchV2Orders().then(() => {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
        })
      );
      setOrderNotesStatus(OrderNotesStatus.SUBMIT_DISABLED);
    });

  const [mutate, { loading }] = useSafeMutation(UPDATE_ORDER_INTERNAL_NOTES);

  const handleSubmitInternalNotes = () => {
    setOrderNotesStatus(OrderNotesStatus.LOADING);
    mutate({
      variables: {
        id: props.orderId,
        internalNotes: liveInternalNotes,
      },
      onCompleted: handleOrderNotesSubmit,
    });
  };

  React.useEffect(() => {
    if (!props.internalNotes || props.internalNotes === liveInternalNotes)
      return;
    setLiveInternalNotes(props.internalNotes);
    setOrderNotesStatus(OrderNotesStatus.SUBMIT_ENABLED);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      setLiveInternalNotes(undefined);
      setOrderNotesStatus(OrderNotesStatus.SUBMIT_DISABLED);
    };
  }, [props.internalNotes]);

  React.useEffect(() => {
    if (!loading) return;
    setOrderNotesStatus(OrderNotesStatus.LOADING);
  }, [loading]);

  return (
    <Stack
      direction="column"
      sx={{
        width: ORDER_NOTES_WIDTH,
      }}
    >
      <TextField
        id="order-details-order-notes-text-field"
        defaultValue={' '}
        variant="filled"
        sx={OrderDetailsNotesTextFieldSx}
        onBlur={handleTextFieldBlur}
        onChange={handleTextFieldChange}
        value={liveInternalNotes}
        placeholder="Order notes"
        label="Order notes"
        multiline={true}
      />
      <LoadingButton
        variant="contained"
        disabled={getSubmitOrderNotesDisabled()}
        onClick={handleSubmitInternalNotes}
        loading={getSubmitOrderNotesLoading()}
        sx={OrderDetailsNotesSubmitButtonSx}
      >
        <Typography variant="button">Submit order notes</Typography>
      </LoadingButton>
    </Stack>
  );
};

export default OrderDetailsNotes;
