import React from 'react';
import { SVGProps } from '../../types';

const AllocatedIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4DB7C6" />
      <path
        d="M12.0002 7.79333L15.3335 10.7933V16H14.0002V12H10.0002V16H8.66683V10.7933L12.0002 7.79333ZM12.0002 6L5.3335 12H7.3335V17.3333H11.3335V13.3333H12.6668V17.3333H16.6668V12H18.6668L12.0002 6Z"
        fill="white"
      />
    </svg>
  );
};

export default AllocatedIcon;
