import { AddEmployeeFormModalData } from './types';

export const EMPTY_ADD_EMPLOYEE_FORM_DATA: AddEmployeeFormModalData = {
  firstName: '',
  lastName: '',
  workEmail: '',
  personalEmail: '',
  phoneNumber: '',
  groupTeams: null,
  hireDate: null,
  startDate: null,
};
