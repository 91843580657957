import React from 'react';
import { CTAButton } from './types';
import { useNavigate } from 'react-router-dom';

export const useHandleOnClick = () => {
  const navigate = useNavigate();
  return (button: CTAButton) => {
    return React.useCallback((event: React.MouseEvent<HTMLElement>) => {
      if (button.link.match('mailto')) {
        window.location.href = button.link;
      } else {
        navigate(button.link);
      }
    }, []);
  };
};
