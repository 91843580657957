import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import {
  clearOMSelfAssignSwitch,
  resetOMComboSearch,
} from 'store/slices/order_management/search';
import { OMDashFilterKeys } from 'pages/OrderManagement/filters/shared/constants';
import { FILTER_STATE_KEYS } from 'store/constants';
import {
  selectAllChosenEntries,
  selectChosenEntries,
  selectIsFilterDisabled,
} from 'store/shared/selectors';
import { orderManagementFilterActions } from 'store/slices/order_management/filter';

export const useClearSelfAssignSwitch = () => {
  const dispatch = useDispatch();
  const selectedFilterEntries =
    useSelector(selectAllChosenEntries(FILTER_STATE_KEYS.ORDER_MANAGEMENT)) ||
    [];

  const hasOMSelection = selectedFilterEntries.some(
    (entry) => entry.key === OMDashFilterKeys.ORDER_MANAGER
  );

  return React.useEffect(() => {
    if (!hasOMSelection) return;
    dispatch(clearOMSelfAssignSwitch());
  }, [hasOMSelection]);
};

export const useClearOMOrderStatusEffect = (clearOrDisableStatus: boolean) => {
  const dispatch = useDispatch();

  const selectedOrderStatuses = useSelector(
    selectChosenEntries(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (selectedOrderStatuses.length && clearOrDisableStatus) {
      dispatch(
        orderManagementFilterActions.clearFilterEntriesByKeyReducer({
          key: OMDashFilterKeys.STATUS,
        })
      );
    }
  }, [clearOrDisableStatus, selectedOrderStatuses.length]);
};

export const useClearOMOrderTypeEffect = (
  clearOrDisableOrderType: boolean
) => {
  const dispatch = useDispatch();

  const selectedOrderTypes = useSelector(
    selectChosenEntries(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (selectedOrderTypes.length && clearOrDisableOrderType) {
      dispatch(
        orderManagementFilterActions.clearFilterEntriesByKeyReducer({
          key: OMDashFilterKeys.ORDER_TYPE,
        })
      );
    }
  }, [clearOrDisableOrderType, selectedOrderTypes.length]);
};

export const useDisableOrderStatus = (tabDisablesStatus: boolean) => {
  const dispatch = useDispatch();

  const orderStatusAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (tabDisablesStatus && !orderStatusAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.disableFilterReducer(
          OMDashFilterKeys.STATUS
        )
      );
    }
  }, [tabDisablesStatus, orderStatusAlreadyDisabled]);
};

export const useEnableOrderStatus = (tabDisablesStatus: boolean) => {
  const dispatch = useDispatch();

  const orderStatusAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (!tabDisablesStatus && orderStatusAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.enableFilterReducer(
          OMDashFilterKeys.STATUS
        )
      );
    }
  }, [tabDisablesStatus, orderStatusAlreadyDisabled]);
};

export const useEnableOrderTypeFilter = (
  tabShouldDisableOrderType: boolean
) => {
  const dispatch = useDispatch();

  const orderTypeAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (!tabShouldDisableOrderType && orderTypeAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.enableFilterReducer(
          OMDashFilterKeys.ORDER_TYPE
        )
      );
    }
  }, [tabShouldDisableOrderType, orderTypeAlreadyDisabled]);
};

export const useDisableOrderTypeFilter = (
  tabShouldDisableOrderType: boolean
) => {
  const dispatch = useDispatch();

  const orderTypeAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (tabShouldDisableOrderType && !orderTypeAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.disableFilterReducer(
          OMDashFilterKeys.ORDER_TYPE
        )
      );
    }
  }, [tabShouldDisableOrderType, orderTypeAlreadyDisabled]);
};

export const useResetComboSearch = () => {
  const dispatch = useDispatch();

  const selectedFilterEntries =
    useSelector(selectAllChosenEntries(FILTER_STATE_KEYS.ORDER_MANAGEMENT)) ||
    [];

  return React.useEffect(() => {
    if (!selectedFilterEntries.length) return;
    dispatch(resetOMComboSearch());
  }, [selectedFilterEntries.length]);
};
