import { gql } from '@apollo/client';

export const getOrderNumberOptions = (output: string) => gql`
    query searchOrders(
    $limit: Int = 15
    $orderNumber: String
    $organizationId: String
  ) {
    searchOrders(
      searchOrdersInput: {
        limit: $limit
        orderNumber: $orderNumber
        organizationId: $organizationId
      }
    ) {
      ${output}
    }
  }
`;

export const getAllOrderTypes = (output: string) => gql`
  query {
    getAllOrderTypes {
      ${output}
    }
  }
`;


export const getOrganizationOptions = (output: string) => gql`
  query searchOrganizations(
    $name: String
    $offset: Int = 0
    $limit: Int = 15
    $orderAsc: String = "name"
    $orderDesc: String
  ) {
    searchOrganizations(
      organizationSearchInput: {
        name: $name
        offset: $offset
        limit: $limit
        orderAsc: $orderAsc
        orderDesc: $orderDesc
      }
    ) {
      ${output}
    }
  }
`;

export const getPurchaserOptions = (output: string) => gql`
    query getCollaboratorAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
    $organizationId: ID
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "firstOrLastName"
      }
    ) {
      ${output}
    }
  }
`;

export const getRecipientEmailOptions = (output: string) => gql`
    query getCollaboratorAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
    $organizationId: ID
  ) {
    getCollaboratorAutocompleteSuggestions(
      collaboratorFieldSuggestionsInput: {
        organizationId: $organizationId
        searchTerm: $searchTerm
        limit: $limit
        searchBy: "iLike"
        searchField: "anyEmail"
      }
    ) {
      ${output}
    }
  }
`;

export const getShippingSelectionTypes = (output: string) => gql`
  query {
    searchShippingTypes(searchShippingTypesInput: { limit: 100 }) {
      ${output}
    }
  }
`;

export const getOrderManagerOptions = (output: string) => gql`
  query getOrderManagerAutocompleteSuggestions(
    $searchTerm: String!
    $limit: Int = 15
  ) {
    getOrderManagerAutocompleteSuggestions(
      orderManagerSuggestionsInput: {
        searchTerm: $searchTerm
        searchField: "firstOrLastName"
        limit: $limit
        searchBy: "iLike"
      }
    ) {
      ${output}
    }
  }
`;

export const getFirst100Depots = (output: string) => gql`
  query searchDepots {
    searchDepots(input: { limit: 100 }) {
      ${output}
    }
  }
`;

export const DUMMY_NODE_GET_NOTHING = gql`
  query {
    searchDepots(input: { limit: 0 }) {
      count
    }
  }
`;

export const getDummyNode = (output: string) => gql`
  query {
    searchDepots(input: { limit: 0 }) {
      ${output}
    }
  }
`;

export const getOMOrganizationOptions = (output: string) => gql`
  query getOMOrganizations(
    $limit: Int = 15
    $name: String
    $skipIds: [String!] = []
  ) {
    getOrganizationAutocompleteSuggestions(
      organizationsSuggestionInput: {
        limit: $limit
        name: $name
        skipIds: $skipIds
      }
    ) {
      ${output}
    }
  }
`;

export const getOMSearchOrders = (output: string) => gql`
  query searchOrders(
    $offset: Int!
    $limit: Int!
    $view: OrderView!
    $orderManagerId: String
    $orderNumber: String
    $recipientCollaboratorId: String
    $orderAsc: String
    $orderDesc: String
    $orderTypeName: OrderTypeName
    $status: [OrderStatusEnum!]
    $depotId: String
    $organizationId: String
    # $isPremiumShipping: Boolean!
  ) {
    searchOrders(
      searchOrdersInput: {
        offset: $offset
        limit: $limit
        view: $view
        status: $status
        orderManagerId: $orderManagerId
        orderNumber: $orderNumber
        recipientCollaboratorId: $recipientCollaboratorId
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        orderTypeName: $orderTypeName
        depotId: $depotId
        organizationId: $organizationId
      }
    ) {
      ${output}
    }
  }
`;

export const getTrackingNumberOption = (output: string) => gql`
  query searchTrackings(
    $limit: Int = 10
    $offset: Int = 0
    $orderAsc: String = "trackingNumber"
    $orderDesc: String
    $trackingNumber: String
  ) {
    searchTrackings(
      searchInput: {
        limit: $limit
        offset: $offset
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        trackingNumber: $trackingNumber
      }
    ) {
      ${output}
    }
  }
`;

export const getOrderItemsByOrderId = (output: string) => gql`
query getAllOrderItems($orderId: String!) {
    getAllOrderItems(orderId: $orderId) {
      ${output}
    }
  }
`;

export const getAllDepotsQuery = (output: string) => gql`
  query getAllOMDepots{
    getAllDepots {
      ${output}
    }
  }
`;
