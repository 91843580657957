import {
  DropDownOption,
  UpsertOptionsHOF,
} from 'components/complex_filter/types';
import { BaseFilterEntry } from 'store/shared/types';
import { Order, OrderType, ShippingType } from 'types';
import { OrderStatusMapping } from 'globals/constants';

export const getTypeaheadOnCompletedDummyHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
  ) =>
  (data: any): void => {};

export const getOrderTypesOnCompletedHOF: UpsertOptionsHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
    _options: DropDownOption[],
  ) =>
  (data: any): void => {
    const orderTypes: OrderType[] = data?.getAllOrderTypes ?? [];

    if (!orderTypes.length) {
      console.error('No order types found');
      return;
    }

    const upsertOptions: BaseFilterEntry[] = orderTypes.map((orderType) => ({
      id: `devices-${key}-${orderType.id}`,
      key,
      displayName: orderType.name,
      value: orderType.id,
    }));

    upsertEntriesCallback(key, upsertOptions);
  };

export const getShippingTypesOnCompletedHOF: UpsertOptionsHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
    _options: DropDownOption[],
  ) =>
  (data: any): void => {
    const shippingTypes: ShippingType[] =
      data?.searchShippingTypes?.shippingTypes ?? [];

    if (!shippingTypes.length) {
      console.error('No order types found');
      return;
    }

    const upsertOptions: BaseFilterEntry[] = shippingTypes.map(
      (shippingType) => ({
        id: `devices-${key}-${shippingType.id}`,
        key,
        displayName: shippingType.name,
        value: shippingType.id,
      }),
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const getPurchaserTypeaheadOnCompletedHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
  ) =>
  (data: any): void => {
    const matchIds =
      data?.getCollaboratorAutocompleteSuggestions?.matchIds ?? [];
    const matches = data?.getCollaboratorAutocompleteSuggestions?.matches ?? [];

    const noOptions = matches.length === 0 || matchIds.length === 0;

    if (noOptions) {
      upsertEntriesCallback(key, []);
      return;
    }

    const upsertOptions: BaseFilterEntry[] = matches.map(
      (match: string, index: number) => ({
        id: `devices-${key}-${match}-${matchIds[index]}`,
        key,
        displayName: match,
        value: matchIds[index],
      }),
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const getRecipientTypeaheadOnCompletedHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
  ) =>
  (data: any): void => {
    const matchIds = data?.getRecipientAutocompleteSuggestions?.matchIds ?? [];
    const matches = data?.getRecipientAutocompleteSuggestions?.matches ?? [];

    const noOptions = matches.length === 0 || matchIds.length === 0;

    if (noOptions) {
      upsertEntriesCallback(key, []);
      return;
    }

    const upsertOptions: BaseFilterEntry[] = matches.map(
      (match: string, index: number) => ({
        id: `devices-${key}-${match}-${matchIds[index]}`,
        key,
        displayName: match,
        value: matchIds[index],
      }),
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const getOrderNumberTypeaheadOnCompletedHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
  ) =>
  (data: any): void => {
    const orders = data?.searchOrders?.orders ?? [];

    if (!orders.length) {
      upsertEntriesCallback(key, []);
      return;
    }

    const matchAndIdPairs = orders.map(
      (order: Pick<Order, 'id' | 'orderNumber'>) => [
        order.orderNumber,
        order.id,
      ],
    );

    const upsertOptions: BaseFilterEntry[] = matchAndIdPairs.map(
      (match: [string, string]) => {
        const [displayName, value] = match;
        return {
          id: `order-${key}-${displayName}-${value}`,
          key,
          displayName,
          value,
        };
      },
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const getAssetTypeaheadOnCompletedHOF =
  (
    key: string,
    upsertEntriesCallback: (key: string, options: BaseFilterEntry[]) => void,
  ) =>
  (data: any): void => {
    const matchIds = data?.getAssetAutocompleteSuggestions?.matchIds ?? [];
    const matches = data?.getAssetAutocompleteSuggestions?.matches ?? [];

    const noOptions = matches.length === 0 || matchIds.length === 0;

    if (noOptions) {
      upsertEntriesCallback(key, []);
      return;
    }

    const upsertOptions: BaseFilterEntry[] = matches.map(
      (match: string, index: number) => ({
        id: `devices-${key}-${match}-${matchIds[index]}`,
        key,
        displayName: match,
        value: matchIds[index],
      }),
    );

    upsertEntriesCallback(key, upsertOptions);
  };

export const getAssetQueryVariablesHOF =
  (
    searchField: string,
    options: { [x: string]: string } = { searchBy: 'iLike' },
  ) =>
  (rawInput: string, moreVariables: { [x: string]: string } = {}) => ({
    variables: {
      searchField,
      searchBy: options.searchBy,
      searchTerm: `%${rawInput}%`,
      ...options,
      ...moreVariables,
    },
  });

export const getOrderPurchaserQueryVariablesHOF =
  (moreVariables: { [x: string]: string } = {}) =>
  (rawInput: string) => ({
    variables: {
      searchTerm: `%${rawInput}%`,
      ...moreVariables,
    },
  });

export const getRecipientQueryVariablesHOF =
  (queryByName: boolean, moreVariables: { [x: string]: string } = {}) =>
  (rawInput: string) => ({
    variables: {
      searchField: queryByName ? 'firstOrLastName' : 'email',
      searchTerm: `%${rawInput}%`,
      ...moreVariables,
    },
  });

export const getOrderNumberQueryVariablesHOF =
  (moreVariables: { [x: string]: string } = {}) =>
  (rawInput: string) => ({
    variables: {
      orderNumber: rawInput,
      ...moreVariables,
    },
  });

export const setupOrderStatusOptions = () => {
  const TEMP: DropDownOption[] = [];
  Object.keys(OrderStatusMapping).forEach((stringKey: string) => {
    // Temporary fix for cancelled spelling
    const dropValue = stringKey === 'CANCELLED' ? 'CANCELED' : stringKey;
    TEMP.push({
      displayName:
        OrderStatusMapping[stringKey as keyof typeof OrderStatusMapping],
      value: dropValue,
    });
  });
  return TEMP;
};
