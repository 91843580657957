import React from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import { FETCH_RETRIEVAL_PRICING } from './queries';
import { AssetPrice } from './types';
import { getInsuranceInfo } from './utils';
import {
  RetrievalAsset,
  RetrievalOptionAnswer,
} from 'pages/Retrieval/RetrievalService/types';
import { formatNumberToDecimalString } from 'services/format';
import { ListItemSx } from './styles';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import { getAddressLabel } from 'services';
import {
  Home,
  HomeOutlined,
  SvgIconComponent,
  WorkOutline,
} from '@mui/icons-material';
import { SendPaperPlane } from 'components/Icons/SendPaperPlaneSVGIcon';
import { SelectableRecipientAddress } from 'types';
import { SEARCH_COLLABORATORS_BY_ORG_ID } from '../EmployeeStep/queries';

export const INSURANCE_MULTIPLIER = 0.015;

const getEmailIcon = (isWork: boolean) => {
  return isWork ? WorkOutline : HomeOutlined;
};

const getAddressIcon = (noAddress: boolean, isHome: boolean) => {
  if (noAddress) {
    return SendPaperPlane;
  }
  return isHome ? HomeOutlined : WorkOutline;
};

const getAddressText = (address: SelectableRecipientAddress) => {
  if (!address) {
    return 'Request address confirmation';
  }
  return getAddressLabel(address);
};

export const EMPLOYEE_COLUMNS = (
  retrievalRouteId: string,
  setAssetPrices: React.Dispatch<React.SetStateAction<AssetPrice[]>>,
  optionAnswerKey: Record<string, RetrievalOptionAnswer[]>
): GridColDef[] => {
  const { isFlagOn: isSelectRecipientEmail } = useFeatureFlagService(
    FeatureFlagNames.SELECT_RECIPIENT_EMAIL,
    {
      debugFlags: false,
    }
  );

  const { isFlagOn: isSelectRecipientAddress } = useFeatureFlagService(
    FeatureFlagNames.SELECT_RECIPIENT_ADDRESS,
    {
      debugFlags: false,
    }
  );

  const retrievalReviewColumns: GridColDef[] = [
    {
      field: 'recipient',
      headerName: 'Recipient',
      flex: 1,
      renderCell: (data) => {
        const recipientName = `${data.row.firstName} ${data.row.lastName}`;

        return (
          <Typography variant="body2" noWrap>
            {recipientName}
          </Typography>
        );
      },
    },
    ...(isSelectRecipientEmail()
      ? [
          {
            field: 'recipientEmail',
            headerName: 'Email',
            flex: 1,
            renderCell: (data) => {
              const icon = getEmailIcon(
                data.row.workEmail === data.row.contactEmail
              );
              const EmailIcon: SvgIconComponent = icon as SvgIconComponent;
              return (
                <>
                  <EmailIcon sx={{ marginRight: 1 }} />
                  {data.row.contactEmail}
                </>
              );
            },
          } as GridColDef,
        ]
      : []),
    ...(isSelectRecipientAddress()
      ? [
          {
            field: 'recipientAddress',
            headerName: 'Address',
            flex: 1,
            renderCell: (data) => {
              const icon = getAddressIcon(
                !data.row.shippingAddress,
                data.row.shippingAddress?.isHome
              );
              const label = getAddressText(data.row.shippingAddress);
              const AddressIcon: SvgIconComponent = icon as SvgIconComponent;

              return (
                <>
                  <AddressIcon sx={{ marginRight: 1 }} />
                  {label}
                </>
              );
            },
          } as GridColDef,
        ]
      : []),
    {
      field: 'retrievalDevices',
      headerName: 'Retrieval Devices',
      flex: 2,
      sortable: false,
      filterable: false,
      renderCell: (data) => {
        const { assets } = data.row;
        return (
          <List disablePadding>
            {assets.map((asset: any) => (
              <ListItem key={asset.id} sx={ListItemSx}>
                <Typography variant="body2" noWrap>
                  {asset.addedAsset ? (
                    <>
                      {asset.type} - {asset.serialNumber}{' '}
                    </>
                  ) : (
                    <>
                      {asset.make} {asset.model} - {asset.serialNumber}
                    </>
                  )}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: 'insuranceCoverageAmount',
      headerName: 'Insurance coverage amount',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (data) => {
        const { assets } = data.row;
        const insuranceAmounts = assets.map((asset: RetrievalAsset) =>
          getInsuranceInfo(asset, optionAnswerKey)
        );
        return (
          <List>
            {insuranceAmounts.map((amount: string, index: number) => {
              return (
                <ListItem key={`${amount}_${index}`} sx={ListItemSx}>
                  <Typography variant="body2" noWrap>
                    ${amount}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        );
      },
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (data) => {
        const { assets } = data.row;
        return (
          <List disablePadding>
            {assets.map((asset: any) => {
              const assetTypeName = asset.type.toUpperCase();
              const insuranceCharged =
                Number(getInsuranceInfo(asset, optionAnswerKey)) *
                INSURANCE_MULTIPLIER;
              const { data, loading } = useQuery(FETCH_RETRIEVAL_PRICING, {
                variables: {
                  retrievalRouteId,
                  assetTypeName,
                },
                onCompleted(data) {
                  setAssetPrices((prevState) => [
                    ...prevState,
                    {
                      retrievalRouteId,
                      assetTypeName,
                      price: data?.retrievalPricing?.price,
                    },
                  ]);
                },
              });

              const price = data?.retrievalPricing?.price ?? 0;
              const priceWithInsurance = formatNumberToDecimalString(
                price + insuranceCharged
              );

              return (
                <ListItem key={asset.id} sx={ListItemSx}>
                  <Typography variant="body2" noWrap>
                    {loading ? (
                      <CircularProgress color="primary" />
                    ) : (
                      <>${priceWithInsurance}</>
                    )}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        );
      },
    },
  ];
  return retrievalReviewColumns;
};
