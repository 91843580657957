import React from 'react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { PillProps } from './types';
import { getPillSx } from './styles';

const Pill = (props: PillProps) => {
  if (!props.toolTipHoverText)
    return (
      <Chip
        label={props.text}
        sx={{
          backgroundColor: props.color,
          color: props.textColor,
          ...props.chipOverrideSx,
        }}
      />
    );

  return (
    <Tooltip
      title={props.toolTipHoverText}
      placement={props.toolTipPlacement}
      arrow={props.toolTipArrow}
    >
      <Chip
        size={props.size}
        label={props.text}
        sx={getPillSx(props)}
      />
    </Tooltip>
  );
};

export default Pill;
