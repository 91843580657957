import React from 'react';
import ComplexFilterBank from 'components/complex_filter_bank/ComplexFilterBank';
import { FILTER_STATE_KEYS } from 'store/constants';
import { useGetFilterBankConfig } from './hooks';

const OMDashComplexFilterBank = () => {
  const filterBankConfig = useGetFilterBankConfig(FILTER_STATE_KEYS.ORDER_MANAGEMENT);

  if (!filterBankConfig) {
    return <></>;
  }

  return <ComplexFilterBank {...filterBankConfig} />;
};

export default OMDashComplexFilterBank;
