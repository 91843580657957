import { v2FindOrdersByStatusTransition } from 'api/apollo/queries/v2/orders';

export const v2FindOrdersByStatusTransitionOutput = `
      count
      orders {
        organizationId
        status
        orderType {
          name
        }
        id
        orderStatusTransition {
          id
          orderId
          from
          to
          createdAt
          updatedAt
          createdBy
        }
        lastOrderStatusTransition {
          id
          orderId
          from
          to
          createdAt
          updatedAt
          createdBy
        }
        orderItems {
          asset {
            assetType {
              name
            }
          }
        }
        recipient {
          firstName
          lastName
        }
      }`;

export const FIND_ORDERS_BY_STATUS_TRANSITION_QUERY =
  v2FindOrdersByStatusTransition(v2FindOrdersByStatusTransitionOutput);
