import React from 'react';
import { SVGProps } from '../../types';

const DeviceIssueIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F27058" />
      <path
        d="M15.833 14.4993H16.1663V14.8327H15.833V14.4993ZM15.833 11.8327H16.1663V12.166H15.833V11.8327ZM15.833 9.16602H16.1663V9.49935H15.833V9.16602Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M11.4998 6.83333V6.96037L11.1665 6.7387V6.5H18.8332V17.5H15.8332V17.1667H17.9998H18.4998V16.6667V7.33333V6.83333H17.9998H11.9998H11.4998Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M10.1665 16.6674V17.1674H10.6665H12.6665H13.1665V16.6674V11.4408V11.1661L12.9347 11.0188L9.71472 8.97214L9.44634 8.80156L9.17807 8.97228L5.7314 11.1656L5.49984 11.313V11.5874V16.6674V17.1674H5.99984H7.99984H8.49984V16.6674V13.1674H10.1665V16.6674ZM5.1665 11.255L9.44661 8.40164L13.4998 11.1018V17.5008H5.1665V11.255Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default DeviceIssueIcon;
