import { createSlice } from '@reduxjs/toolkit';
import { TableInitialState } from './types';

import { setAssetTablePaginationModelReducer } from './reducers';
import { DEFAULT_PAGINATION_MODEL } from 'global-constants';

export const initialState: TableInitialState = {
  paginationModel: DEFAULT_PAGINATION_MODEL,
};

export const tableSlice = createSlice({
  name: 'tableSlice',
  initialState,
  reducers: {
    setAssetTablePaginationModel: setAssetTablePaginationModelReducer,
  },
});

export const { setAssetTablePaginationModel } = tableSlice.actions;
