import React from 'react';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { OrderManagementSuggestionFilterName } from 'pages/OrderManagement/constants';
import { AutoCompleteOptionTuple } from 'types';
import {
  SEARCH_ORDERS_BY_ORDER_NUMBER,
  GET_COLLABORATORS_BY_NAME_OR_EMAIL,
} from './queries';

export const useGetOrderSuggestions = (
  searchTerm: string,
  filterName: OrderManagementSuggestionFilterName,
  setAutocompleteOptions: React.Dispatch<
    React.SetStateAction<AutoCompleteOptionTuple[]>
  >
) => {
  const [fetchOrderOptionsByOrderNumber] = useSafeLazyQuery(
    SEARCH_ORDERS_BY_ORDER_NUMBER,
    {
      onCompleted(data) {
        const { searchOrders } = data;
        const { orders } = searchOrders;
        if (!orders?.length ?? 0) return;
        const orderOptions = orders
          .slice()
          .sort((a: any, b: any) => a.orderNumber.localeCompare(b.orderNumber))
          .filter(
            (order: any, index: number) =>
              !(index > 0 && order.id === orders[index - 1].id)
          )
          .map((order: any) => [
            'orderNumber',
            order?.orderNumber,
            `Order number: ${order?.orderNumber}`,
          ]);
        setAutocompleteOptions(orderOptions);
      },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchOrderOptionsByCollaboratorNameOrEmail] = useSafeLazyQuery(
    GET_COLLABORATORS_BY_NAME_OR_EMAIL,
    {
      onCompleted(data) {
        const { collaborators: fetchedCollaborators } = data;
        const { collaborators: collaboratorsData } = fetchedCollaborators;
        if (!collaboratorsData.length) return;
        const collaboratorOptions = collaboratorsData
          .slice()
          .sort((a: any, b: any) => a.lastName.localeCompare(b.lastName))
          .map((collaborator: any) => [
            'recipientCollaboratorId',
            collaborator?.id,
            `${collaborator?.firstName} ${collaborator?.lastName} (${collaborator?.email})`,
          ]);
        setAutocompleteOptions(collaboratorOptions);
      },
    }
  );

  React.useEffect(() => {
    if (searchTerm) {
      switch (filterName) {
        case OrderManagementSuggestionFilterName.ORDER_NUMBER:
          fetchOrderOptionsByOrderNumber({
            variables: {
              orderNumber: searchTerm,
            },
          });
          break;
        case OrderManagementSuggestionFilterName.RECIPIENT_NAME:
          fetchOrderOptionsByCollaboratorNameOrEmail({
            variables: {
              firstOrLastName: searchTerm,
            },
          });
          break;
        case OrderManagementSuggestionFilterName.RECIPIENT_EMAIL:
          fetchOrderOptionsByCollaboratorNameOrEmail({
            variables: {
              email: searchTerm,
            },
          });
          break;
        default:
          break;
      }
    }
  }, [filterName, searchTerm]);
};
