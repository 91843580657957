import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';
import { ActiveTab, HomeInitialState } from './types';

export const setActiveTabReducer = (
  state: WritableDraft<HomeInitialState>,
  action: PayloadAction<ActiveTab>
) => {
  state.activeTab = action.payload;
};
