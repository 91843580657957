import {
  BaseFilterBankConfig,
  FilterModalConfig,
  FilterTestIds,
  FilterModalOverrideSx,
} from './types';
import {
  ASSET_DEVICES_FILTER_BANK_CONFIG,
  ASSET_DEVICES_FILTER_MODAL_CONFIG,
  ASSET_DEVICES_FILTER_TEST_IDS,
} from 'pages/Assets/filters/shared/constants';
import {
  ORDERS_FILTER_MODAL_CONFIG,
  ORDER_FILTER_TEST_IDS,
  ORDER_FILTER_BANK_CONFIG,
} from 'pages/Orders/filters/shared/constants';
import {
  OM_DASH_FILTER_MODAL_CONFIG,
  OM_DASH_FILTER_TEST_IDS,
  OM_DASH_FILTER_BANK_CONFIG,
} from 'pages/OrderManagement/filters/shared/constants';
import { FilterStateKey, DateTimeMode } from 'store/shared/types';

export const FILTER_BANK_CONFIG: Record<FilterStateKey, BaseFilterBankConfig> = {
  assets: ASSET_DEVICES_FILTER_BANK_CONFIG,
  orders: ORDER_FILTER_BANK_CONFIG,
  orderManagement: OM_DASH_FILTER_BANK_CONFIG,
};

export const COMPLEX_FILTER_MODAL_CONFIG: Record<FilterStateKey, FilterModalConfig> =
  {
    assets: ASSET_DEVICES_FILTER_MODAL_CONFIG,
    orders: ORDERS_FILTER_MODAL_CONFIG,
    orderManagement: OM_DASH_FILTER_MODAL_CONFIG,
  };

export const COMPLEX_FILTER_TEST_IDS: Record<FilterStateKey, FilterTestIds> = {
  assets: ASSET_DEVICES_FILTER_TEST_IDS,
  orders: ORDER_FILTER_TEST_IDS,
  orderManagement: OM_DASH_FILTER_TEST_IDS,
};

export const COMPLEX_FILTER_OVERRIDE_SX: Record<
  FilterStateKey,
  FilterModalOverrideSx
> = {
  assets: {
    filterButtonOverrideSx: { ml: '8px' },
  },
  orders: {},
  orderManagement: {},
};

export const DATE_TIME_MODES = Object.fromEntries(
  ['range', 'before', 'after'].map((x: string) => [x, x])
) as Record<DateTimeMode, DateTimeMode>;
