export const CTAContainerSx = {
  pb: '15px',
  mt: '5px',
  fontSize: '12px',
};

export const CTACupSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '& img': {
    width: '50px',
    height: '50px',
  },
};

export const CTATitleSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontSize: '14px',
  fontWeight: '700',
  paddingTop: '10px',
};

export const CTASubtitleSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  pt: '10px',
  pb: '15px',
  textAlign: 'center',
};

export const CTAButtonContainerSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

export const CTAButtonSx = {
  color: 'black',
  fontSize: '12px',
  fontWeight: '600',
  button: {
    ml: '5px',
    mr: '5px',
    display: 'flex',
    backgroundColor: 'rgba(249, 197, 77, 1)',
    pt: '8px',
    pb: '8px',
    pl: '15px',
    pr: '15px',
  },
};

export const CTAIconSx = {};

export const CTALinkSx = {};
